// @deprecated - Use `components/Page` instead
import { PropsWithChildren } from 'react'
import { BodyProps } from '../types/components/body'

function Body(props: PropsWithChildren<BodyProps>) {
  const { title, subtitle, children } = props

  return (
    <div className='client-page-body'>
      <div>
        {title && <h3>{title}</h3>}
        {subtitle && <h4 className='no-margin subtitle'>{subtitle}</h4>}
      </div>
      <section className='body'>{children}</section>
    </div>
  )
}

export default Body
