import React from 'react'
import Skeleton from 'react-loading-skeleton'

function HusbandRentSkeleton() {
  return (
    <div>
      <section className='skeleton-custom-table'>
        <Skeleton height={218} />
      </section>
      <Skeleton height={192} />
    </div>
  )
}

export default HusbandRentSkeleton
