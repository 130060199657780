import React from 'react'
import classNames from 'classnames'

function Row(props) {
  const { className, children } = props

  const componentClass = classNames('row', {
    [className]: className,
  })

  const newProps = {
    className: componentClass,
    children,
  }

  return <section {...newProps} />
}

export default Row
