import React from 'react'

import { isEmpty } from 'lodash'
import { Tabs } from '@leroy-merlin-br/backyard-react'
import ReactHtmlParser from 'react-html-parser'

import Skeleton from 'react-loading-skeleton'

import ErrorFallback from '../../../../errors/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'

import { fetchGroupClient } from '../../../../services/groupClient'
import { htmlHasOnlySpaces } from '../../../../utils/string-helper'

function IncludedAndExcluded(props) {
  const { t, details } = props

  const [loading, setLoading] = React.useState(true)
  const [servcGrpData, setServcGrpData] = React.useState([])

  React.useEffect(() => {
    handleFetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.servcGrpCd])

  async function handleFetchData() {
    if (!details?.servcGrpCd) return
    setLoading(true)

    const { data } = await fetchGroupClient(details.servcGrpCd)

    if (data) {
      setServcGrpData(data)
      setLoading(false)
    }
  }

  function resolveTabsItens() {
    const listItens = []

    if (
      servcGrpData?.inclddClientServcTxt &&
      !htmlHasOnlySpaces(servcGrpData?.inclddClientServcTxt)
    ) {
      listItens.push({
        id: 'includedId',
        label: t('servcGrp.edit.screen.nav.included'),
        panel: ReactHtmlParser(servcGrpData?.inclddClientServcTxt),
      })
    }

    if (
      servcGrpData?.inclddNotClientServcTxt &&
      !htmlHasOnlySpaces(servcGrpData?.inclddNotClientServcTxt)
    ) {
      listItens.push({
        id: 'excludedId',
        label: t('servcGrp.edit.screen.nav.excluded'),
        panel: ReactHtmlParser(servcGrpData?.inclddNotClientServcTxt),
      })
    }

    if (servcGrpData?.checklistClientTxt && !htmlHasOnlySpaces(servcGrpData?.checklistClientTxt)) {
      listItens.push({
        id: 'checklistId',
        label: t('client.service.checklist'),
        panel: ReactHtmlParser(servcGrpData?.checklistClientTxt),
      })
    }

    return listItens
  }

  const verifyIncludesText =
    (isEmpty(servcGrpData?.inclddClientServcTxt) ||
      htmlHasOnlySpaces(servcGrpData?.inclddClientServcTxt)) &&
    (isEmpty(servcGrpData?.inclddNotClientServcTxt) ||
      htmlHasOnlySpaces(servcGrpData?.inclddNotClientServcTxt)) &&
    (isEmpty(servcGrpData?.checklistClientTxt) ||
      htmlHasOnlySpaces(servcGrpData?.checklistClientTxt))

  if (loading) return <IncludedAndExcludedGhost />

  if (isEmpty(servcGrpData) || verifyIncludesText) return <div />

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => handleFetchData()}>
      <div className='check-list-client'>
        <header>
          <h3 className='conditions-title'>
            {`${t('client.portal.servcGrp.service.conditions.label')} ${servcGrpData?.servcGrpNm}`}
          </h3>
        </header>

        <Tabs items={resolveTabsItens()} />

        <br />
      </div>
    </ErrorBoundary>
  )
}

export default IncludedAndExcluded

function IncludedAndExcludedGhost() {
  return (
    <div>
      <Skeleton height={30} />

      <Skeleton height={200} />
    </div>
  )
}
