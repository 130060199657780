import i18next from 'i18next'
import { Link } from 'react-router-dom'

import tScheduleInfo from '../../../types/components/scheduleInfo'

function ProviderBox(props: tScheduleInfo) {
  const { details } = props

  return (
    <div className='info-box'>
      <p className='name'>{i18next.t('customer.service.order.details.infos.provider') as string}</p>
      <div className='image rounded'>
        <img
          alt=''
          className='img contain'
          src={
            details.filePath
              ? `data:image/png;base64, ${details.filePath}`
              : `assets/company-placeholder.svg`
          }
        />
      </div>
      <div className='right-infos'>
        <p>
          {i18next.t('company.info.company') as string}:{' '}
          <strong>
            {details.servcPrvdrTrdNm ? details.servcPrvdrTrdNm : details.servcPrvdrNm}
          </strong>
        </p>
        <p>
          {i18next.t('customer.service.order.details.infos.rating') as string}:{' '}
          <b>{`${details.servcPrvdrAvgGrade || ''}`.replace('.', ',')}</b>{' '}
          {i18next.t('customer.service.order.details.infos.fivepoints') as string}
        </p>
        <Link
          to={{
            pathname: '/installer',
            search: `?id=${details.servcPrvdrAgntIdEncoded}`,
            state: { osDetails: details },
          }}
          className='link'
        >
          {i18next.t('customer.service.order.details.infos.moreInfo') as string}
        </Link>
      </div>
    </div>
  )
}

export default ProviderBox
