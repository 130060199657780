import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSetupParameters } from '../../hooks/useSetupParameters'

import { Container } from '../../components/Container'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import { Page } from '../../components/Page'

import './index.scss'

const osDetails = {
  osId: '123456',
  osType: 'INSTALACAO',
}

/**
 * @description Detailed information about a service (V2)
 */

export function ServiceDetailsV2() {
  const history = useHistory()
  const { homePageVersionCustomerPortal } = useSetupParameters()

  useEffect(() => {
    if (homePageVersionCustomerPortal === 1) {
      history.replace(`/service-details${window.location.search}`)
    }
  }, [homePageVersionCustomerPortal])

  const handleOnClickHeaderNeedHelp = () => {
    const { search } = history.location

    history.push({
      pathname: '/service-details/need-help',
      search,
      state: osDetails,
    })
  }

  return (
    <Container>
      <HeaderV2 onClickNeedHelp={handleOnClickHeaderNeedHelp} />
      <Page className='service-details-v2'>
        <h3>Service Details V2</h3>
      </Page>
      <FooterV2 />
    </Container>
  )
}
