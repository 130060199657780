import React, { Component } from 'react'
import Body from '../../components/Body'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import { renderToastError } from '../../utils/Toast'
import LoginContainer from './components/LoginContainer'

class ClientHomepage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (!navigator.onLine) renderToastError('Você está offline, não será possivel entrar.')
  }

  render() {
    return (
      <>
        <HeaderV2 />
        <Body
          title='Acesso a página do serviço'
          subtitle='Digite seus dados para acessar a página do serviço.'
        >
          <LoginContainer />
        </Body>
        <FooterV2 />
      </>
    )
  }
}

export default ClientHomepage
