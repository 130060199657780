import { getFluxLabel } from '../../utils/ServiceOrderFlux'
import CalendarBox from './components/CalendarBox'
import AddressBox from './components/AddressBox'
import PhoneBox from './components/PhoneBox'
import ProviderBox from './components/ProviderBox'
import InstallerBox from './components/InstallerBox'
import tScheduleInfo from '../../types/components/scheduleInfo'

import { STEPS, VISIT_STEPS } from '../../utils/ServiceOrderSteps'
import ServiceOrderStatusType from '../../utils/ServiceOrderStatusType'
import { isPlannedVisit } from '../../utils/isTechVisit'

function ScheduleInfo(props: tScheduleInfo) {
  const { flux, step, details } = props
  const { servcOrdStusCd } = details

  const isVisitFlow = isPlannedVisit(flux)

  const isNonConformingOS = servcOrdStusCd === ServiceOrderStatusType.NAO_CONFORME.id

  const warrantyStep = isVisitFlow
    ? VISIT_STEPS.ENDED === step
    : STEPS.WARRANTY === step || isNonConformingOS

  const showProviderBox = ![STEPS.CONFIRMED_PURCHASE, STEPS.CONFIRM_SCHEDULE].includes(step)

  const childProps = { ...props, warrantyStep }

  return (
    <div className='services-information row'>
      <div className='col-xs-12 header'>
        <h4>
          {!isNonConformingOS && <b>{getFluxLabel('customer.service.order.details.info', flux)}</b>}
          {isNonConformingOS && (
            <b>{getFluxLabel('customer.service.order.details.info', flux, 'non.conforming')}</b>
          )}
        </h4>
      </div>

      <div className='col-xs-12 services-information-body'>
        <div className='schedule-info row'>
          {!warrantyStep && (
            <>
              <div className='col-xs-12 col-sm-4'>
                <CalendarBox {...childProps} />
                <div className='divider hide-xs' />
              </div>
              <div className='col-xs-12 col-sm-4 d-flex'>
                <div className='divider-vertical' />
                <AddressBox {...childProps} />
                <div className='divider-vertical ml-auto' />
              </div>
              <div className='col-xs-12 col-sm-4'>
                <div className='divider hide-xs' />
                <PhoneBox {...childProps} />
              </div>
            </>
          )}
          {warrantyStep && (
            <>
              <div className='col-xs-12 col-sm-6'>
                <CalendarBox {...childProps} />
                <div className='divider hide-xs' />
              </div>
              <div className='col-xs-12 col-sm-6 d-flex'>
                <div className='divider-vertical' />
                <AddressBox {...childProps} />
                <div className='divider hide-xs' />
              </div>
            </>
          )}
        </div>

        {showProviderBox && (
          <>
            <div className='provider-info row'>
              <div className='divider neutral-200' />
              <div className='col-xs-12 col-sm-6 d-flex'>
                <ProviderBox {...childProps} />
                <div className='divider-vertical ml-auto' />
              </div>
              <div className='col-xs-12 col-sm-6'>
                <div className='divider hide-xs' />
                <InstallerBox {...childProps} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ScheduleInfo
