import { useSelector } from 'react-redux'
import { FetchSetupParametersResponse } from '../redux/actions/parameters'
import { RootReducer } from '../redux/reducers'

/**
 * @description Hook to get the setup parameters from the redux store
 */

export const useSetupParameters = () => {
  const params: FetchSetupParametersResponse = useSelector(
    (state: RootReducer) => state.setupParameters,
  )
  return params
}
