import React, { Component, createRef } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone'
import { isNil, isFunction } from 'lodash'
import 'react-dates/lib/css/_datepicker.css'
import { DATE_PATTERN, FORMAT_SINGLE_DATE_PICKER } from '../../constants/config'
import Mask from 'vanilla-masker'

const outsideRange = () => false

class DatePicker extends Component {
  constructor() {
    super()

    this.myRef = createRef()
  }

  state = {
    focused: false,
  }

  handleChange = (value) => {
    const { onDateChange, onChange } = this.props

    if (onChange) {
      return onChange({
        target: { value },
      })
    }

    if (onDateChange) {
      onDateChange(value)
    }
  }

  handleFocusChange = (data) => {
    const { onFocusChange } = this.props

    if (onFocusChange) {
      return onFocusChange(data)
    }

    this.setState({ focused: data.focused })
  }

  isFocused = () => {
    const { focused } = this.props
    return isNil(focused) ? this.state.focused : focused
  }

  isOutsideRange = () => {
    const { isOutsideRange } = this.props

    if (isFunction(isOutsideRange)) {
      return isOutsideRange
    }

    return isOutsideRange && (() => false)
  }

  handleInputTypingChange = (event) => {
    if (!this.props.readOnly) {
      const formattedDate = Mask.toPattern(event.target.value, DATE_PATTERN)

      if (formattedDate.length === 10) {
        const momentDate = moment(formattedDate, FORMAT_SINGLE_DATE_PICKER)

        if (moment(momentDate).isValid()) {
          this.handleChange(momentDate)
        }
      }
    }
  }

  render() {
    const {
      date,
      value,
      placeholder,
      disabled,
      showClearDate,
      isOutsideRange,
      readOnly = true,
      icon = false,
      isDayBlocked = () => false,
    } = this.props

    return (
      <div className='datepicker-wrapper'>
        <div
          className='datepicker-wrapper-SingleDatePicker'
          onChange={this.handleInputTypingChange}
          ref={this.myRef}
        >
          <SingleDatePicker
            placeholder={placeholder}
            date={date || value}
            focused={this.isFocused()}
            onDateChange={this.handleChange}
            onFocusChange={this.handleFocusChange}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            displayFormat={FORMAT_SINGLE_DATE_PICKER}
            monthFormat='MMMM YYYY'
            readOnly={readOnly}
            disabled={disabled}
            showClearDate={showClearDate}
            isOutsideRange={isOutsideRange || outsideRange}
            isDayBlocked={isDayBlocked}
          />
        </div>
        {icon && <img alt='' className='calendar-icon' src='/assets/calendar-icon.svg' />}
      </div>
    )
  }
}

export default DatePicker
