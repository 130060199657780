import { Card } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import React from 'react'
import { callCenterLink } from '../../../../constants/constants'

function TalkToAttendant() {
  const callCenterLinkHtml = /* html */ `<a href="${callCenterLink}" target="_blank">${i18next.t(
    'needHelp.talkToAttendant.callCenter',
  )}</a>`
  const callCenterText = i18next
    .t('needHelp.talkToAttendant.callCenterHelp')
    .replace('{callCenterLink}', callCenterLinkHtml)

  return (
    <div id='talk-to-attendant'>
      <Card>
        <div className='card-content'>
          <div className='card-content-title'>
            {i18next.t('needHelp.talkToAttendant.needToTalkWithAttendant') as string}
          </div>
          <div className='card-content-text' dangerouslySetInnerHTML={{ __html: callCenterText }} />
        </div>
      </Card>
    </div>
  )
}

export default TalkToAttendant
