class ServcOrdEvntStusType {
  static REFUSED = new ServcOrdEvntStusType('1')

  static EXPIRED = new ServcOrdEvntStusType('2')

  static NO_STARTED = new ServcOrdEvntStusType('3')

  static ON_MY_WAY = new ServcOrdEvntStusType('4')

  static ATTENTION = new ServcOrdEvntStusType('5')

  static REMOVED_ATTENTION = new ServcOrdEvntStusType('6')

  static REWORK_DESCRIPTION = new ServcOrdEvntStusType('7')

  static CUST_CONFIRMATION = new ServcOrdEvntStusType('8')

  static CUST_SCHEDULED = new ServcOrdEvntStusType('9')

  static CUST_NEED_HELP = new ServcOrdEvntStusType('10')

  static AUTOMATIC_SURVEY_SATISFACTION = new ServcOrdEvntStusType('11')

  static PARTIALLY_DONE = new ServcOrdEvntStusType('12')

  static SMS_AUTOMATIC_CONFIRMATION = new ServcOrdEvntStusType('13')

  static SMS_AUTOMATIC_EVALUATION = new ServcOrdEvntStusType('14')

  static ANSWER_OF_THE_BACKOFFICE = new ServcOrdEvntStusType('15')

  static ANSWER_OF_THE_CUSTOMER = new ServcOrdEvntStusType('16')

  static QUOTATION_APPROVED = new ServcOrdEvntStusType('28')

  static QUOTATION_REFUSED = new ServcOrdEvntStusType('29')

  static OUT_OF_FLOW_REQUEST = new ServcOrdEvntStusType('30')

  static APPROVED_OUT_OF_FLOW_REQUEST = new ServcOrdEvntStusType('31')

  static REFUSED_OUT_OF_FLOW_REQUEST = new ServcOrdEvntStusType('32')

  static AWAITING_ACCEPTANCE_SALES_CONDITIONS = new ServcOrdEvntStusType('34')

  static REFUSAL_PROCESS = new ServcOrdEvntStusType('48')

  static SENT_NFE = new ServcOrdEvntStusType('61')

  static NFE_REMOVED = new ServcOrdEvntStusType('62')

  static SO_CANCELED_WHEN_NO_SERVICE_QUANTITY_IN_ORDER = new ServcOrdEvntStusType('63')

  static SO_REOPENED = new ServcOrdEvntStusType('65')

  static SEND_NFE_INTERMEDIATION = new ServcOrdEvntStusType('66')

  static PAYMENT_MADE = new ServcOrdEvntStusType('67')

  static MEASUREMENT_SHEET_SENT = new ServcOrdEvntStusType('68')

  static MEASUREMENT_SHEET_APPROVED = new ServcOrdEvntStusType('70')

  static MEASUREMENT_SHEET_REPROVED = new ServcOrdEvntStusType('71')

  static AGENT_RESCHEDULE_REQUESTED = new ServcOrdEvntStusType('72')

  static CUSTOMER_ACCEPT_RESCHEDULE = new ServcOrdEvntStusType('73')

  static CUSTOMER_ACCEPT_RESCHEDULE_ADVANCE_DATE = new ServcOrdEvntStusType('74')

  static CUSTOMER_REFUSE_RESCHEDULE = new ServcOrdEvntStusType('75')

  id = ''

  constructor(id) {
    this.id = id || ''
  }

  static getStatusWithId(id) {
    switch (id) {
      case ServcOrdEvntStusType.REFUSED.id:
        return ServcOrdEvntStusType.REFUSED

      case ServcOrdEvntStusType.EXPIRED.id:
        return ServcOrdEvntStusType.EXPIRED

      case ServcOrdEvntStusType.NO_STARTED.id:
        return ServcOrdEvntStusType.NO_STARTED

      case ServcOrdEvntStusType.ON_MY_WAY.id:
        return ServcOrdEvntStusType.ON_MY_WAY

      case ServcOrdEvntStusType.ATTENTION.id:
        return ServcOrdEvntStusType.ATTENTION

      case ServcOrdEvntStusType.REWORK_DESCRIPTION.id:
        return ServcOrdEvntStusType.REWORK_DESCRIPTION

      case ServcOrdEvntStusType.CUST_CONFIRMATION.id:
        return ServcOrdEvntStusType.CUST_CONFIRMATION

      case ServcOrdEvntStusType.CUST_SCHEDULED.id:
        return ServcOrdEvntStusType.CUST_SCHEDULED

      case ServcOrdEvntStusType.CUST_NEED_HELP.id:
        return ServcOrdEvntStusType.CUST_NEED_HELP

      case ServcOrdEvntStusType.AUTOMATIC_SURVEY_SATISFACTION.id:
        return ServcOrdEvntStusType.AUTOMATIC_SURVEY_SATISFACTION

      case ServcOrdEvntStusType.SMS_AUTOMATIC_CONFIRMATION.id:
        return ServcOrdEvntStusType.SMS_AUTOMATIC_CONFIRMATION

      case ServcOrdEvntStusType.SMS_AUTOMATIC_EVALUATION.id:
        return ServcOrdEvntStusType.SMS_AUTOMATIC_EVALUATION

      case ServcOrdEvntStusType.ANSWER_OF_THE_BACKOFFICE.id:
        return ServcOrdEvntStusType.ANSWER_OF_THE_BACKOFFICE

      case ServcOrdEvntStusType.ANSWER_OF_THE_CUSTOMER.id:
        return ServcOrdEvntStusType.ANSWER_OF_THE_CUSTOMER

      case ServcOrdEvntStusType.OUT_OF_FLOW_REQUEST.id:
        return ServcOrdEvntStusType.OUT_OF_FLOW_REQUEST

      case ServcOrdEvntStusType.APPROVED_OUT_OF_FLOW_REQUEST.id:
        return ServcOrdEvntStusType.APPROVED_OUT_OF_FLOW_REQUEST

      case ServcOrdEvntStusType.REFUSED_OUT_OF_FLOW_REQUEST.id:
        return ServcOrdEvntStusType.REFUSED_OUT_OF_FLOW_REQUEST

      case ServcOrdEvntStusType.AWAITING_ACCEPTANCE_SALES_CONDITIONS.id:
        return ServcOrdEvntStusType.AWAITING_ACCEPTANCE_SALES_CONDITIONS

      case ServcOrdEvntStusType.REFUSAL_PROCESS.id:
        return ServcOrdEvntStusType.REFUSAL_PROCESS

      case ServcOrdEvntStusType.QUOTATION_APPROVED.id:
        return ServcOrdEvntStusType.QUOTATION_APPROVED

      case ServcOrdEvntStusType.QUOTATION_REFUSED.id:
        return ServcOrdEvntStusType.QUOTATION_REFUSED

      case ServcOrdEvntStusType.SENT_NFE.id:
        return ServcOrdEvntStusType.SENT_NFE

      case ServcOrdEvntStusType.NFE_REMOVED.id:
        return ServcOrdEvntStusType.NFE_REMOVED

      case ServcOrdEvntStusType.MEASUREMENT_SHEET_SENT.id:
        return ServcOrdEvntStusType.MEASUREMENT_SHEET_SENT

      case ServcOrdEvntStusType.MEASUREMENT_SHEET_REMOVED.id:
        return ServcOrdEvntStusType.MEASUREMENT_SHEET_REMOVED

      case ServcOrdEvntStusType.MEASUREMENT_SHEET_APPROVED.id:
        return ServcOrdEvntStusType.MEASUREMENT_SHEET_APPROVED

      case ServcOrdEvntStusType.MEASUREMENT_SHEET_REPROVED.id:
        return ServcOrdEvntStusType.MEASUREMENT_SHEET_REPROVED

      default:
        return new ServcOrdEvntStusType()
    }
  }
}

export default ServcOrdEvntStusType
