import API_BASE from '../../constants/api-base-env.json'

const API_ENV = import.meta.env.MODE as
  | 'CDP'
  | 'lmbr.dev'
  | 'lmbr.qa'
  | 'lmbr.prod'
  | 'lmpt.uat'
  | 'lmpt.sit'
  | 'lmpt.prod'

const TYPE_ENV = API_BASE[API_ENV]

export const {
  API_INSTALA_SATSFTN,
  API_INSTALA_AUTH,
  API_INSTALA,
  API_INSTALA_PROVIDER,
  API_INSTALA_I18N,
  API_INSTALA_PARAM,
  API_INSTALA_JOBS,
  API_INSTALA_CORE_ELASTIC,
  API_INSTALA_BUDGET,
  API_INSTALA_REPORT,
  API_INSTALA_SERVICES_GROUPS,
} = TYPE_ENV
