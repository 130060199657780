import React from 'react'
import i18next from 'i18next'

import API_URL from '../../../../infra/api/endpoints'
import ApiRequest from '../../../../utils/ApiRequest'

import ListPackages from './components/List'
import Packages from './components/Packages'
import PackageServicesGhost from './Skeleton'

function PackageServices({ servcOrdSeq }) {
  const [loading, setLoading] = React.useState(false)
  const [packagesSubservices, setPackagesSubservices] = React.useState([])

  React.useEffect(() => {
    handleFetchOrderPackagesSubservices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleFetchOrderPackagesSubservices() {
    setLoading(true)
    const URL = `${API_URL.FETCH_PACKAGES_SERVICES_ORDER}/${servcOrdSeq}`

    const { data } = await new ApiRequest(null).get(URL).finally(() => {
      setLoading(false)
    })

    if (data) {
      setPackagesSubservices(data)
    }
  }

  async function handleSavePackagesOnServiceOrder() {
    setLoading(true)
    return new ApiRequest(null)
      .post(API_URL.SAVE_PACKAGES_SERVICES_ORDER, packagesSubservices)
      .then(() => window.location.reload())
  }

  function handleChangeQuantityOfPackage(event, stateIndex) {
    const {
      target: { name: subgroupIndex, value },
    } = event

    const clonedPackages = [...packagesSubservices]
    const currentPackage = clonedPackages[stateIndex]
    const currentSubGroupItem = currentPackage.subservicesDTO[subgroupIndex]

    if (currentSubGroupItem.sbgrpQty !== undefined) {
      currentSubGroupItem.sbgrpQty = value

      setPackagesSubservices(clonedPackages)
    }
  }

  if (loading) return <PackageServicesGhost />

  const existsQuantitySelected = packagesSubservices.some(
    (subservice) => subservice.hasQuantitySelected,
  )

  return (
    <section id='package-services-container'>
      {existsQuantitySelected
        ? (packagesSubservices || []).map((subservice, index) => (
            <ListPackages subservice={subservice} key={index} />
          ))
        : (packagesSubservices || []).map((subservice, index) => (
            <>
              <header>
                <h3>{i18next.t('select.services.text')}</h3>
              </header>

              <h4>{i18next.t('client.page.helper.text.to.select.packages')}</h4>
              <Packages
                subservice={subservice}
                index={index}
                key={index}
                handleChangeQuantity={handleChangeQuantityOfPackage}
                handleSavePackagesOnServiceOrder={handleSavePackagesOnServiceOrder}
              />
            </>
          ))}
    </section>
  )
}

export default PackageServices
