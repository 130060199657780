import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function PaginationLink(props) {
  const { getPage, pageNumber, glyphType, active, disabled } = props

  const handleClick = (pageNumber, onClickEvent, event) => {
    event.preventDefault()
    onClickEvent(pageNumber)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const paginationClass = classNames('pagination-item', {
    'pagination-arrow': glyphType,
    active,
    disabled,
  })

  const paginationContent = (glyph = glyphType, number = pageNumber) => {
    if (!glyph) {
      return number
    }
    // return <Glyph type={glyph} />
    if (glyph === 'double-arrow-left') {
      return <span>&#171;</span>
    }

    return <span>&#187;</span>
  }

  if (disabled || active) {
    return (
      <span className={paginationClass} title={`Página ${pageNumber}`} disabled={disabled}>
        {paginationContent()}
      </span>
    )
  }

  return (
    <a
      href={`?page=${pageNumber}`}
      onClick={(event) => handleClick(pageNumber, getPage, event)}
      className={paginationClass}
      title={`Página ${pageNumber}`}
      disabled={disabled}
    >
      {paginationContent()}
    </a>
  )
}

PaginationLink.propTypes = {
  getPage: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  glyphType: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default PaginationLink
