const brandColor = '#72bf44'
export const colourStyles = {
  clearIndicator: (styles) => ({
    ...styles,
    color: '#72bf44',
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : '#a6a6a6',
    '&:hover': {
      borderColor: state.isFocused ? brandColor : '#a6a6a6',
    },
  }),

  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? '#a6a6a6' : '#72bf44',
  }),

  indicatorsContainer: (styles) => ({
    ...styles,
    height: 30,
    alignSelf: 'center',
  }),

  indicatorSeparator: (styles) => ({
    borderColor: '#fffffff',
  }),

  multiValue: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#e5e5e5' : '#ffffff',
  }),

  multiValueLabel: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? '#a6a6a6' : '#72bf44',
    backgroundColor: state.isDisabled ? '#e5e5e5' : '#ffffff',
    fontSize: 'medium',
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : '#a6a6a6',
    '&:hover': {
      borderColor: state.isFocused ? brandColor : '#a6a6a6',
    },
  }),

  multiValueRemove: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#e5e5e5' : '#ffffff',
    color: state.isDisabled ? '#a6a6a6' : '#72bf44',
    ':hover': {
      backgroundColor: state.isDisabled ? '#e5e5e5' : '#cae4b8',
      color: '#72bf44',
    },
  }),

  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),

  noOptionsMessage: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    color: '#72bf44',
  }),

  option: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isFocused ? '#cae4b8' : null,
    color: isDisabled ? '#a6a6a6' : '#666666',
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
}

export const colourStylesSmall = {
  clearIndicator: (styles) => ({
    ...styles,
    color: '#72bf44',
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : '#a6a6a6',
    '&:hover': {
      borderColor: state.isFocused ? brandColor : '#a6a6a6',
    },
  }),

  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#72bf44',
  }),

  indicatorsContainer: (styles) => ({
    ...styles,
    height: 30,
    alignSelf: 'center',
  }),

  indicatorSeparator: (styles) => ({
    borderColor: '#fffffff',
  }),

  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
  }),

  multiValueLabel: (styles, state) => ({
    ...styles,
    paddingTop: 2,
    paddingright: 5,
    paddingBottom: 2,
    paddingLeft: 5,
    color: '#72bf44',
    backgroundColor: '#ffffff',
    fontSize: 'small',
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : '#a6a6a6',
    '&:hover': {
      borderColor: state.isFocused ? brandColor : '#a6a6a6',
    },
  }),

  multiValueRemove: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#e5e5e5' : '#ffffff',
    color: '#72bf44',
    ':hover': {
      backgroundColor: '#e5e5e5',
      color: '#72bf44',
    },
  }),

  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),

  noOptionsMessage: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    color: '#72bf44',
  }),

  option: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isFocused ? '#e5e5e5' : null,
    color: isDisabled ? '#a6a6a6' : '#666666',
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
}

export const colourStylesStore = {
  container: (styles, state) => ({
    ...styles,
    width: '100%',
    height: '100%',
    borderColor: '#72bf44',
  }),

  control: (styles, state) => ({
    ...styles,
    background: '#72bf44',
    height: '100%',
    border: 'none',
    borderRadius: '0',
    borderColor: '#72bf44',
  }),

  singleValue: (styles, state) => ({
    ...styles,
    color: '#ffffff',
    fontWeight: 'bold',
  }),

  placeholder: (styles, state) => ({
    ...styles,
    color: '#ffffff',
    fontWeight: 'bold',
  }),

  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#ffffff',
  }),

  valueContainer: (styles, state) => ({
    ...styles,
    flexDirection: 'row-reverse',
  }),

  indicatorSeparator: (styles, state) => ({
    ...styles,
    display: 'none',
  }),

  menuList: (styles, state) => ({
    ...styles,
    minHeight: '70vh',
  }),

  option: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isFocused ? '#cae4b8' : null,
    color: isDisabled ? '#a6a6a6' : '#666666',
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
}

export const colourStylesSchedule = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : '#a6a6a6',
    '&:hover': {
      borderColor: state.isFocused ? brandColor : '#a6a6a6',
    },
  }),

  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? '#a6a6a6' : '#72bf44',
  }),

  indicatorsContainer: (styles) => ({
    ...styles,
    height: 30,
    alignSelf: 'center',
  }),

  indicatorSeparator: (styles) => ({
    borderColor: '#fffffff',
  }),

  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),

  noOptionsMessage: (styles) => ({
    ...styles,
    backgroundColor: '#ffffff',
    color: '#72bf44',
  }),

  option: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isFocused ? '#cae4b8' : null,
    color: isDisabled ? '#a6a6a6' : '#666666',
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),

  placeholder: (styles, state) => ({
    ...styles,
    color: '#72BF44',
  }),
}
