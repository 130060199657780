import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import Cache from 'i18next-localstorage-cache'

import { fetchI18nKeys } from '../redux/actions/i18n'
import LanguageConfig from '../configs/Language'

const prefix = 'i18next_res_'
const language = new LanguageConfig(prefix)

class I18nWrapperContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    this.props.fetchI18nKeys(
      language.userLanguage,
      (i18nKeys) => {
        language.clearI18nCache()

        i18next.use(Cache).init({
          react: { wait: true, useSuspense: false },
          resources: {
            [language.userLanguage]: {
              translation: i18nKeys,
            },
          },
          lng: language.userLanguage,
          interpolation: {
            escapeValue: false,
          },
          lowerCaseLng: true,
          keySeparator: false,
          nsSeparator: false,
          cache: {
            enabled: true,
            prefix,
          },
        })

        this.setState({ loaded: true })
      },
      () => {
        i18next.use(Cache).init({
          react: { wait: true, useSuspense: false },
          resources: {
            [language.userLanguage]: {
              translation: {},
            },
          },
          lng: language.userLanguage,
          interpolation: {
            escapeValue: false,
          },
          lowerCaseLng: true,
          keySeparator: false,
          nsSeparator: false,
          cache: {
            enabled: true,
            prefix,
          },
        })

        this.setState({ loaded: true })
      },
    )
  }

  render() {
    const { loaded } = this.state
    const { children } = this.props

    if (!loaded) {
      return null
    }

    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
  }
}

/*
 * Mapeia state do redux para props do componente
 */
const mapStateToProps = ({ i18nReducer }) => ({
  i18nKeys: i18nReducer.keys,
})

/*
 * Mapeia actions e dispatch para props do componente
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchI18nKeys,
    },
    dispatch,
  )

/*
 * Default export como componente conectado ao redux
 */
export default connect(mapStateToProps, mapDispatchToProps)(I18nWrapperContainer)

/*
 * Export como componente
 */
export { I18nWrapperContainer as I18nWrapper }
