export interface Option {
  label: string
  value: number | boolean
  description?: string
  icon?: {
    checked: string
    unchecked: string
  }
}

export enum QuestionAnswerType {
  buttons = 'buttons',
  radios = 'radios',
  multiple = 'multiple',
}

export interface QuestionType<R> {
  title: string
  description?: string
  type: QuestionAnswerType
  options: Option[]
  answer?: R
  bottomLabels?: [string, string]
}

export interface UnnacomplishedReason {
  servcOrdEvntTypRsnId: number
  servcOrdEvntTyp: number
  servcOrdEvntTypRsnDesc: string
  actvOrdEvntTypRsnInd: number
  servcOrdEvntTypRsnDetail: string
  lastUpdtTs: string
  lastUpdtUserCd: number
  servcOrdEvntTypDesc: string
}

export type CommentFile = any

export interface EvaluationSurveySaveParams {
  servcOrdSeq: number
  indScoreRating?: string
  indEvaluationRating?: string
  indScoreDelivery?: string
  indScoreProduct?: string
  indScoreRecommend?: string
  lsReason?: number[]
  selectedReason?: number
  dsEvaluation?: string
  files?: any[]
  evaluationFlow: number
  custFullNm?: string
}

export interface QuestionValueTypeItem<R> {
  get: QuestionType<R>
  set: React.Dispatch<React.SetStateAction<QuestionType<R>>>
}

export interface QuestionsValueType {
  serviceWasPerformed: QuestionValueTypeItem<boolean>
  indScoreRating: QuestionValueTypeItem<number>
  indEvaluationRating: QuestionValueTypeItem<number>
  indScoreDelivery: QuestionValueTypeItem<number>
  indScoreProduct: QuestionValueTypeItem<number>
  indScoreRecommend: QuestionValueTypeItem<number>
  lsReason: QuestionValueTypeItem<number[]>
  technicalVisit: QuestionValueTypeItem<boolean>
  selectedReason: QuestionValueTypeItem<number>
}

export interface InstallerPerformanceRating {
  satsftnSrvyRtgRsnId: number
  satsftnSrvyRtgRsnTxt: string
  cntryCd: number
  buCd: number
  actvSatsftnSrvyRtgRsnInd: number
}
