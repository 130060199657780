import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Body from '../../components/Body'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import ServiceList from '../../components/ServiceList'
import { fetchCustomerServiceOrders } from '../../redux/actions/customer'

class ClientHomepage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  get customerPageTitleName() {
    const { serviceOrders } = this.props

    const orderWithName = ((!serviceOrders?.error && serviceOrders) || []).find(
      (ord) => ord.custFullNm,
    )
    return orderWithName && orderWithName.custFullNm && orderWithName.custFullNm.split(' ')[0]
  }

  componentDidMount() {
    const { history, fetchCustomerServiceOrders, t } = this.props
    if (!localStorage.customerCpf || !localStorage.customerSmsCode) {
      history.push('/login')
    } else {
      fetchCustomerServiceOrders(
        history,
        t('http.exception'),
        localStorage.customerCpf,
        localStorage.customerSmsCode,
      )
    }
  }

  render() {
    const { t, serviceOrders } = this.props

    return (
      <>
        <HeaderV2 />
        <Body
          title={this.customerPageTitleName}
          subtitle={t('customer.service.order.list.screen.title')}
        >
          <ServiceList t={t} services={serviceOrders} />
        </Body>
        <FooterV2 />
      </>
    )
  }
}

const ClientHomepageTranslated = withTranslation()(ClientHomepage)

const mapStateToProps = (state) => ({
  serviceOrders: state.customerReducer.serviceOrders,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCustomerServiceOrders }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientHomepageTranslated))
