import React from 'react'
import Mask from 'vanilla-masker'
import classNames from 'classnames'

function TextArea(props) {
  const {
    id,
    name,
    value,
    onChange,
    onKeyPress = () => {},
    onKeyDown = () => {},
    onKeyUp = () => {},
    onBlur,
    mask,
    label,
    disabled = false,
    minLength,
    maxLength,
    min,
    max,
    cols,
    rows,
    className = '',
    placeholder,
    title,
  } = props

  const handleChange = (event) => {
    if (mask) {
      event.target.value = Mask.toPattern(event.target.value, mask)
    }

    onChange(event)
  }

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  const handleKeyPress = (event) => {
    if (onKeyPress) {
      onKeyPress(event)
    }
  }

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  const handleKeyUp = (event) => {
    if (onKeyUp) {
      onKeyUp(event)
    }
  }

  const inputClass = classNames('textarea')

  return (
    <div>
      {title && <h4>{title}</h4>}
      <textarea
        name={name}
        id={id}
        className={`${inputClass} ${className}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
      />
      {label && (
        <label htmlFor={id} className='label'>
          {label}
        </label>
      )}
    </div>
  )
}

export default TextArea
