import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Body from '../../components/Body'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import SmsCodeContainer from '../../components/SmsCodeContainer'

class ClientSmsCode extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { history } = this.props
    if (!localStorage.customerCpf) history.push('/login')
  }

  render() {
    const { phoneNr = {} } = this.props
    return (
      <>
        <HeaderV2 />
        <Body
          title='Acesso a página do serviço'
          subtitle={
            <span>
              Digite o código enviado para o seu celular <b>{phoneNr.custMobilePhonNr || '-'}</b>
            </span>
          }
        >
          <SmsCodeContainer />
        </Body>
        <FooterV2 />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  phoneNr: state.customerReducer.loginInfo,
})

export default withRouter(connect(mapStateToProps)(ClientSmsCode))
