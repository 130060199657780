import React from 'react'

import i18next from 'i18next'

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../../../errors/ErrorFallback'

import HusbandRentSkeleton from './components/Skeleton'

import useHusbandRent from '../../../../hooks/business/useHusbandRent'
import minutesToHours from '../../../../helpers/minutesToHours'

interface Props {
  servcOrdSeq: number
}

function HusbandRent(props: Props) {
  const { servcOrdSeq } = props
  const { servicesHourly, totalTime, showSpinner } = useHusbandRent(servcOrdSeq)

  if (showSpinner) return <HusbandRentSkeleton />

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <div id='husband-rent-container'>
        <header>
          <span className='title'>
            {i18next.t('service.per.hour.husband.rent.title') as string}
          </span>
        </header>

        <section className='body'>
          {servicesHourly.length > 0 ? (
            <table className='custom-table'>
              <thead>
                <tr>
                  <th className='th-1'>{i18next.t('services.purcharset.text') as string}</th>
                  <th className='th-2'>{i18next.t('quantity.text.abreviated') as string}.</th>
                </tr>
              </thead>
              <tbody>
                {(servicesHourly || [])?.map((service) => (
                  <tr key={service.serviceId}>
                    <td className='td-1'>{service.serviceNm}</td>
                    <td className='td-2'>{service.serviceQty}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className='estimated-time-text'>
                    {i18next.t('total.estimated.time.text') as string}
                  </td>
                  <td className='estimated-time-hours'>{minutesToHours(totalTime)}</td>
                </tr>
              </tfoot>
            </table>
          ) : null}
        </section>

        <footer>
          <strong className='title'>{i18next.t('important.label.text') as string}:</strong>
          <span className='info'>
            <p>
              <span>
                {i18next.t('service.per.hour.husband.rent.important.subtitle.1') as string}
              </span>
              <span>
                {i18next.t('service.per.hour.husband.rent.important.subtitle.2') as string}
              </span>
              <span>
                {i18next.t('service.per.hour.husband.rent.important.subtitle.3') as string}
              </span>
              <span className='no-padding'>
                {i18next.t('service.per.hour.husband.rent.important.subtitle.4') as string}
              </span>
            </p>
          </span>
        </footer>
      </div>
    </ErrorBoundary>
  )
}

export default HusbandRent
