export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const CHANGE_TOKEN = 'CHANGE_TOKEN'
// Loader
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
// Pagination
export const GET_PAGES = 'GET_PAGES'
export const RESET_PAGES = 'RESET_PAGES'
export const SERVC_ORD_RECEIPT_REQUEST_SUCCESS = 'SERVC_ORD_RECEIPT_REQUEST_SUCCESS'
export const SERVC_ORD_RECEIPT_REQUEST_FAILURE = 'SERVC_ORD_RECEIPT_REQUEST_FAILURE'

export const FETCH_PARAMETERS_SUCCESS = 'FETCH_PARAMETERS_SUCCESS'
export const FETCH_PARAMETERS_ERROR = 'FETCH_PARAMETERS_ERROR'
// Reasons
export const FETCH_REASONS_LIST_REQUEST = 'FETCH_REASONS_LIST_REQUEST'
export const FETCH_REASONS_LIST_SUCCESS = 'FETCH_REASONS_LIST_SUCCESS'
export const FETCH_REASONS_LIST_ERROR = 'FETCH_REASONS_LIST_ERROR'
export const UPDATE_REASON_REQUEST = 'UPDATE_REASON_REQUEST'
export const UPDATE_REASON_SUCCESS = 'UPDATE_REASON_SUCCESS'
export const UPDATE_REASON_ERROR = 'UPDATE_REASON_ERROR'
export const FETCH_REASONS_TYPES_REQUEST = 'FETCH_REASONS_TYPES_REQUEST'
export const FETCH_REASONS_TYPES_SUCCESS = 'FETCH_REASONS_TYPES_SUCCESS'
export const FETCH_REASONS_TYPES_ERROR = 'FETCH_REASONS_TYPES_ERROR'
export const FETCH_UNNACOMPLISHED_REASONS_LIST_REQUEST = 'FETCH_UNNACOMPLISHED_REASONS_LIST_REQUEST'
export const FETCH_UNNACOMPLISHED_REASONS_LIST_SUCCESS = 'FETCH_UNNACOMPLISHED_REASONS_LIST_SUCCESS'
export const FETCH_UNNACOMPLISHED_REASONS_LIST_ERROR = 'FETCH_UNNACOMPLISHED_REASONS_LIST_ERROR'
// i18n
export const I18N_KEYS_REQUEST = 'I18N_KEYS_REQUEST'
export const FETCH_I18N_KEYS_SUCCESS = 'FETCH_I18N_KEYS_SUCCESS'
export const FETCH_I18N_KEYS_ERROR = 'FETCH_I18N_KEYS_ERROR'

// Customer confirmation
export const SAVE_CONFIRMATION_REQUEST = 'SAVE_CONFIRMATION_REQUEST'
export const SAVE_CONFIRMATION_REQUEST_SUCCESS = 'SAVE_CONFIRMATION_REQUEST_SUCCESS'
export const SAVE_CONFIRMATION_REQUEST_ERROR = 'SAVE_CONFIRMATION_REQUEST_ERROR'

// Customer evaluation
export const SAVE_EVALUATION_REQUEST = 'SAVE_EVALUATION_REQUEST'
export const SAVE_EVALUATION_REQUEST_SUCCESS = 'SAVE_EVALUATION_REQUEST_SUCCESS'
export const SAVE_EVALUATION_REQUEST_ERROR = 'SAVE_EVALUATION_REQUEST_ERROR'
export const CUSTOMER_FETCH_OS_INFO_SUCCESS = 'CUSTOMER_FETCH_OS_INFO_SUCCESS'
export const CUSTOMER_FETCH_OS_INFO_FAILURE = 'CUSTOMER_FETCH_OS_INFO_FAILURE'
export const CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS'
export const CUSTOMER_LOGIN_FAILURE = 'CUSTOMER_LOGIN_FAILURE'

export const CUSTOMER_FETCH_SERVICE_ORDER_SUCCESS = 'CUSTOMER_FETCH_SERVICE_ORDER_SUCCESS'
export const CUSTOMER_FETCH_SERVICE_ORDER_FAILURE = 'CUSTOMER_FETCH_SERVICE_ORDER_FAILURE'

export const CUSTOMER_SMS_CODE_SUCCESS = 'CUSTOMER_SMS_CODE_SUCCESS'
export const CUSTOMER_SMS_CODE_FAILURE = 'CUSTOMER_SMS_CODE_FAILURE'
export const FETCH_OCCURRENCES_SUCCESS = 'FETCH_OCCURRENCES_SUCCESS'
export const FETCH_OCCURRENCES_ERROR = 'FETCH_OCCURRENCES_ERROR'
export const FETCH_TEMPLATES_BY_ID_SUCCESS = 'FETCH_TEMPLATES_BY_ID_SUCCESS'
export const FETCH_TEMPLATES_BY_ID_ERROR = 'FETCH_TEMPLATES_BY_ID_ERROR'

// services groups
export const CUSTOMER_FETCH_SERVICE_GROUP_INFO_SUCCESS = 'CUSTOMER_FETCH_SERVICE_GROUP_INFO_SUCCESS'
export const CUSTOMER_FETCH_SERVICE_GROUP_INFO_FAILURE = 'CUSTOMER_FETCH_SERVICE_GROUP_INFO_FAILURE'
