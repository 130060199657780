import i18next from 'i18next'
import tScheduleInfo from '../../../types/components/scheduleInfo'
import { formatNationalOrInternationalPhoneNumber } from '../../../utils/InternationalPhoneNumberUtils'
import { getFluxLabel } from '../../../utils/ServiceOrderFlux'
import Button from '../../Button'

import Phone from '../../../assets/icons/phone.svg?react'

function PhoneBody(props: tScheduleInfo) {
  const { flux, details, openChangeAddressPage } = props

  const showAddressBtn = false

  return (
    <div className='phone'>
      <Phone />
      <span className='bold'>{getFluxLabel('customer.service.order.details.phones', flux)}</span>
      {details.custMobilePhonNr && (
        <span>
          {i18next.t('customer.service.order.details.mobile.phone') as string}:{' '}
          {`${
            formatNationalOrInternationalPhoneNumber(i18next.t, details.custMobilePhonNr) || '-'
          }`}
        </span>
      )}
      {details.custHmPhonNr && (
        <span>
          {i18next.t('customer.service.order.details.home.phone') as string}:{' '}
          {`${formatNationalOrInternationalPhoneNumber(i18next.t, details.custHmPhonNr) || '-'}`}
        </span>
      )}
      {details.custBusnsPhonNr && (
        <span>
          {i18next.t('customer.service.order.details.business.phone') as string}:{' '}
          {`${formatNationalOrInternationalPhoneNumber(i18next.t, details.custBusnsPhonNr) || '-'}`}
        </span>
      )}
      {showAddressBtn && (
        <>
          <Button
            onClick={() => openChangeAddressPage('title-phone')}
            className='margin button-fit-content'
            fitContent
            variant='white'
            label={i18next.t('customer.service.order.change.phones.button')}
          />
        </>
      )}
    </div>
  )
}

export default PhoneBody
