import i18next from 'i18next'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Body from '../../components/Body'
import { ServiceOrderDetailV2 } from '../../models/service-order-detail-v2'
import { fetchOsInfo } from '../../redux/actions/customer'
import { parseQuery } from '../../utils/serviceOrderUtils'
import TalkToAttendant from './components/TalkToAttendant'

import Footer from '../../components/ClientFooter'
import Header from '../../components/GenericHeader'

interface NeedHelpProps {
  fetchOsInfo: (servcOrdSeqEncoded: any, vaCustCdEncoded: any) => Promise<void>
  osDetails: ServiceOrderDetailV2
  history: any
  location: any
}

function NeedHelp(props: NeedHelpProps) {
  const { osDetails } = props

  useEffect(() => {
    loadOsDetail()
  }, [])

  const loadOsDetail = () => {
    const { o, c } = parseQuery()
    return props.fetchOsInfo(o, c)
  }

  const headerCustomActions: { label: string; onClick: () => void }[] = [
    {
      label: i18next.t('needHelp.backToHome'),
      onClick: () => {
        props.history.push({
          pathname: '/service-details',
          search: props.location.search,
          state: { orderDetails: osDetails },
        })
      },
    },
  ]

  return (
    <>
      <HeaderJsAdapter title='' osDetails={props.osDetails} customActions={headerCustomActions} />

      <Body title='' subtitle=''>
        <div id='need-help'>
          <div className='header'>
            <h1>{i18next.t('needHelp.title') as string}</h1>
          </div>

          <hr />

          <TalkToAttendant />
        </div>
      </Body>

      <FooterJsAdapter />
    </>
  )
}

function HeaderJsAdapter(props: {
  title: string
  osDetails: ServiceOrderDetailV2
  customActions: { label: string; onClick: () => void }[]
}) {
  const H = Header as any
  return <H {...props} />
}

function FooterJsAdapter() {
  const F = Footer as any
  return <F />
}

const mapStateToProps = (state: any) => ({
  osDetails: state.customerReducer.osDetails,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      fetchOsInfo,
    },
    dispatch,
  )

export default connect(mapStateToProps, (dispatch) => ({
  ...mapDispatchToProps(dispatch),
  dispatch,
}))(NeedHelp as any)
