export const STEPS = {
  CONFIRMED_PURCHASE: 1,
  CONFIRM_SCHEDULE: 2,
  AUTHORIZED: 3,
  EVALUATION: 4,
  WARRANTY: 5,
}

export const VISIT_STEPS = {
  CONFIRMED_PURCHASE: 1,
  CONFIRM_SCHEDULE: 2,
  AUTHORIZED: 3,
  ENDED: 4,
}
