import i18next from 'i18next'
import moment from 'moment-timezone'
import { getPages } from '../../components/pagination/constants'
import API_URL from '../../infra/api/endpoints'
import Api from '../../utils/ApiRequest'
import { renderToastError } from '../../utils/Toast'
import { parseQuery } from '../../utils/serviceOrderUtils'
import {
  CUSTOMER_FETCH_OS_INFO_FAILURE,
  CUSTOMER_FETCH_OS_INFO_SUCCESS,
  CUSTOMER_FETCH_SERVICE_GROUP_INFO_FAILURE,
  CUSTOMER_FETCH_SERVICE_GROUP_INFO_SUCCESS,
  CUSTOMER_FETCH_SERVICE_ORDER_FAILURE,
  CUSTOMER_FETCH_SERVICE_ORDER_SUCCESS,
  CUSTOMER_LOGIN_FAILURE,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_SMS_CODE_FAILURE,
  CUSTOMER_SMS_CODE_SUCCESS,
  FETCH_OCCURRENCES_ERROR,
  FETCH_OCCURRENCES_SUCCESS,
  SERVC_ORD_RECEIPT_REQUEST_FAILURE,
  SERVC_ORD_RECEIPT_REQUEST_SUCCESS,
} from './actionTypes'
import { startLoading, stopLoading } from './activityIndicator'

const hideSpinner = () => ({
  type: null,
})
const showSpinner = () => ({
  type: null,
})

export const requestService =
  (newInfo, callback = () => {}) =>
  (dispatch) => {
    const params = {
      ...newInfo,
    }

    dispatch(showSpinner())

    return new Api(null, dispatch)
      .post(API_URL.CUSTOMER_REQUEST_SERVICE, params)
      .then(
        (response) => {
          callback()
        },
        (err) => {
          renderToastError(err.message.dsMessage)
        },
      )
      .finally(() => dispatch(hideSpinner()))
  }

const fetchOsInfoSuccess = (payload, dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_OS_INFO_SUCCESS,
    payload,
  })
}

const fetchOsInfoError = (error, dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_OS_INFO_FAILURE,
    payload: error,
  })
}

const fetchReceiptSuccess = (payload, dispatch) => {
  dispatch({
    type: SERVC_ORD_RECEIPT_REQUEST_SUCCESS,
    payload,
  })
}

const fetchReceiptError = (error, dispatch) => {
  dispatch({
    type: SERVC_ORD_RECEIPT_REQUEST_FAILURE,
    payload: error,
  })
}

const fetchServiceGroupSuccess = (payload, dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_SERVICE_GROUP_INFO_SUCCESS,
    payload,
  })
}

const fetchServiceGroupError = (error, dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_SERVICE_GROUP_INFO_FAILURE,
    payload: error,
  })
}

export const fetchReceipt = (servcOrdSeqEncoded) => (dispatch) => {
  const loadingAction = 'fetchReceipt'

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .get(API_URL.FETCH_SERVC_ORDER_RECEIPT, { servcOrdSeqEncoded })
    .then(
      (response) => fetchReceiptSuccess(response.data, dispatch),
      (error) => fetchReceiptError(error, dispatch),
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const fetchOsInfo = (servcOrdSeqEncoded, vaCustCdEncoded) => (dispatch) => {
  const loadingAction = 'fetchOsInfo'

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .get(API_URL.FETCH_CUSTOMER_OS_ENCODED, {
      servcOrdSeqEncoded,
      vaCustCdEncoded,
    })
    .then(
      (response) => fetchOsInfoSuccess(response.data, dispatch),
      (error) => fetchOsInfoError(error, dispatch),
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

const customerLoginSuccess = (data, cpf, dispatch) => {
  localStorage.setItem('customerCpf', cpf) // saves user cpf to localstorage
  dispatch({
    type: CUSTOMER_LOGIN_SUCCESS,
    payload: {
      cpf,
      ...data,
    },
  })
}

const customerLoginFailure = (error, cpf, dispatch) => {
  dispatch(hideSpinner())
  renderToastError(error.message.dsMessage)
  dispatch({
    type: CUSTOMER_LOGIN_FAILURE,
    payload: error,
  })
}

export const customerLogin = (vaCustCd, capcha, successCallback) => (dispatch) => {
  const loadingAction = 'customerLogin'

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .get(API_URL.CUSTOMER_LOGIN_CPF, {
      vaCustCd,
      capcha,
      OTPFriendly: 'OTPCredential' in window ? 1 : 0,
      frontUrl: window.location.host,
    })
    .then(
      (response) => {
        customerLoginSuccess(response.data, vaCustCd, dispatch)
        successCallback && successCallback()
      },
      (error) => customerLoginFailure(error, vaCustCd, dispatch),
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

const validateCustomerSmsCodeSuccess = (response, code, dispatch) => {
  localStorage.setItem('customerSmsCode', code) // saves user code to local storage
  if (response && response.status === 'valid') {
    dispatch({
      type: CUSTOMER_SMS_CODE_SUCCESS,
    })
  } else {
    dispatch({
      type: CUSTOMER_SMS_CODE_FAILURE,
    })
  }
}

const validateCustomerSmsCodeFailure = (dispatch) => {
  dispatch({
    type: CUSTOMER_SMS_CODE_FAILURE,
  })
}

export const validateCustomerSmsCode = (custPassword, successCallback) => (dispatch) => {
  const loadingAction = 'validateSmsCode'

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .get(API_URL.CUSTOMER_LOGIN_SMS_CODE, {
      custPassword,
      vaCustCd: localStorage.getItem('customerCpf'),
    })
    .then(dispatch(showSpinner()))
    .then(
      (response) => {
        validateCustomerSmsCodeSuccess(response.data, custPassword, dispatch)
        if (response.data && response.data.status === 'valid') successCallback && successCallback()
        else renderToastError('Código inválido.')
      },
      (error) => validateCustomerSmsCodeFailure(dispatch),
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

const fetchCustomerServiceOrdersSuccess = (payload, dispatch) => {
  dispatch({
    type: CUSTOMER_FETCH_SERVICE_ORDER_SUCCESS,
    payload,
  })
}

const fetchCustomerServiceOrdersFailure = (error, dispatch, history, failMsg) => {
  localStorage.removeItem('customerSmsCode')
  localStorage.removeItem('customerCpf')
  renderToastError(error?.message?.dsMessage || failMsg)
  history.push('/login')

  dispatch({
    type: CUSTOMER_FETCH_SERVICE_ORDER_FAILURE,
    payload: error,
  })
}

export const fetchCustomerServiceOrders =
  (history, failMsg, cpf, code, page = 1) =>
  (dispatch) => {
    const loadingAction = 'fetchServiceOrders'

    const metadata = {
      page,
      size: 10,
    }

    dispatch(startLoading(loadingAction))
    return new Api(null, dispatch, metadata)
      .get(API_URL.CUSTOMER_FETCH_SERVICE_ORDERS, {
        custPassword: code,
        vaCustCd: cpf,
      })
      .then(dispatch(showSpinner()))
      .then(
        (response) => {
          dispatch(getPages(response.metadata))
          fetchCustomerServiceOrdersSuccess(response.data, dispatch)
        },
        (error) => fetchCustomerServiceOrdersFailure(error, dispatch, history, failMsg),
      )
      .finally(() => dispatch(stopLoading(loadingAction)))
  }

export const editInformation = (newInfo, oldInfo, search, history, failMsg) => (dispatch) => {
  const loadingAction = 'fetchEditAddress'
  dispatch(startLoading(loadingAction))

  const auth = parseQuery(search)

  const params = {
    ...newInfo,
    custPassword: localStorage.getItem('customerSmsCode'),
    vaCustCd: localStorage.getItem('customerCpf'),
    servcOrdSeq: oldInfo.servcOrdSeq,
    servcOrdSeqEncoded: auth.o,
    vaCustCdEncoded: auth.c,
  }

  return new Api(null, dispatch)
    .post(API_URL.CUSTOMER_EDIT_ADDRESS_PHONE, params)
    .then(
      () => {
        history.push({
          pathname: '/service-details',
          search,
          state: {
            success: 'address-phone',
            osDetails: { ...oldInfo, ...newInfo },
          },
        })
      },
      (err) => {
        renderToastError(failMsg)
        console.error(err)
      },
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const editPhone = (newInfo, oldInfo, search, history, failMsg) => (dispatch) => {
  const auth = parseQuery(search)
  const params = {
    ...newInfo,
    custPassword: localStorage.getItem('customerSmsCode'),
    vaCustCD: localStorage.getItem('customerCpf'),
    servcOrdSeq: oldInfo.servcOrdSeq,
    servcOrdSeqEncoded: auth.o,
    vaCustCDEncoded: auth.c,
  }

  const loadingAction = 'changePhones'
  return new Api(null, dispatch)
    .post(API_URL.CUSTOMER_EDIT_PHONES, params)
    .then(dispatch(startLoading(loadingAction)))
    .then(
      () => {
        history.push({
          pathname: '/service-details',
          search,
          state: { success: 'phone', osDetails: { ...oldInfo, ...newInfo } },
        })
      },
      (err) => {
        renderToastError(failMsg)
        console.error(err)
      },
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const reschedule = (newInfo, oldInfo, search, history, failMsg) => (dispatch) => {
  const auth = parseQuery(search)
  const params = {
    ...newInfo,
    custPassword: localStorage.getItem('customerSmsCode'),
    vaCustCD: localStorage.getItem('customerCpf'),
    servcOrdSeq: oldInfo.servcOrdSeq,
    servcOrdSeqEncoded: auth.o,
    vaCustCDEncoded: auth.c,
  }

  const loadingAction = 'reschedule'

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .post(API_URL.CUSTOMER_RESCHEDULE, params)
    .then(dispatch(showSpinner()))
    .then(
      () => {
        history.push({
          pathname: '/service-details',
          search,
          state: {
            success: 'reschedule',
            osDetails: {
              ...oldInfo,
              date: newInfo.servcOrdSchdlgTs
                ? moment(newInfo.servcOrdSchdlgTs).format('L')
                : oldInfo.date,
              idSlotApplication: newInfo.servcOrdSchdlgShiftCd,
              statusOs: '1',
            },
          },
        })
      },
      (err) => {
        renderToastError(failMsg)
        console.error(err)
      },
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

/**
 * @typedef {{
 *  newInfo: { servcOrdSchdlgTs: string, servcOrdSchdlgShiftCd: string },
 *  servcOrdSeq: number,
 *  servcOrdSeqEncoded: string,
 *   vaCustCDEncoded: string
 * }} RescheduleNeedHelpParams
 */

/**
 * @param {RescheduleNeedHelpParams} params
 */
export const rescheduleNeedHelp = (params) => (dispatch) => {
  const { newInfo, servcOrdSeq, servcOrdSeqEncoded, vaCustCDEncoded } = params

  const body = {
    ...newInfo,
    custPassword: localStorage.getItem('customerSmsCode'),
    vaCustCD: localStorage.getItem('customerCpf'),
    servcOrdSeq,
    servcOrdSeqEncoded,
    vaCustCDEncoded,
  }

  const loadingAction = 'reschedule-needHelp'

  dispatch(startLoading(loadingAction))

  return new Api(null, dispatch)
    .post(API_URL.CUSTOMER_RESCHEDULE, body)
    .then(
      () => dispatch(showSpinner()),
      (err) => {
        renderToastError(i18next.t('customer.service.order.edit.save.fail'))
        console.error(err)
      },
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const getLastOsOcurrence = (servcOrdSeq) => (dispatch) => {
  const loadingAction = 'getLastOsOcurrence'
  const auth = parseQuery(true)

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .get(API_URL.GET_LAST_OCCURRENCE, { ...auth, servcOrdSeq })
    .then(({ data: lastOcurrence }) => {
      if (!lastOcurrence?.servcOrdEvntStusInd)
        return dispatch({
          type: FETCH_OCCURRENCES_ERROR,
          payload: { servcOrdSeq },
        })

      return dispatch({
        type: FETCH_OCCURRENCES_SUCCESS,
        payload: { servcOrdSeq, data: lastOcurrence },
      })
    })
    .catch((error) =>
      dispatch({
        type: FETCH_OCCURRENCES_ERROR,
        payload: { servcOrdSeq },
      }),
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const fetchServiceGroupInfo = (servcGrpCd) => (dispatch) => {
  const loadingAction = 'fetchServiceGroupInfo'

  dispatch(startLoading(loadingAction))

  return new Api(null, dispatch)
    .get(`${API_URL.FETCH_SERVICE_GROUP}/${servcGrpCd}`, {})
    .then(
      (response) => fetchServiceGroupSuccess(response.data, dispatch),
      (error) => fetchServiceGroupError(error, dispatch),
    )
    .finally(() => dispatch(stopLoading(loadingAction)))
}
