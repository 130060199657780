import ServcOrdCategoryType from './ServcOrdCategoryType'

export function isTechVisit(category: string) {
  return [
    ServcOrdCategoryType.TECHNICAL_VISIT.id,
    ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id,
  ].includes(category)
}

export function needTechReport(category: string) {
  return isTechVisit(category) || ServcOrdCategoryType.WARRANTY.id === category
}

export function isPlannedVisit(category: string) {
  return category === ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id
}

export function isTechVisitAllowed(user: any) {
  return (
    user?.allowedCategories.includes(Number(ServcOrdCategoryType.TECHNICAL_VISIT.id)) ||
    user?.allowedCategories.includes(Number(ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id))
  )
}
