import API_URL from '../../infra/api/endpoints'
import { ADEOResponsePublic } from '../../types/ADEOResponse'
import ApiRequest from '../../utils/ApiRequest'
import { catchCookies } from '../../utils/Cookies'
import { FETCH_PARAMETERS_ERROR, FETCH_PARAMETERS_SUCCESS } from './actionTypes'

export interface FetchSetupParametersResponse {
  servcPrvdrManualDistributionIsActive: boolean
  serviceProviderDistributionJobIsActive: boolean
  serviceProviderAllStoresMatchmaking: boolean
  servcGrpUseLocal: boolean
  jobTypUseLocal: boolean
  branchUseLocal: boolean
  itemUseLocal: boolean
  apiKey: string
  authDomain: string
  databaseURL: string
  storageBucket: string
  editSpecialistOn: boolean
  callAgent: number
  anewAgente: string
  anewRamal: string
  anewCampanha: string
  five9Campanha: string
  five9CheckDnc: boolean
  twilioFunctionsUrl: string
  twilioAccessTokenUrl: string
  currentBase: string
  initialLatitude: string
  initialLongitude: string
  leroyLogoIndex: number
  configShiftOptions: 'M' | 'A' | 'E'
  freeFeature: boolean
  idwallActiveIntegration: boolean
  resendEmailTimeout: number
  confirmationChangeAddress: boolean
  providerLandingPageLogin: boolean
  pdpSelectServicesMaxAmount: number
  serviceOrderShowAmount: boolean
  googleAnalyticsKey: string
  hotjarId: string
  paymentsDefaultDays: number
  paymentMaxRepass: number
  budgetPadding: boolean
  serviceOrderOutOfFlowEnable: boolean
  acceptanceSalesConditions: boolean
  serviceOrderChatMultiStoreUser: boolean
  pingoneEnable: boolean
  providerLinkedAgent: boolean
  acceptingServicePrice: boolean
  maximumLimitExtraction: number
  waitingForEvaluation: number
  maximumLimitExtractionLog: number
  tokenExpirationTime: string
  apiDaysServiceOrdersSchedule: string
  warantyPeriod: number
  chatEnabled: boolean
  captchaEnabled: boolean
  captchaKey: string
  canUpdateDocumentAfterApproval: boolean
  reschedulingAvailableShift: string
  canSearchFromSalesOrder: boolean
  scheduleDateQnty: number
  grpServcInStore: string
  enableSearchCEPApi: boolean
  displayTagsFromCreatedOrders: string
  validateNfeSendTime: number
  timeAvailableNfePortal: number
  permissionViewDocuments: number
  updateIdb: boolean
  timeAvaliableMovementsPayment: number
  enableServcOrdInvcSend: boolean
  askTheUserForCookies: boolean
  chatDbProvider: string
  disabledCalendarDays: string
  disableDaysAfterCreatingByServiceGroup: string
  usersMaxAgeRegister: number
  svrcOrderSendConfirmationSms: number
  termsConditionsPrivacyPolicy: string
  qlikSenseURL: string
  servcGrpWithServcValuePercentage: string
  maxLimitExtractionServiceOrderProviderPortal: number
  statusConfiguredDisplayScheduleLink: string
  elasticEnabled: boolean
  installerAcademyURL: string
  enableInstallerAcademy: boolean
  providerPortalURL: string
  maximumLimitQttySlots: number
  servcOrdClientMailEnabled: boolean
  enableSelectBranchsOnServcOrder: boolean
  apiInstalaMessagingSenderId: string
  apiInstalaAPPID: string
  apiKeyGoogleCloud: string
  servcOrdRescheduleDaysLimit: number
  reschedulingAvailableShiftMobile: string
  changePasswordApp: boolean
  optionalFieldsAddressClientPage: string
  showCustomerNumber: boolean
  suggestScheduleEnable: boolean
  categoriesConfiguredQuestionsClosingOs: string
  communicatiesStandardImage: string
  enableRedirectionNewCommunicatiesPWA: boolean
  statusConfiguredDisplayPreScheduleLink: string
  servcPrvdrAnalysisStartYear: number
  providerRegisterCategoriesMax: number
  providerRegisterServiveGroupsMax: number
  nifMandatory: boolean
  technicalReportTemplateServiceGroups: string
  cloudStoragePublicBucketUrl: string
  cockpitServicesDayBeforeDays: number
  daysServiceProviderContractDrafted: number
  pwaForceInstall: boolean
  pwaRemoveGroupOnRefuseReason: boolean
  clientChatDaysBefore: number
  storesForceShowPhone: string
  serviceGroupsForceShowPhone: string
  macroRegionalityPrimaryAreaMin: number
  macroRegionalityPrimaryAreaMax: number
  macroRegionalityPrimaryAreaAchievement: number
  macroRegionalitySecundaryAreaMin: number
  macroRegionalitySecundaryAreaMax: number
  macroRegionalitySecundaryAreaAchievement: number
  macroRegionalityStates: string
  servcOrdTechnicalReportLate: number
  distributeServiceBlockBeforeDelivery: boolean
  menuCustom1: string
  menuCustom2: string
  menuCustom3: string
  servcOrdBudgetComponent: boolean
  enableAcceptingServicePrice: boolean
  suggestScheduleHoursToConfirm: 1
  hideCockpitAlerts: boolean
  customerNeedHelpButtons: string
  vaCheckoutCartUrl: string
  macroRegionalStates: string
  servcResponsibleStoresEnabled: string
  reschduleByDeliveryDate: boolean
  storesHighRiskOsNeedToVisit: string
  enableStoreProvidersChat: boolean
  minimumTimeOSReschedulingProvider: number
  groupsNotAllowedSendTechnicalReportFiles: string
  appUrlPWA: string
  perfServcPrvdrAgnt: number
  perfServcPrvdrAgntPartnr: number
  allowDispatchBeforeTcSigned: boolean
  cockpitIndicatorServiceOnTime: string
  disabledDaysGlobal: number
  customerSexTrackPageShowConditionsOfServices: string
  useServiceGroupCodeServcOrdCreation: boolean
  servcOrdSpecialtiesPriorityEnabled: boolean
  pwaOffersColorIndicators: string
  serviceOrderMailSocialWhatsapp: string
  serviceOrderMailSocialTwitter: string
  serviceOrderMailSocialFacebook: string
  serviceOrderMailSocialInstagram: string
  serviceOrderMailSocialPinterest: string
  serviceOrderMailSocialYoutube: string
  homePageVersionCustomerPortal: number
  conformity: boolean
}

export const fetchSetupParameters = (dispatch: any) =>
  new ApiRequest()
    .get(API_URL.SETUP_PARAMETERS)
    .then((response: ADEOResponsePublic<FetchSetupParametersResponse>) => {
      if (!response.data) {
        console.error('Error fetching setup parameters')
        dispatch({
          type: FETCH_PARAMETERS_ERROR,
        })
        return
      }

      dispatch({
        type: FETCH_PARAMETERS_SUCCESS,
        payload: response.data,
      })

      const { askTheUserForCookies } = response.data || {}

      if (askTheUserForCookies) catchCookies()
    })
    .catch(() => {
      console.error('Error fetching setup parameters')
      dispatch({
        type: FETCH_PARAMETERS_ERROR,
      })
    })
