import { START_LOADING, STOP_LOADING } from './actionTypes'

export const startLoading = (action) => (dispatch) =>
  dispatch({
    type: START_LOADING,
    payload: action,
  })

export const stopLoading = (action) => (dispatch) =>
  dispatch({
    type: STOP_LOADING,
    payload: action,
  })
