import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ReactHtmlParser from 'react-html-parser'
import Select from 'react-select'
import { bindActionCreators } from 'redux'

import API_URL from '../../infra/api/endpoints'
import { requestService } from '../../redux/actions/customer'

import { FORMAT_DATE_PICKER } from '../../constants/config'

import moment from 'moment-timezone'

import Api from '../../utils/ApiRequest'
import { colourStyles } from '../../utils/selectStyle'
import { renderToastError, renderToastSuccess } from '../../utils/Toast'

import Body from '../../components/Body'
import Button from '../../components/Button'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import PicsUpload from '../../components/ServicePicsUpload'
import Col from '../../components/utils/Col'
import CustomerSelect from '../../components/utils/CustomerSelect'
import DatePicker from '../../components/utils/DatePicker'
import Input from '../../components/utils/Input'
import Row from '../../components/utils/Row'
import TextArea from '../../components/utils/TextArea'

class OrderRequest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        termsAcceptance: false,
        servcAddrPstlCd: '',
        servcAddrStrNm: '',
        servcAddrNr: '',
        servcAddrCmplmtryTxt: '',
        servcAddrDstrctNm: '',
        servcAddrCityNm: '',
        servcAddrStCd: '',
        servcAddrRefPointDesc: '',
        custFiscalId: '',
        custEmail: '',
        custName: '',
        custPhone: '',
        custMobilePhone: '',
        serviceDescription: '',
        plntCd: '',
        scheduleDate: '',
        shiftSelected: '',
        files: [],
      },
      apiAddress: {},
      options: [],
      shiftReturn: null,
      validations: {},
      hideUpload: false,
      loading: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleSubmit = () => {
    const { formData } = this.state
    const { requestService, t } = this.props

    if (this.beforeSubmit()) {
      this.setState({ loading: true })
      requestService(formData, () => {
        renderToastSuccess(t('customer.orderRequest.success'))
        this.setState({ loading: false })
        this.resetForm()
      })
    }
  }

  beforeSubmit() {
    const { t } = this.props
    const { formData } = this.state

    if (formData.custPhone.length > 0 && formData.custMobilePhone === formData.custPhone) {
      renderToastError(t('customer.orderRequest.equalPhones'))
      return false
    }

    return true
  }

  resetForm() {
    this.setState({
      formData: {
        termsAcceptance: false,
        servcAddrPstlCd: '',
        servcAddrStrNm: '',
        servcAddrNr: '',
        servcAddrCmplmtryTxt: '',
        servcAddrDstrctNm: '',
        servcAddrCityNm: '',
        servcAddrStCd: '',
        servcAddrRefPointDesc: '',
        custFiscalId: '',
        custEmail: '',
        custName: '',
        custPhone: '',
        custMobilePhone: '',
        serviceDescription: '',
        plntCd: '',
        scheduleDate: '',
        shiftSelected: '',
        files: [],
      },
      validations: {},
      hideUpload: true,
      termsAcceptance: false,
    })

    setTimeout(() => {
      this.setState({ hideUpload: false })
    }, 100)
  }

  searchPostalCode = (postalCode, updateValue) => {
    const { dispatch, t } = this.props
    const { formData } = this.state

    return new Api(null, dispatch)
      .get(API_URL.PUBLIC_SERVICE_PROVIDER_POSTAL_CODE, {
        postalCode: postalCode.replace('-', ''),
      })
      .then(
        (response) => {
          const apiAddress = response.data.dne[0]
          const addrObj = {}

          Object.entries(apiAddress).forEach(([key, value]) => (apiAddress[key] = value.trim()))

          if (apiAddress.streetName) addrObj.servcAddrStrNm = apiAddress.streetName
          else addrObj.servcAddrStrNm = ''
          if (apiAddress.state) addrObj.servcAddrStCd = apiAddress.state
          else addrObj.servcAddrStCd = ''
          if (apiAddress.neighborhood) addrObj.servcAddrDstrctNm = apiAddress.neighborhood
          else addrObj.servcAddrDstrctNm = ''
          if (apiAddress.city) addrObj.servcAddrCityNm = apiAddress.city
          else addrObj.servcAddrCityNm = ''

          if (updateValue) {
            this.setState({
              apiAddress,
              formData: { ...this.state.formData, ...addrObj },
            })
          } else {
            this.setState({ apiAddress })
          }
        },
        () => {
          this.setState({
            apiAddress: {},
            formData: {
              ...formData,
              servcAddrStrNm: '',
              servcAddrStCd: '',
              servcAddrDstrctNm: '',
              servcAddrCityNm: '',
            },
          })
          renderToastError(t('customer.service.order.address.edit.invalid.postal.code'))
        },
      )
  }

  handlePostalCodeChange = (postalCode) => {
    const { formData, validations } = this.state
    this.setState({
      formData: { ...formData, servcAddrPstlCd: postalCode },
      validations: { ...validations, servcAddrPstlCd: true },
    })

    if (postalCode && String(postalCode).length === 9) {
      this.searchPostalCode(postalCode, true)
    }
  }

  handleChangeDtWeek = () => {
    const { formData } = this.state
    const { t, params } = this.props
    const availableShifts = params.reschedulingAvailableShift
      .split(';')
      [moment(formData.scheduleDate).format('d')].slice(2)
      .split(',')
    const availableOptions = {
      M: t('suggest.schedule.spinner.morning'),
      A: t('suggest.schedule.spinner.afternoon'),
      E: t('suggest.schedule.spinner.night'),
    }
    const parsedOptions = availableShifts.map((shift) => ({
      value: shift,
      label: availableOptions[shift],
    }))
    this.setState({
      formData: { ...formData, shiftSelected: '' },
      options: parsedOptions,
      shiftReturn: null,
    })
  }

  handleFileChange = (pics) => {
    const { formData } = this.state

    this.setState({
      pics,
      formData: {
        ...formData,
        files: Object.entries(pics).map(([name, file]) => ({
          name,
          fileIndicador: 6,
          file: file
            .replace('data:image/png;base64,', '')
            .replace('data:image/jpg;base64,', '')
            .replace('data:image/jpeg;base64,', ''),
        })),
      },
    })
  }

  getLastDtAval = () => {
    const { params } = this.props
    const lastDtAval = new Date()
    lastDtAval.setDate(lastDtAval.getDate() + params.scheduleDateQnty)
    lastDtAval.setHours(0, 0, 0, 0)
    return lastDtAval
  }

  checkBtnDisabled() {
    const { formData, termsAcceptance, loading } = this.state

    let disabled = false

    const requiredFields = [
      'servcAddrPstlCd',
      'servcAddrStrNm',
      'servcAddrNr',
      'servcAddrDstrctNm',
      'servcAddrCityNm',
      'servcAddrStCd',
      'custFiscalId',
      'custEmail',
      'custName',
      'custMobilePhone',
      'plntCd',
      'scheduleDate',
      'shiftSelected',
    ]

    if (loading) {
      disabled = true
    } else if (!termsAcceptance) {
      disabled = true
    } else {
      for (const field of requiredFields) {
        if (!formData[field] || formData[field].length === 0) {
          disabled = true
        }
      }
    }

    return disabled
  }

  render() {
    const { formData, options, apiAddress, termsAcceptance, validations, hideUpload } = this.state
    const { t, dispatch, params, history } = this.props

    return (
      <>
        <HeaderV2 />
        <Body>
          <div className='data-edit-form order-request-page'>
            <h4>Dados pessoais</h4>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, custFiscalId: e.target.value },
                      validations: { ...validations, custFiscalId: true },
                    })
                  }
                  topLabel={t('field.name.person.main.document')}
                  mask='999.999.999-99'
                  value={formData.custFiscalId}
                  className='inputDisabled'
                  valid={
                    validations && validations.custFiscalId && !formData.custFiscalId ? false : null
                  }
                />
              </Col>
            </Row>

            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, custName: e.target.value },
                      validations: { ...validations, custName: true },
                    })
                  }
                  topLabel={t('field.name.name')}
                  value={formData.custName}
                  className='inputDisabled'
                  valid={validations && validations.custName && !formData.custName ? false : null}
                />
              </Col>
            </Row>

            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, custEmail: e.target.value },
                      validations: { ...validations, custEmail: true },
                    })
                  }
                  topLabel={t('field.name.person.email')}
                  value={formData.custEmail}
                  className='inputDisabled'
                  valid={validations && validations.custEmail && !formData.custEmail ? false : null}
                />
              </Col>
            </Row>

            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        custMobilePhone: e.target.value,
                      },
                      validations: { ...validations, custMobilePhone: true },
                    })
                  }
                  topLabel={t('customer.service.order.edit.phone.mobile')}
                  onBlur={(e) => {
                    e.target.value.length < 14 &&
                      this.setState({
                        formData: { ...formData, custMobilePhone: '' },
                      })
                  }}
                  mask='(99) 99999-9999'
                  value={formData.custMobilePhone}
                  className='inputDisabled'
                  valid={
                    validations && validations.custMobilePhone && !formData.custMobilePhone
                      ? false
                      : null
                  }
                />
              </Col>
            </Row>

            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, custPhone: e.target.value },
                    })
                  }
                  topLabel={ReactHtmlParser(t('customer.orderRequest.phone'))}
                  onBlur={(e) => {
                    e.target.value.length < 14 &&
                      this.setState({
                        formData: { ...formData, custPhone: '' },
                      })
                  }}
                  mask='(99) 99999-9999'
                  value={formData.custPhone}
                  className='inputDisabled'
                />
              </Col>
            </Row>

            <h4>Endereço de instalação</h4>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={3}>
                <Input
                  onChange={(e) => this.handlePostalCodeChange(e.target.value)}
                  topLabel={t('customer.service.order.edit.address.cep')}
                  value={formData.servcAddrPstlCd}
                  mask='99999-999'
                  className='inputDisabled'
                  valid={
                    validations && validations.servcAddrPstlCd && !formData.servcAddrPstlCd
                      ? false
                      : null
                  }
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrStrNm: e.target.value },
                      validations: { ...validations, servcAddrStrNm: true },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.address')}
                  value={formData.servcAddrStrNm}
                  disabled={!!apiAddress.streetName}
                  className='inputDisabled'
                  valid={
                    validations && validations.servcAddrStrNm && !formData.servcAddrStrNm
                      ? false
                      : null
                  }
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={3}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrNr: e.target.value },
                      validations: { ...validations, servcAddrNr: true },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.num')}
                  value={formData.servcAddrNr}
                  className='inputDisabled'
                  valid={
                    validations && validations.servcAddrNr && !formData.servcAddrNr ? false : null
                  }
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrCmplmtryTxt: e.target.value,
                      },
                    })
                  }
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.address.cmplmnt'))}
                  value={formData.servcAddrCmplmtryTxt}
                  className='inputDisabled'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrDstrctNm: e.target.value,
                      },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.neighbhd')}
                  value={formData.servcAddrDstrctNm}
                  disabled={!!apiAddress.neighborhood}
                  className='inputDisabled'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrCityNm: e.target.value },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.city')}
                  value={formData.servcAddrCityNm}
                  disabled={!!apiAddress.city}
                  className='inputDisabled'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrStCd: e.target.value },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.state')}
                  value={formData.servcAddrStCd}
                  disabled={!!apiAddress.state}
                  mask='AA'
                  className='inputDisabled'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrRefPointDesc: e.target.value,
                      },
                    })
                  }
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.address.ref'))}
                  value={formData.servcAddrRefPointDesc}
                  className='inputDisabled'
                />
              </Col>
            </Row>

            <h4>Serviço</h4>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <TextArea
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        serviceDescription: e.target.value,
                      },
                    })
                  }
                  placeholder={t('customer.orderRequest.requisition')}
                  value={formData.serviceDescription}
                  rows='4'
                  className='inputDisabled'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col lg={6} md={6} xs={12}>
                {!hideUpload && (
                  <CustomerSelect
                    dispatch={dispatch}
                    onChange={(e) =>
                      this.setState({
                        formData: { ...formData, plntCd: e.value },
                      })
                    }
                    t={t}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} className='relative margin-bottom-row'>
                <label className='top-label'>{t('servcOrd.schedule.date')}</label>
                <DatePicker
                  id='scheduleDate'
                  placeholder=''
                  onDateChange={(e) => {
                    this.setState({ formData: { ...formData, scheduleDate: moment(e._d) } }, () => {
                      this.handleChangeDtWeek()
                    })
                  }}
                  icon
                  isOutsideRange={(day) => day.isBefore(this.getLastDtAval())}
                  displayFormat={FORMAT_DATE_PICKER}
                  date={formData.scheduleDate}
                  dateFormat={FORMAT_DATE_PICKER}
                  isDayBlocked={(day) =>
                    params.reschedulingAvailableShift.indexOf(
                      String(parseInt(day.format('d')) + 1),
                    ) === -1
                  }
                />
              </Col>
              <Col xs={12} sm={6} md={6} className='margin-bottom-row'>
                <label className='top-label'>{t('serviceOrders.scheduleTime')}</label>
                <Select
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, shiftSelected: e.value },
                      shiftReturn: e,
                    })
                  }
                  placeholder=''
                  options={options}
                  styles={colourStyles}
                  value={this.state.shiftReturn}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row no-margin'>
              <h5>{t('customer.orderRequest.cupom')}</h5>
              {!hideUpload && (
                <PicsUpload handleFileChange={(file) => this.handleFileChange(file)} isSingleFile />
              )}
              <div>
                <small>
                  <i>({t('customer.orderRequest.onlyImages')})</i>
                </small>
              </div>
            </Row>
            <Row className='margin-bottom-row no-margin'>
              <Col>
                <span onClick={(_) => this.setState({ termsAcceptance: !termsAcceptance })}>
                  <input type='checkbox' checked={termsAcceptance} style={{ width: 'auto' }} />
                  <span style={{ padding: 10 }}>{t('generalConditions.checkbox.service')}</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={3}>
                <Button
                  onClick={() => history.goBack()}
                  label={t('customer.service.order.reschedule.cancel')}
                  variant='white'
                  fill
                />
              </Col>
              <Col xs={6} sm={6} md={3}>
                <Button
                  label={t('customer.service.order.reschedule.save')}
                  fill
                  disabled={this.checkBtnDisabled()}
                  onClick={() => this.handleSubmit()}
                />
              </Col>
            </Row>
          </div>
        </Body>
        <FooterV2 />
      </>
    )
  }
}

const OrderRequestTranslated = withTranslation()(OrderRequest)

const mapStateToProps = (state) => ({
  params: state.setupParameters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ requestService }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderRequestTranslated))
