import './index.scss'

interface PageProps {
  readonly children: React.ReactNode
  readonly className?: string
}

/**
 * @description Page component that wraps the entire page to delimiter the content
 */

export function Page({ children, className }: PageProps) {
  return (
    <section id='page' className={className}>
      {children}
    </section>
  )
}
