import { LightBoxProps } from '../types/components/lightbox'

function LightBox({ img, open, handleClose }: LightBoxProps) {
  return (
    <>
      {open && (
        <div
          className='lightbox-container'
          onClick={() => {
            handleClose && handleClose()
          }}
        >
          <div className='margin-white'>
            <img alt='lightbox' src={img} />
          </div>
        </div>
      )}
    </>
  )
}

export default LightBox
