import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import LeroyMerlinLogo from '../assets/icons/logo-leroy-merlin.svg?react'

function GenericClientHeader(props) {
  const {
    t,
    title,
    subtitle,
    customActions,
    history,
    params: { currentBase },
    location: { search },
    osDetails,
  } = props

  const showNeedHelpButton = false

  return (
    <div className='client-header'>
      <section className='texture-header'>
        <section className='body-header'>
          <div />
          <LeroyMerlinLogo className='lm-logo' />
        </section>
      </section>

      <section className='sub-header'>
        <section className='left-side'>
          {!!currentBase && <span className='env-badge'>{currentBase}</span>}
          {customActions?.map((ca, i) => (
            <h3 key={i} className='custom-action' onClick={ca.onClick}>
              {ca.label}
            </h3>
          ))}
          {(title || subtitle) && (
            <h3 className={title && title.length > 15 ? 'big-title' : ''}>
              {title} - {subtitle}
            </h3>
          )}
        </section>

        {!!localStorage.customerSmsCode && (
          <section className='header-actions'>
            {showNeedHelpButton && (
              <>
                <button
                  onClick={() => {
                    history.push({
                      pathname: '/service-details/need-help',
                      search,
                      state: osDetails,
                    })
                  }}
                >
                  {t('customer.service.order.need.help.button')}
                </button>
                <i className='divider'>|</i>
              </>
            )}
            <button
              onClick={() => {
                localStorage.removeItem('customerSmsCode')
                localStorage.removeItem('customerCpf')
                history.push({
                  pathname: '/login',
                })
              }}
            >
              {t('option.exit')}
            </button>
          </section>
        )}
      </section>
    </div>
  )
}

const GenericClientHeaderTranslated = withTranslation()(GenericClientHeader)

const mapStateToProps = (state) => ({
  params: state.setupParameters || {},
})

export default withRouter(connect(mapStateToProps)(GenericClientHeaderTranslated))
