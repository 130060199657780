import React, { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import GenericContainer from '../../../components/GenericClientContainer'
import { validateCpf, toPatternMultiple } from '../../../utils/masks'
import { customerLogin } from '../../../redux/actions/customer'
import { TextField, Button } from '@leroy-merlin-br/backyard-react'
import TelephoneInputField from '@develcode/react-phone-number-input'
import Mask from 'vanilla-masker'
import { validateMobilePhoneNumber } from '../../../utils/InternationalPhoneNumberUtils'

class LoginContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUserNotARobot: false,
      didSubmit: false,
      isCpfValid: false,
      custIdKey: '',
      userCpf: '',
      recaptchaToken: '',
    }
  }

  handleSubmit = () => {
    const { isUserNotARobot, custIdKey, recaptchaToken, isCpfValid, userCpf } = this.state
    const {
      customerLogin,
      history,
      params: { nifMandatory },
    } = this.props

    this.setState({ didSubmit: true })
    if (nifMandatory && isUserNotARobot && isCpfValid) {
      customerLogin(
        userCpf?.replace(/[^\d]/g, ''),
        recaptchaToken,
        () => history && history.push('/sms-code'),
      )
    } else if (!nifMandatory && isUserNotARobot) {
      customerLogin(
        custIdKey?.replace(/(?!^\+)[^\d\r\n]+/g, ''),
        recaptchaToken,
        () => history && history.push('/sms-code'),
      )
    }
  }

  handleCaptchaSubmission = (recaptchaToken) => {
    this.setState({ isUserNotARobot: true, recaptchaToken })
  }

  handleChange = (event, lng) => {
    const {
      params: { nifMandatory },
    } = this.props

    if (nifMandatory) {
      let {
        target: { value },
      } = event

      const mask = event.target.getAttribute('mask')

      const disableMultipleMask = false
      const allowEmpty = false

      if (mask && value) {
        value = disableMultipleMask ? Mask.toPattern(value, mask) : toPatternMultiple(value, mask)
      }

      if (allowEmpty && !value) {
        value = ' '
      }

      this.setState({
        userCpf: value,
        isCpfValid: lng === 'pt-br' ? validateCpf(value.replace(/[^\d]/g, '')) : true,
      })
    } else {
      this.setState({
        custIdKey: event,
        isCpfValid: true,
      })
    }
  }

  render() {
    const { isCpfValid, isUserNotARobot, didSubmit, custIdKey, userCpf } = this.state
    const { params, t, lng } = this.props

    return (
      <GenericContainer>
        {params.nifMandatory ? <h5>CPF:</h5> : <h5>Telemóvel:</h5>}
        {params.nifMandatory ? (
          <TextField
            mask={t('cpf.mask')}
            value={userCpf}
            state={isCpfValid ? 'valid' : didSubmit ? 'invalid' : ''}
            onChange={(e) => this.handleChange(e, lng)}
            hint={didSubmit && !isCpfValid && t('cpf.invalid.message')}
            style={{
              width: '10em',
            }}
          />
        ) : (
          <TelephoneInputField
            value={custIdKey}
            valid={!!custIdKey && validateMobilePhoneNumber(custIdKey)}
            onChange={(e) => this.handleChange(e, lng)}
            labelClassName='labelPhoneBreak'
          />
        )}

        <div className='recaptcha-container'>
          {navigator.onLine && params && params.captchaKey && (
            <ReCAPTCHA
              sitekey={params.captchaKey}
              className='recaptcha'
              onChange={(token) => this.handleCaptchaSubmission(token)}
              onExpired={() => this.setState({ isUserNotARobot: false })}
              onErrored={() => this.setState({ isUserNotARobot: false })}
            />
          )}
        </div>

        {didSubmit && !isUserNotARobot && (
          <span className='error'>{t('recaptcha.invalid.message')}</span>
        )}

        <Button
          disabled={!navigator.onLine}
          onClick={() => this.handleSubmit()}
          style={{
            width: '10em',
          }}
        >
          {t('pwa.login.button')}
        </Button>
      </GenericContainer>
    )
  }
}

const LoginContainerTranslated = withTranslation()(LoginContainer)

const mapStateToProps = (state) => ({
  params: state.setupParameters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ customerLogin }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainerTranslated))
