import React, { useEffect } from 'react'
import { connect } from 'react-redux'

function ActivityIndicator({ visible }) {
  useEffect(() => {
    const dom = document.getElementsByTagName('body')[0]
    if (!(dom && dom.style)) return

    if (visible) dom.style.overflow = 'hidden'
    else setTimeout(() => (dom.style.overflow = 'auto'), 250)
  }, [visible])

  return (
    <div className={`activity-indicator ${visible ? 'visible' : 'not-visible'}`}>
      <img alt='loading' src='/assets/loading.gif' />
    </div>
  )
}

const mapStateToProps = (state) => ({
  visible: state.activityIndicator.active,
})

export default connect(mapStateToProps)(ActivityIndicator)
