import React from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

class SpecialistBox extends React.Component {
  buildDate() {
    const { details } = this.props

    const date = moment(details.servcPrvdrAgntCreatTs || undefined)
    const today = moment()

    let diff = Math.floor(today.diff(date, 'months', true))

    const gender = details.servcPrvdrAgntGendrCd === 'F' ? 'f' : 'm'

    if (diff <= 12) {
      if (diff < 1) {
        diff = 1
      }
      return `${i18next.t(`customer.service.order.details.infos.partner${gender}.from`)} ${diff} ${
        diff === 1
          ? i18next.t('customer.service.order.details.infos.month')
          : i18next.t('customer.service.order.details.infos.months')
      }`
    }
    return `${i18next.t(
      `customer.service.order.details.infos.partner${gender}.since`,
    )} ${date.format('Y')}`
  }

  render() {
    const { step, details } = this.props
    const formattedDate = this.buildDate()

    return (
      <div className='info-box'>
        <p className='name'>{i18next.t('customer.service.order.details.infos.specialist')}</p>
        <div className='image rounded'>
          {details.servcPrvdrAgntProfilePicture ? (
            <img
              alt=''
              className='img'
              src={`data:image/png;base64,${details.servcPrvdrAgntProfilePicture}`}
            />
          ) : (
            <img alt='' className='img contain' src='/assets/installer-placeholder.png' />
          )}
        </div>
        <div className='right-infos'>
          <p>
            {i18next.t('customer.service.order.details.infos.name')}:{' '}
            <strong>{details.servcPrvdrAgntNm}</strong>
          </p>
          {step <= 3 ? (
            <>
              <p>
                {i18next.t('customer.service.order.details.infos.document')}:{' '}
                <b>{details.natrlPrsnAddlTaxIdNr}</b>
              </p>
            </>
          ) : (
            ''
          )}
          <p>
            {i18next.t('customer.service.order.details.infos.rating')}:{' '}
            <b>{`${details.sysAcsUserAvgGrade || ''}`.replace('.', ',')}</b>{' '}
            {i18next.t('customer.service.order.details.infos.fivepoints')}
          </p>
          <p>{formattedDate}</p>
          <Link
            to={{
              pathname: '/installer',
              search: `?id=${details.servcPrvdrAgntIdEncoded}`,
              state: { osDetails: details },
            }}
            className='link'
          >
            {i18next.t('customer.service.order.details.infos.moreInfo')}
          </Link>
        </div>
      </div>
    )
  }
}

export default SpecialistBox
export { SpecialistBox }
