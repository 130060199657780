import i18next from 'i18next'
import moment from 'moment-timezone'
import { schdlgCds } from '../../../constants/constants'
import tScheduleInfo from '../../../types/components/scheduleInfo'
import { getFluxLabel } from '../../../utils/ServiceOrderFlux'

import Calendar from '../../../assets/icons/calendar.svg?react'

function CanceledCalendarBody(props: tScheduleInfo) {
  const { flux, details, success } = props

  const date = (details.date && moment(details.date, 'DD/MM/YYYY')) || 0

  return (
    <div className='calendar'>
      <Calendar />
      <span>
        {getFluxLabel('customer.service.order.details.scheduling.date', flux)}
        {success === 'reschedule' && (
          <p className='success-edit-msg'>
            {i18next.t('customer.service.order.reschedule.requested') as string}
          </p>
        )}
      </span>
      <p>
        <s>
          {i18next
            .t('customer.service.order.scheduling.date.format')
            .replace('{}', (date && date.format('dddd')) || '-')
            .replace('{}', (date && date.format('L')) || '-')
            .replace(
              '{}',
              (details.idSlotApplication &&
                i18next.t(schdlgCds[details.idSlotApplication]).toLowerCase()) ||
                '-',
            )}
        </s>
      </p>
    </div>
  )
}

export default CanceledCalendarBody
