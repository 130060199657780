export const situationList = [
  'customer.service.order.situation.purchase.confirmed',
  'customer.service.order.situation.confirm.scheduling',
  'customer.service.order.situation.service.authorized',
  'customer.service.order.situation.evaluate.service',
  'customer.service.order.situation.service.warranty',
]

export const serviceOrderTitleList = [
  [
    'customer.service.order.details.first.step.title',
    'customer.service.order.details.first.step.subtitle',
  ],
  [
    'customer.service.order.details.second.step.title',
    'customer.service.order.details.second.step.subtitle',
  ],
  ['', 'customer.service.order.details.third.step.subtitle'],
  [
    'customer.service.order.details.fourth.step.title',
    'customer.service.order.details.fourth.step.subtitle',
  ],
  [
    'customer.service.order.details.fifth.step.title',
    'customer.service.order.details.fifth.step.subtitle',
  ],
]

export const schdlgCds = {
  M: 'service.order.calendar.morning',
  A: 'service.order.calendar.afternoon',
  N: 'service.order.calendar.night',
  E: 'service.order.calendar.night',
}

export const fluxes = {
  installation: 1,
  technicalVisit: 2,
  plannedFurnitureTechnicalVisit: 3,
  storeService: 5,
  hourlyService: 8,
  packageService: 13,
  highPotentialVisit: 16,
}

export const callCenterLink = 'https://leroymerlin.com.br/fale-conosco'
