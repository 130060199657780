import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'

import {
  FETCH_REASONS_LIST_REQUEST,
  FETCH_REASONS_LIST_SUCCESS,
  FETCH_REASONS_LIST_ERROR,
  FETCH_UNNACOMPLISHED_REASONS_LIST_REQUEST,
  FETCH_UNNACOMPLISHED_REASONS_LIST_SUCCESS,
  FETCH_UNNACOMPLISHED_REASONS_LIST_ERROR,
  UPDATE_REASON_REQUEST,
  UPDATE_REASON_SUCCESS,
  UPDATE_REASON_ERROR,
  FETCH_REASONS_TYPES_REQUEST,
  FETCH_REASONS_TYPES_SUCCESS,
  FETCH_REASONS_TYPES_ERROR,
  GET_PAGES,
} from './actionTypes'

const getPages = (metadata = {}) => ({
  type: GET_PAGES,
  pages: metadata.pageCount,
  current: metadata.page,
  count: metadata.totalCount,
})

export const fetchUnnacomplishedReasonsList = () => (dispatch) => {
  dispatch(fetchUnnacomplishedReasonsListRequest())

  return new Api(null, dispatch)
    .get(API_URL.FIND_UNNACOMPLISHED_REASONS)
    .then((response) => {
      dispatch(fetchUnnacomplishedReasonsListSuccess(response.data))
    })
    .catch((error) => {
      dispatch(fetchUnnacomplishedReasonsListError(error))
    })
}

/*
 * REQUEST REASONS
 */
export const fetchReasonsList =
  (token, page = 1) =>
  (dispatch) => {
    dispatch(fetchReasonsListRequest())

    const metadata = {
      page,
      size: 10,
    }

    const query = {}

    return new Api(token, dispatch, metadata)
      .get(API_URL.FIND_REASONS_LIST, query)
      .then((response) => {
        dispatch(getPages(response.metadata))
        dispatch(fetchReasonsListSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchReasonsListError(error))
      })
  }

export const fetchUnnacomplishedReasonsListRequest = () => ({
  type: FETCH_UNNACOMPLISHED_REASONS_LIST_REQUEST,
})

export const fetchUnnacomplishedReasonsListSuccess = (data) => ({
  type: FETCH_UNNACOMPLISHED_REASONS_LIST_SUCCESS,
  payload: data,
})

export const fetchUnnacomplishedReasonsListError = (error) => ({
  type: FETCH_UNNACOMPLISHED_REASONS_LIST_ERROR,
  payload: error,
})

export const fetchReasonsListRequest = () => ({
  type: FETCH_REASONS_LIST_REQUEST,
})

export const fetchReasonsListSuccess = (data) => ({
  type: FETCH_REASONS_LIST_SUCCESS,
  payload: data,
})

export const fetchReasonsListError = (error) => ({
  type: FETCH_REASONS_LIST_ERROR,
  payload: error,
})

/*
 * SAVE OR UPDATE REASON
 */
export const updateReason = (token, values, onSuccess) => (dispatch) => {
  dispatch(updateReasonRequest())

  const fields = {
    ...values,
  }

  return new Api(token, dispatch)
    .post(API_URL.SAVE_REASON, fields)
    .then((response) => {
      dispatch(updateReasonSuccess(response.data, onSuccess))
      dispatch(fetchReasonsList(token))
    })
    .catch((error) => {
      dispatch(updateReasonError(error))
    })
}

export const updateReasonRequest = () => ({
  type: UPDATE_REASON_REQUEST,
})

export const updateReasonSuccess = (data, onSuccess) => {
  onSuccess && onSuccess(data)

  return {
    type: UPDATE_REASON_SUCCESS,
    payload: data,
  }
}

export const updateReasonError = (error) => ({
  type: UPDATE_REASON_ERROR,
  payload: error,
})

/*
 * REQUEST REASONS TYPES
 */
export const fetchReasonsTypes = (token) => (dispatch) => {
  dispatch(fetchReasonsTypesRequest())

  return new Api(token, dispatch)
    .get(API_URL.FIND_REASONS_TYPES)
    .then((response) => {
      dispatch(fetchReasonsTypesSuccess(response.data))
    })
    .catch((error) => {
      dispatch(fetchReasonsTypesError(error))
    })
}

export const fetchReasonsTypesRequest = () => ({
  type: FETCH_REASONS_TYPES_REQUEST,
})

export const fetchReasonsTypesSuccess = (data) => ({
  type: FETCH_REASONS_TYPES_SUCCESS,
  payload: data,
})

export const fetchReasonsTypesError = (error) => ({
  type: FETCH_REASONS_TYPES_ERROR,
  payload: error,
})
