import { fluxes } from '../constants/constants'
import { getTranslationAppend } from '../utils/ServiceOrderFlux'

const ProgressBarVariation = (flux: number) => {
  const installationFlux = [
    `customer.service.order.situation.purchase.confirmed.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.confirm.scheduling.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.authorized.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.evaluate.service.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.warranty.${getTranslationAppend(flux)}`,
  ]

  const technicalVisitFlux = [
    `customer.service.order.situation.purchase.confirmed.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.confirm.scheduling.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.authorized.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.evaluate.service.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.finished.${getTranslationAppend(flux)}`,
  ]

  const plannedFurnitureTechnicalVisitFlux = [
    `customer.service.order.situation.purchase.confirmed.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.confirm.scheduling.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.authorized.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.finished.${getTranslationAppend(flux)}`,
  ]

  const packageServicesFlux = [
    `customer.service.order.situation.purchase.confirmed.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.select.packages.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.confirm.scheduling.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.authorized.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.evaluate.service.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.warranty.${getTranslationAppend(flux)}`,
  ]

  const hourlyServiceFlux = [
    `customer.service.order.situation.purchase.confirmed.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.confirm.scheduling.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.authorized.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.evaluate.service.${getTranslationAppend(flux)}`,
    `customer.service.order.situation.service.warranty.${getTranslationAppend(flux)}`,
  ]

  switch (flux) {
    case fluxes.installation:
      return installationFlux

    case fluxes.technicalVisit:
    case fluxes.highPotentialVisit:
      return technicalVisitFlux

    case fluxes.plannedFurnitureTechnicalVisit:
      return plannedFurnitureTechnicalVisitFlux

    case fluxes.packageService:
      return packageServicesFlux

    case fluxes.hourlyService:
      return hourlyServiceFlux

    default:
      return installationFlux
  }
}

export default ProgressBarVariation
