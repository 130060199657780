import { useTranslation } from 'react-i18next'

import SocialFacebookIcon from './images/social-facebook.svg?react'
import SocialInstagramIcon from './images/social-instagram.svg?react'
import SocialLinkedinIcon from './images/social-linkedin.svg?react'
import SocialPinterestIcon from './images/social-pinterest.svg?react'
import SocialTwitterIcon from './images/social-twitter.svg?react'
import SocialYoutubeIcon from './images/social-youtube.svg?react'

import './index.scss'

/**
 * @description Footer used in all pages of the site (v2)
 */

export function FooterV2() {
  const { t } = useTranslation()

  return (
    <footer id='footer-v2'>
      <section className='footer-v2__social-icons'>
        <a href='https://twitter.com/LeroyMerlinBRA' target='_blank' rel='noreferrer'>
          <SocialTwitterIcon />
        </a>

        <a href='https://www.facebook.com/LeroyMerlinBrasil' target='_blank' rel='noreferrer'>
          <SocialFacebookIcon />
        </a>

        <a href='https://www.instagram.com/leroymerlinbrasil' target='_blank' rel='noreferrer'>
          <SocialInstagramIcon />
        </a>

        <a href='https://br.pinterest.com/pinterest_leroymerlin/' target='_blank' rel='noreferrer'>
          <SocialPinterestIcon />
        </a>

        <a href='https://www.youtube.com/c/LeroyMerlinBRA' target='_blank' rel='noreferrer'>
          <SocialYoutubeIcon />
        </a>

        <a href='https://www.linkedin.com/company/leroy-merlin/' target='_blank' rel='noreferrer'>
          <SocialLinkedinIcon />
        </a>
      </section>
      <p className='footer-v2__text'>{t('customer.portal.footer.v2')}</p>
    </footer>
  )
}
