import API_URL from '../../infra/api/endpoints'
import ApiRequest from '../../utils/ApiRequest'

import { I18N_KEYS_REQUEST, FETCH_I18N_KEYS_SUCCESS, FETCH_I18N_KEYS_ERROR } from './actionTypes'

export const fetchI18nKeys = (userLanguage, onSuccess, onError) => (dispatch) => {
  dispatch(requestI18nKeys())

  const params = {
    dsI18n: userLanguage,
  }

  return new ApiRequest()
    .post(API_URL.LOCALES_LIST, params)
    .then((response) => {
      const data = response.data.lsKeys
      dispatch(fetchI18nKeysSuccess(data))
      onSuccess && onSuccess(data)
    })
    .catch((error) => {
      dispatch(fetchI18nKeysError(error))
      onError && onError()
    })
}

const requestI18nKeys = () => ({
  type: I18N_KEYS_REQUEST,
})

const fetchI18nKeysSuccess = (data) => ({
  type: FETCH_I18N_KEYS_SUCCESS,
  payload: data,
})

const fetchI18nKeysError = (error) => ({
  type: FETCH_I18N_KEYS_ERROR,
  payload: error,
})
