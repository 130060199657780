import { ThemeProvider } from '@emotion/react'
import { light } from '@leroy-merlin-br/backyard-theme'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import ActivityIndicator from '../components/ActivityIndicator'
import { fetchSetupParameters } from '../redux/actions/parameters'
import store from '../redux/store'
import Routes from '../routes/Root'
import I18nWrapper from '../utils/i18nWrapper'

fetchSetupParameters(store.dispatch)

function App() {
  const queryClient = new QueryClient()

  return (
    // @ts-ignore
    <ClearBrowserCacheBoundary auto fallback='' duration={60000}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={light}>
          <Provider store={store}>
            <ActivityIndicator />
            <ToastContainer newestOnTop />
            <I18nWrapper>
              <Routes />
            </I18nWrapper>
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </ClearBrowserCacheBoundary>
  )
}

export default App
