import {
  FETCH_UNNACOMPLISHED_REASONS_LIST_REQUEST,
  FETCH_UNNACOMPLISHED_REASONS_LIST_SUCCESS,
  FETCH_UNNACOMPLISHED_REASONS_LIST_ERROR,
  FETCH_REASONS_LIST_REQUEST,
  FETCH_REASONS_LIST_SUCCESS,
  FETCH_REASONS_LIST_ERROR,
  UPDATE_REASON_REQUEST,
  UPDATE_REASON_SUCCESS,
  UPDATE_REASON_ERROR,
  FETCH_REASONS_TYPES_REQUEST,
  FETCH_REASONS_TYPES_SUCCESS,
  FETCH_REASONS_TYPES_ERROR,
} from '../actions/actionTypes'

const DEFAULT_STATE = {
  list: {
    records: [],
    error: null,
    isFetching: false,
  },
  edit: {
    record: {},
    error: null,
    isUpdating: false,
  },
  types: {
    records: [],
    error: null,
    isFetching: false,
  },
  unnacomplishedList: {
    records: [],
    error: null,
    isFetching: false,
  },
}

const list = (state = DEFAULT_STATE, action) => {
  const data = action.payload

  switch (action.type) {
    case FETCH_UNNACOMPLISHED_REASONS_LIST_REQUEST:
      return {
        ...state,
        unnacomplishedList: {
          ...state.unnacomplishedList,
          isFetching: true,
        },
      }

    case FETCH_UNNACOMPLISHED_REASONS_LIST_SUCCESS:
      return {
        ...state,
        unnacomplishedList: {
          ...state.unnacomplishedList,
          isFetching: false,
          error: null,
          records: data,
        },
      }

    case FETCH_UNNACOMPLISHED_REASONS_LIST_ERROR:
      return {
        ...state,
        unnacomplishedList: {
          ...state.unnacomplishedList,
          isFetching: false,
          records: [],
          error: data,
        },
      }

    case FETCH_REASONS_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case FETCH_REASONS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: null,
          records: data,
        },
      }

    case FETCH_REASONS_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          records: [],
          error: data,
        },
      }

    case UPDATE_REASON_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: true,
        },
      }

    case UPDATE_REASON_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: false,
          error: null,
          record: data,
        },
      }

    case UPDATE_REASON_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: false,
          error: data,
          record: {},
        },
      }

    case FETCH_REASONS_TYPES_REQUEST:
      return {
        ...state,
        types: {
          ...state.types,
          isFetching: true,
        },
      }

    case FETCH_REASONS_TYPES_SUCCESS:
      return {
        ...state,
        types: {
          ...state.types,
          isFetching: false,
          error: null,
          records: data,
        },
      }

    case FETCH_REASONS_TYPES_ERROR:
      return {
        ...state,
        types: {
          ...state.types,
          isFetching: false,
          records: [],
          error: data,
        },
      }

    default:
      return state
  }
}

export default list
