import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import AddressEdit from '../pages/AddressEdit'
import SmsCode from '../pages/ClientSmsCode'
import Services from '../pages/Home'
import InstallerPage from '../pages/Installer'
import LoginPage from '../pages/Login'
import NeedHelp from '../pages/NeedHelp'
import PhoneEdit from '../pages/PhoneEdit/PhoneEdit'
import Reschedule from '../pages/Reschedule'
import ServiceDetails from '../pages/ServiceDetails'
import { ServiceDetailsV2 } from '../pages/ServiceDetailsV2'
import ServiceRequest from '../pages/ServiceRequest'

function Root() {
  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <Route exact path='/sms-code' component={SmsCode} />
        <Route exact path='/services' component={Services} />

        <Route exact path='/service-details' component={ServiceDetails} />
        <Route exact path='/v2/service-details' component={ServiceDetailsV2} />
        <Route exact path='/service-details/reschedule' component={Reschedule} />
        <Route exact path='/service-details/address-edit' component={AddressEdit} />
        <Route exact path='/service-details/phone-edit' component={PhoneEdit} />
        <Route exact path='/service-details/need-help' component={NeedHelp} />

        <Route exact path='/installer' component={InstallerPage} />

        <Route exact path='/service-request' component={ServiceRequest} />

        <Redirect from='/' to='/login' />
      </Switch>
    </Router>
  )
}

export default Root
