import { datadogRum } from '@datadog/browser-rum'
import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './containers/App'

import '@mozaic-ds/react/lib/index.css'
import 'react-dates/initialize'

import './fonts/LeroyMerlinSans.ttf'
import './styles/globals.scss'
import './styles/main.scss'

import '@develcode/react-phone-number-input/style.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'

import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/it'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/pt-br'

moment.tz.setDefault(moment.tz.guess())
moment.locale(navigator.language)

fetch(`${window.location.origin}/manifest.json`)
  .then((response) => response.json())
  .then(({ env, service, canary_version: version }) => {
    datadogRum.init({
      applicationId: '8be4d5f4-cdee-42b2-9660-5d9e5052dffc',
      clientToken: 'pubb0a2623107ec6c9c45cc8aca9af4453b',
      site: 'datadoghq.eu',
      service,
      env,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackFrustrations: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
  })
  .catch((error) => {
    console.error('Error fetching manifest.json', error)
  })

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
serviceWorkerRegistration.forceSWupdate()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
