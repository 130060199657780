import { START_LOADING, STOP_LOADING } from '../actions/actionTypes'

const defaultState = {
  loadingActions: [],
  active: false,
}

const ActivityIndicator = (state = defaultState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loadingActions: [...state.loadingActions, action.payload],
        active: true,
      }

    case STOP_LOADING:
      const loadingActionsList = state.loadingActions.filter((act) => act !== action.payload)
      return {
        ...state,
        loadingActions: loadingActionsList,
        active: loadingActionsList.length,
      }

    default:
      return state
  }
}

export default ActivityIndicator
