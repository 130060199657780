import React from 'react'
import PropTypes from 'prop-types'
import { AlertTriangleSolid, CheckmarkCircleSolid } from '@leroy-merlin-br/backyard-icons'

function QuantityInput(props) {
  const inputRef = React.createRef()
  const {
    className = '',
    label,
    name,
    hint,
    disableMore,
    disabled,
    readOnly,
    state = '',
    onBlur = () => {},
    onFocus = () => {},
    min,
    max,
    value = '0',
  } = props

  const handleChangeQuantity = (event) => {
    const { onChange = () => {}, min, max } = props

    const {
      target: { value, name },
    } = event

    let parsedValue = parseInt(value || '0', 10)

    if (min !== undefined && parsedValue < min) {
      parsedValue = min
    }

    if (max !== undefined && parsedValue > max) {
      parsedValue = max
    }

    onChange({ target: { value: parsedValue, name } })
  }

  const handleMinusQuantity = () => {
    const newValue = parseInt(value, 10) - 1

    inputRef.current.value = newValue
    handleChangeQuantity({ target: { value: newValue, name } })
  }

  const handlePlusQuantity = () => {
    const newValue = parseInt(value, 10) + 1

    inputRef.current.value = newValue
    handleChangeQuantity({ target: { value: newValue, name } })
  }

  const isMinimum = min !== undefined && parseInt(value, 10) <= min
  const isMaximum = max !== undefined && parseInt(value, 10) >= max

  return (
    <div className={`quantity-input ${className} ${state}`}>
      {!!label && <label>{label}</label>}
      <div className='container'>
        <MinusControl isMinimum={isMinimum} minus={handleMinusQuantity} disabled={disabled} />

        <MoreControl
          isMaximum={isMaximum}
          plus={handlePlusQuantity}
          disabled={disabled || disableMore}
        />
        <input
          type='number'
          readOnly={readOnly}
          disabled
          name={name}
          value={value || '0'}
          autoComplete='off'
          className={`${value ? 'filled' : ''}`}
          onChange={(event) => handleChangeQuantity(event)}
          onBlur={(e) => onBlur(e)}
          onFocus={(e) => onFocus(e)}
          ref={inputRef}
        />
      </div>
      {!!hint && (
        <div className='hint'>
          {state === 'valid' && <CheckmarkCircleSolid />}
          {(state === 'invalid' || state === 'warning') && <AlertTriangleSolid />}
          {hint}
        </div>
      )}
    </div>
  )
}

QuantityInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.any,
  disableMore: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  state: PropTypes.oneOf(['', 'valid', 'warning', 'invalid']),
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default QuantityInput

function MinusControl(props) {
  const { isMinimum, minus, disabled } = props

  if (disabled) return <div className='minus disabled' />

  return (
    <div className={`minus ${isMinimum ? 'disabled' : ''}`} onClick={() => !isMinimum && minus()} />
  )
}

function MoreControl(props) {
  const { isMaximum, plus, disabled } = props

  if (disabled) return <div className='plus disabled' />

  return (
    <div className={`plus ${isMaximum ? 'disabled' : ''}`} onClick={() => !isMaximum && plus()} />
  )
}
