import React from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

class ProviderBox extends React.Component {
  buildDate() {
    const { details } = this.props

    const date = moment(details.servcPrvdrCreatTs || undefined)
    const today = moment()

    let diff = Math.floor(today.diff(date, 'months', true))

    if (diff <= 12) {
      if (diff < 1) {
        diff = 1
      }
      return `${i18next.t('customer.service.order.details.infos.partnerf.from')} ${diff} ${
        diff === 1
          ? i18next.t('customer.service.order.details.infos.month')
          : i18next.t('customer.service.order.details.infos.months')
      }`
    }
    return `${i18next.t('customer.service.order.details.infos.partnerf.since')} ${date.format('Y')}`
  }

  render() {
    const { details } = this.props
    const formattedDate = this.buildDate()

    return (
      <div className='info-box'>
        <p className='name'>{i18next.t('customer.service.order.details.infos.company')}</p>
        <div className='image rounded'>
          <img
            alt=''
            className='img contain'
            src={
              details.filePath
                ? `data:image/png;base64, ${details.filePath}`
                : `assets/company-placeholder.svg`
            }
          />
        </div>
        <div className='right-infos'>
          <p>
            {i18next.t('customer.service.order.details.infos.name')}:{' '}
            <strong>
              {details.servcPrvdrTrdNm ? details.servcPrvdrTrdNm : details.servcPrvdrNm}
            </strong>
          </p>
          <p>
            {i18next.t('customer.service.order.details.infos.rating')}:{' '}
            <b>{`${details.servcPrvdrAvgGrade || ''}`.replace('.', ',')}</b>{' '}
            {i18next.t('customer.service.order.details.infos.fivepoints')}
          </p>
          <p>{formattedDate}</p>
          <Link
            to={{
              pathname: '/installer',
              search: `?id=${details.servcPrvdrAgntIdEncoded}`,
              state: { osDetails: details },
            }}
            className='link'
          >
            {i18next.t('customer.service.order.details.infos.moreInfo')}
          </Link>
        </div>
      </div>
    )
  }
}

export default ProviderBox
export { ProviderBox }
