import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveConfirmation } from '../redux/actions/confirmation'
import ReplaceTextKeys from '../utils/ReplaceTextKeys'
import Events from '../utils/ServcOrdEventStusType'
import GenericClientContainer from './GenericClientContainer'

import Warning from '../assets/icons/subtract.svg?react'

function ServiceConfirmation(props) {
  const {
    t,
    details,
    title,
    params: { svrcOrderSendConfirmationSms },
    occurrences,
  } = props

  if (
    [Events.AGENT_RESCHEDULE_REQUESTED.id].includes(
      occurrences?.[details.servcOrdSeq]?.servcOrdEvntStusInd,
    )
  )
    return null

  const dtLiberation = moment(details.date, 'DD/MM/YYYY').subtract(
    svrcOrderSendConfirmationSms,
    'days',
  )

  const disabled = moment().isBefore(dtLiberation)

  const titleText = t(title)

  const schedulingDate = details.date
  const schedulingSlot = t(details.dsSlot).toLowerCase()
  const schedulingWeekDay = moment(details.date, 'L').format('dddd')
  const installer = details.servcPrvdrAgntNm
  const provider = details.servcPrvdrTrdNm
  const serviceGroup =
    details?.servcGrpNm && details?.servcGrpNm?.split('-').length > 1
      ? details?.servcGrpNm?.split('-')[1].trim()
      : details?.servcGrpNm

  return (
    <GenericClientContainer className='full-width service-confirmation'>
      {disabled ? (
        <h4 className='title disabled-title'>
          <Warning className='warning' />
          <span>
            {t('customer.service.order.confirm.scheduling.title').replace(
              '{0}',
              dtLiberation.format('L'),
            )}
          </span>
        </h4>
      ) : (
        <h4 className='title'>
          <Warning />
          <span>
            {ReplaceTextKeys(titleText, {
              schedulingDate,
              schedulingSlot,
              schedulingWeekDay,
              installer,
              provider,
              serviceGroup,
            })}
          </span>
        </h4>
      )}
    </GenericClientContainer>
  )
}

const mapStateToProps = (state) => ({
  params: state.setupParameters,
  occurrences: state.occurrencesReducer,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ saveConfirmation }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServiceConfirmation)
