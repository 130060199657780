import classNames from 'classnames'
import { ButtonProps } from '../types/components/button'

function Button(props: ButtonProps) {
  const {
    label,
    icon,
    onClick,
    fitContent,
    variant,
    className,
    fill,
    disabled,
    type = 'button',
  } = props

  const btnClassNames = classNames(
    variant === 'white' ? 'generic-cancel-button' : 'generic-submit-button',
    (fitContent && 'word-nowrap') || '',
    className,
  )

  return (
    <button
      style={{ width: fitContent ? 'fit-content' : fill ? '100%' : '' }}
      className={btnClassNames}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon}
      {label}
    </button>
  )
}

export default Button
