import { ArrowArrowLeft16 } from '@mozaic-ds/icons/react'
import { Link, LinkLeftIcon } from '@mozaic-ds/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSetupParameters } from '../../hooks/useSetupParameters'

import LeroyMerlinInstalaDrillIcon from './images/leroy-merlin-instala-drill.svg?react'
import LeroyMerlinInstalaNameLogo from './images/leroy-merlin-instala-name.svg?react'
import LeroyMerlinLogo from './images/leroy-merlin-logo.svg?react'

import './index.scss'

interface HeaderV2Props {
  readonly onClickBack?: () => void
  readonly onClickNeedHelp?: () => void
}

/**
 * @description Header used in all pages (V2)
 */

export function HeaderV2({ onClickBack, onClickNeedHelp }: HeaderV2Props) {
  const history = useHistory()
  const { t } = useTranslation()
  const { currentBase } = useSetupParameters()

  const hasCustomer = !!localStorage.getItem('customerSmsCode')

  const handleOnClickExit = () => {
    localStorage.removeItem('customerSmsCode')
    localStorage.removeItem('customerCpf')
    history.push({
      pathname: '/login',
    })
  }

  return (
    <header id='header-v2'>
      <section className='header-v2__logo'>
        <div>
          <LeroyMerlinInstalaDrillIcon />
          <LeroyMerlinInstalaNameLogo />
        </div>
        {!!currentBase && <span className='header-v2__env'>{currentBase}</span>}
        <div>
          <LeroyMerlinLogo />
        </div>
      </section>

      <section className='header-v2__actions'>
        <div className='header-v2__back-action'>
          {onClickBack && (
            <Link size='s' onClick={onClickBack} theme='primary'>
              {/* @ts-ignore */}
              <LinkLeftIcon>
                <ArrowArrowLeft16 />
              </LinkLeftIcon>
              {t('button.name.back')}
            </Link>
          )}
        </div>
        <div className='header-v2__customer-actions'>
          {hasCustomer && (
            <>
              {onClickNeedHelp && (
                <>
                  <Link size='s' onClick={onClickNeedHelp}>
                    {t('provider.portal.help')}
                  </Link>
                  |
                </>
              )}
              <Link size='s' onClick={handleOnClickExit} className='header-v2__exit'>
                {t('option.exit')}
              </Link>
            </>
          )}
        </div>
      </section>
    </header>
  )
}
