import { FETCH_PARAMETERS_ERROR, FETCH_PARAMETERS_SUCCESS } from '../actions/actionTypes'

const defaultState = {}

function parameters(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PARAMETERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case FETCH_PARAMETERS_ERROR:
      return {
        ...state,
        error: 'Erro nos dados de configuração do firebase',
      }

    default:
      return state
  }
}

export default parameters
