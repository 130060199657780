import parsePhoneNumber from 'libphonenumber-js/max'
import { formatPhone } from './masks'
import { isValidPhoneNumber, formatPhoneNumber } from '@develcode/react-phone-number-input'

const MOBILE_PHONE_TYPE = 'MOBILE'
const FIXED_LINE_PHONE_TYPE = 'FIXED_LINE'
const FIXED_LINE_OR_MOBILE = 'FIXED_LINE_OR_MOBILE'

export const getPhoneNumberCountry = (phoneNumber) => {
  /**
   * Function used to return the country of a phone number corresponding to E164 format.
   *
   * @param phoneNumber E164 formatted phone number.
   *  Examples: `+5554999999999`, `+1123456789`.
   *
   * @returns The phone number identified country, in 2 digits format.
   *  Examples: `BR`, `US`, `RU`.
   */

  const parsedPhone = parsePhoneNumber(phoneNumber || '')

  return parsedPhone !== undefined ? parsedPhone.country : ''
}

export const formatNationalOrInternationalPhoneNumber = (
  translations,
  phoneNumber,
  phoneNumberAreaCode = null,
) => {
  /**
   * Function used to format a national or international phone number.
   *
   * @param translations The translations function.
   *
   * @param phoneNumber The phone number to be formatted.
   *  If the phone number is in `national` format, the area code has to be passed along the phone number.
   *  If the phone number is in `international` format, the area code doesn't need to be passed.
   *
   * @param phoneNumberAreaCode The phone number area code, required if phone number is in `national` format, optional otherwise.
   *
   * @returns A formatted phone number if it's formatted successfully, or the given raw phone number if it cannot be formatted.
   */

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber || '')

  if (parsedPhoneNumber !== undefined) {
    return formatPhoneNumber(phoneNumber)
  }
  if (phoneNumber && phoneNumberAreaCode) {
    return formatPhone(`${phoneNumberAreaCode}${phoneNumber}`, translations)
  }
  return phoneNumber
}

export const validateE164Phone = (phone) => {
  /**
   * Validate as the following conditions:
   *  If the phone length is 11, the first digit should be 9,
   *  If the phone length is 10, the first digit should be 2, 3, 4, 5 or 7,
   *  Not valid otherwise.
   *
   * @param phone
   * @param returns boolean
   */

  phone = phone.replace(/\D/g, '')
  if (!phone) return null
  if (phone.length < 10) return false

  phone = phone.substring(2)
  if (phone.length === 9) return phone.startsWith('9')
  return ['2', '3', '4', '5', '7'].includes(phone.charAt(0))
}

export const validatePhoneNumber = (phoneNumber) => {
  /**
   * Checks if a phone number is or not valid.
   *
   * If the phone number is identified as `brazillian` format, it runs the E164 phone manual validation,
   * otherwise it will do the normal validation.
   *
   * @param phone
   * @param returns boolean
   */

  const phoneNumberCountry = getPhoneNumberCountry(phoneNumber)

  if (phoneNumberCountry === 'BR') {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber || '')
    return validateE164Phone(parsedPhoneNumber.nationalNumber)
  }

  return isValidPhoneNumber(phoneNumber, phoneNumberCountry)
}

export const validateFixedLinePhoneNumber = (phoneNumber) => {
  /**
   * Checks if a phone number is or not valid and is a fixed line phone number.
   *
   * @param phone
   * @param returns boolean
   */
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber || '')

  return validatePhoneNumber(phoneNumber) && parsedPhoneNumber.getType() === FIXED_LINE_PHONE_TYPE
}

export const validateMobilePhoneNumber = (phoneNumber) => {
  /**
   * Checks if a phone number is or not valid and ensures it is a mobile phone number.
   *
   * @param phone
   * @param returns boolean
   */
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber || '')

  const phoneType = parsedPhoneNumber?.getType()
  const isMobile =
    phoneType !== undefined
      ? phoneType === MOBILE_PHONE_TYPE || phoneType === FIXED_LINE_OR_MOBILE
      : true

  return validatePhoneNumber(phoneNumber) && isMobile
}
