import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import TelephoneInputField from '../../components/utils/InternationalPhoneNumberField'
import API_URL from '../../infra/api/endpoints'
import { editInformation } from '../../redux/actions/customer'
import Api from '../../utils/ApiRequest'
import { renderToastError } from '../../utils/Toast'

import Body from '../../components/Body'
import Button from '../../components/Button'
import Col from '../../components/utils/Col'
import Input from '../../components/utils/Input'
import Row from '../../components/utils/Row'

import { isEmpty } from 'lodash'
import { getFluxLabel } from '../../utils/ServiceOrderFlux'

import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import {
  validateMobilePhoneNumber,
  validatePhoneNumber,
} from '../../utils/InternationalPhoneNumberUtils'
import { formatPostalCode, getPostalCodeMask, isSamePattern } from '../../utils/masks'

class AddressEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        custMobilePhonNr: '',
        custHmPhonNr: '',
        custBusnsPhonNr: '',
        servcAddrPstlCd: '',
        servcAddrStrNm: '',
        servcAddrNr: '',
        servcAddrCmplmtryTxt: '',
        servcAddrDstrctNm: '',
        servcAddrCityNm: '',
        servcAddrStCd: '',
        servcAddrRefPointDesc: '',
      },
      cepInputValid: true,
      apiAddress: {},
      isPhonesInvalid: false,
    }
  }

  componentDidMount() {
    const { formData } = this.state
    const {
      location: {
        state: { orderDetails, scrollTo },
      },
      t,
    } = this.props

    this.setState(
      {
        formData: {
          ...formData,
          ...Object.fromEntries(
            Object.entries(orderDetails).filter(([key, value]) =>
              Object.keys(formData).includes(key),
            ),
          ),
          servcAddrPstlCd: formatPostalCode(orderDetails.servcAddrPstlCd, t),
          custMobilePhonNr: orderDetails.custMobilePhonNr || '',
          custBusnsPhonNr: orderDetails.custBusnsPhonNr || '',
          custHmPhonNr: orderDetails.custHmPhonNr || '',
        },
      },
      () => {
        if (
          this.state.formData.servcAddrPstlCd &&
          String(this.state.formData.servcAddrPstlCd).length === 9
        ) {
          this.searchPostalCode(orderDetails.servcAddrPstlCd, false)
        }
      },
    )

    if (scrollTo) {
      document.getElementById(scrollTo).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    } else {
      window.scrollTo(0, 0)
    }
  }

  handleSubmit = () => {
    const { formData } = this.state
    const {
      editInformation,
      t,
      history,
      location: {
        search,
        state: { orderDetails },
      },
    } = this.props

    if (!this.phoneSubmitVerify()) return

    if (this.canSubmit()) {
      editInformation(
        formData,
        orderDetails,
        search,
        history,
        t('customer.service.order.edit.fail.address.phone'),
      )
    } else {
      renderToastError(t('customer.service.order.address.edit.invalid.postal.code'))
    }
  }

  phoneSubmitVerify = () => {
    const { formData } = this.state
    const { t } = this.props

    if (!formData.custMobilePhonNr) {
      renderToastError(t('customer.service.order.edit.phone.msg'))
      return false
    }

    const phoneFields = ['custMobilePhonNr', 'custHmPhonNr', 'custBusnsPhonNr']

    const hasDuplicates = phoneFields.some((field) =>
      phoneFields.some((phone) => {
        if (phone !== field) {
          const equal = formData[phone] === formData[field]
          const filled = formData[field] !== ''
          return equal && filled
        }
        return false
      }),
    )

    if (hasDuplicates) {
      renderToastError(t('customer.service.order.edit.phone.duplicate'))
      return false
    }

    return true
  }

  canSubmit = () => {
    const {
      params: { optionalFieldsAddressClientPage },
      location: {
        state: { orderDetails },
      },
    } = this.props
    const { formData, cepInputValid } = this.state

    const ignoreFields = ['custMobilePhonNr', 'custHmPhonNr', 'custBusnsPhonNr']
    const optionalFields = ignoreFields.concat(optionalFieldsAddressClientPage?.split(','))

    const requiredFieldsValid = !Object.keys(formData).some((key) =>
      optionalFields.includes(key) ? false : !formData[key],
    )

    const hasChanges = Object.keys(formData).some((key) =>
      orderDetails[key] ? orderDetails[key] !== formData[key] : formData[key],
    )

    return hasChanges && requiredFieldsValid && cepInputValid
  }

  isPhonesInvalid = (newPhones) => {
    const { custBusnsPhonNr, custHmPhonNr, custMobilePhonNr } = newPhones

    const checkCustMobilePhonNr =
      !validateMobilePhoneNumber(custMobilePhonNr) ||
      [custHmPhonNr, custBusnsPhonNr].includes(custMobilePhonNr)

    const checkCustHmPhonNr = !custHmPhonNr
      ? false
      : (custHmPhonNr && !validatePhoneNumber(custHmPhonNr)) ||
        [custMobilePhonNr, custBusnsPhonNr].includes(custHmPhonNr)

    const checkCustBusnsPhonNr = !custBusnsPhonNr
      ? false
      : (custBusnsPhonNr && !validatePhoneNumber(custBusnsPhonNr)) ||
        [custMobilePhonNr, custHmPhonNr].includes(custBusnsPhonNr)

    const disable = checkCustMobilePhonNr || checkCustHmPhonNr || checkCustBusnsPhonNr

    return disable
  }

  handleChange = (phone, value) => {
    const { formData } = this.state
    const newPhones = formData

    newPhones[phone] = value

    this.setState(
      {
        formData: { ...formData, ...newPhones },
        isPhonesInvalid: this.isPhonesInvalid(newPhones),
      },
      () => {
        // needed to make the cursor go to the end of input (maybe think of something better in the future)
        setTimeout(
          () =>
            document
              .getElementsByClassName('PhoneInputInput')
              [
                ['custMobilePhonNr', 'custHmPhonNr', 'custBusnsPhonNr'].indexOf(phone)
              ].setSelectionRange(-1, -1),
          10,
        )
      },
    )
  }

  searchPostalCode = (postalCode, updateValue) => {
    const { dispatch, t } = this.props
    return new Api(null, dispatch)
      .get(API_URL.PUBLIC_SERVICE_PROVIDER_POSTAL_CODE, {
        postalCode: postalCode.replace('-', ''),
      })
      .then(
        (response) => {
          if (response.data && response.data.dne.length > 0 && response.data.dne[0].IBGEcode) {
            const apiAddress = response.data.dne[0]
            const addrObj = {}

            Object.entries(apiAddress).forEach(([key, value]) => (apiAddress[key] = value.trim()))

            if (apiAddress.streetName) addrObj.servcAddrStrNm = apiAddress.streetName
            else addrObj.servcAddrStrNm = ''
            if (apiAddress.state) addrObj.servcAddrStCd = apiAddress.state
            else addrObj.servcAddrStCd = ''
            if (apiAddress.neighborhood) addrObj.servcAddrDstrctNm = apiAddress.neighborhood
            else addrObj.servcAddrDstrctNm = ''
            if (apiAddress.city) addrObj.servcAddrCityNm = apiAddress.city
            else addrObj.servcAddrCityNm = ''

            if (updateValue) {
              this.setState((prev) => ({
                ...prev,
                apiAddress,
                cepInputValid: true,
                formData: { ...prev.formData, ...addrObj },
              }))
            } else {
              this.setState((prev) => ({
                ...prev,
                apiAddress,
                cepInputValid: true,
              }))
            }
          } else {
            this.setState((prev) => ({
              ...prev,
              cepInputValid: true,
            }))
          }
        },
        () => {
          this.setState((prev) => ({
            ...prev,
            apiAddress: {},
            cepInputValid: false,
            formData: {
              ...prev.formData,
              servcAddrStrNm: '',
              servcAddrStCd: '',
              servcAddrDstrctNm: '',
              servcAddrCityNm: '',
            },
          }))
          renderToastError(t('customer.service.order.address.edit.invalid.postal.code'))
        },
      )
  }

  handlePostalCodeChange = (postalCode) => {
    const { t } = this.props
    this.setState((prev) => ({
      ...prev,
      formData: { ...prev.formData, servcAddrPstlCd: postalCode },
    }))

    if (isSamePattern(postalCode, getPostalCodeMask(t))) {
      this.searchPostalCode(postalCode, true)
    } else {
      this.setState((prev) => ({ ...prev, cepInputValid: false }))
    }
  }

  render() {
    const { formData, apiAddress, cepInputValid, isPhonesInvalid } = this.state
    const {
      t,
      history,
      location: {
        search,
        state: { orderDetails },
      },
    } = this.props

    const { custMobilePhonNr, custHmPhonNr, custBusnsPhonNr } = formData

    const flux = parseInt(orderDetails.servcCatgryTypCd)

    const handleOnClickBack = () => {
      history.push({
        pathname: '/service-details',
        search,
        state: { orderDetails },
      })
    }

    const handleOnClickHeaderNeedHelp = () => {
      history.push({
        pathname: '/service-details/need-help',
        search,
        state: osDetails,
      })
    }

    return (
      <>
        <HeaderV2 onClickBack={handleOnClickBack} onClickNeedHelp={handleOnClickHeaderNeedHelp} />
        <Body>
          <div className='data-edit-form'>
            <h4>{t('customer.service.order.address.edit.title')}</h4>
            <p>
              {ReactHtmlParser(
                getFluxLabel('customer.service.order.details.address.change.warning', flux),
              )}
            </p>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={3}>
                <Input
                  onChange={(e) => this.handlePostalCodeChange(e.target.value)}
                  topLabel={t('customer.service.order.edit.address.cep')}
                  value={formData.servcAddrPstlCd}
                  mask={t('postal.code.mask')}
                  valid={cepInputValid}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrStrNm: e.target.value },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.address')}
                  value={formData.servcAddrStrNm}
                  disabled={!!apiAddress.streetName && !isEmpty(formData.servcAddrStrNm)}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={3}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrNr: e.target.value },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.num')}
                  value={formData.servcAddrNr}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrCmplmtryTxt: e.target.value,
                      },
                    })
                  }
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.address.cmplmnt'))}
                  value={formData.servcAddrCmplmtryTxt}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrDstrctNm: e.target.value,
                      },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.neighbhd')}
                  value={formData.servcAddrDstrctNm}
                  disabled={!!apiAddress.neighborhood && !isEmpty(formData.servcAddrDstrctNm)}
                  maxLength={80}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrCityNm: e.target.value,
                      },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.city')}
                  value={formData.servcAddrCityNm}
                  disabled={!!apiAddress.city && !isEmpty(formData.servcAddrCityNm)}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={6} md={6}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: { ...formData, servcAddrStCd: e.target.value },
                    })
                  }
                  topLabel={t('customer.service.order.edit.address.state')}
                  value={formData.servcAddrStCd}
                  disabled={!!apiAddress.state && !isEmpty(formData.servcAddrStCd)}
                  mask='AA'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={12}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      formData: {
                        ...formData,
                        servcAddrRefPointDesc: e.target.value,
                      },
                    })
                  }
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.address.ref'))}
                  value={formData.servcAddrRefPointDesc}
                />
              </Col>
            </Row>
            <h4 id='title-phone'>{t('customer.service.order.edit.phone.title')}</h4>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={5}>
                <TelephoneInputField
                  topLabel={t('customer.service.order.edit.phone.mobile')}
                  label={t('customer.service.order.edit.phone.msg')}
                  value={custMobilePhonNr}
                  valid={
                    !!custMobilePhonNr &&
                    validateMobilePhoneNumber(custMobilePhonNr) &&
                    ![custHmPhonNr, custBusnsPhonNr].includes(custMobilePhonNr)
                  }
                  onChange={(value) => this.handleChange('custMobilePhonNr', value || '')}
                  labelClassName='labelPhoneBreak'
                  className='labelPhoneBreak'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={5}>
                <TelephoneInputField
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.phone.home'))}
                  value={custHmPhonNr}
                  valid={
                    custHmPhonNr !== ''
                      ? validatePhoneNumber(custHmPhonNr) &&
                        ![custMobilePhonNr, custBusnsPhonNr].includes(custHmPhonNr)
                      : ''
                  }
                  onChange={(value) => this.handleChange('custHmPhonNr', value || '')}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={5}>
                <TelephoneInputField
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.phone.bsns'))}
                  value={custBusnsPhonNr}
                  valid={
                    custBusnsPhonNr !== ''
                      ? validatePhoneNumber(custBusnsPhonNr) &&
                        ![custMobilePhonNr, custHmPhonNr].includes(custBusnsPhonNr)
                      : ''
                  }
                  onChange={(value) => this.handleChange('custBusnsPhonNr', value || '')}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={6} sm={6} md={3}>
                <Button
                  onClick={() => history.goBack()}
                  label={t('customer.service.order.reschedule.cancel')}
                  variant='white'
                  fill
                />
              </Col>
              <Col xs={6} sm={6} md={3}>
                <Button
                  label={t('customer.service.order.reschedule.save')}
                  fill
                  onClick={() => this.handleSubmit()}
                  disabled={isPhonesInvalid || !this.canSubmit()}
                />
              </Col>
            </Row>
          </div>
        </Body>
        <FooterV2 />
      </>
    )
  }
}

const AddressEditTranslated = withTranslation()(AddressEdit)

const mapStateToProps = (state) => ({
  params: state.setupParameters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ editInformation }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressEditTranslated))
