import i18next from 'i18next'
import moment from 'moment-timezone'
import { schdlgCds } from '../../../constants/constants'
import tScheduleInfo from '../../../types/components/scheduleInfo'
import { getFluxLabel } from '../../../utils/ServiceOrderFlux'
import ServiceOrderStatusType from '../../../utils/ServiceOrderStatusType'
import { isPlannedVisit, isTechVisit } from '../../../utils/isTechVisit'
import Button from '../../Button'
import CanceledCalendarBox from './CanceledCalendarBox'

import Calendar from '../../../assets/icons/calendar.svg?react'

function CalendarBox(props: tScheduleInfo) {
  const {
    flux,
    details,
    step,
    isRescheduledByClient,
    openReschedulePage,
    isCanceled,
    warrantyStep,
  } = props
  const { servcOrdStusCd } = details

  if (isCanceled) return <CanceledCalendarBox {...props} />

  const nonConforming = servcOrdStusCd === ServiceOrderStatusType.NAO_CONFORME.id
  const showWarrantyDate = !(isTechVisit(flux) || isPlannedVisit(flux) || nonConforming)

  const showRescheduleBtn = false

  const date = (details.date && moment(details.date, 'DD/MM/YYYY')) || 0

  return (
    <div className='calendar'>
      <Calendar />
      <span>
        {i18next.t('conformity.osData.instNumber') as string} <b> {details.servcOrdSeq}</b>
      </span>

      <span>
        {warrantyStep && (
          <>
            <span>
              {getFluxLabel('customer.service.order.details.finish.date', flux)}{' '}
              <b>{(date && date.format('L')) || '-'}</b>
            </span>
            {showWarrantyDate && (
              <>
                {i18next.t('customer.service.order.details.infos.warranty')}:
                <b> {moment(details.warrantyMaxDt).format('L')}</b>
              </>
            )}
          </>
        )}

        {!warrantyStep && (
          <>
            {getFluxLabel(
              'customer.service.order.details.scheduling.date',
              flux,
              details.servcOrdStusCd === ServiceOrderStatusType.OUT_OF_FLOW.id ? 'previst' : '',
              step,
            )}

            {isRescheduledByClient && (
              <strong>
                {' '.concat(i18next.t('customer.service.order.details.scheduling.date.wait'))}
              </strong>
            )}
            {!isRescheduledByClient && (
              <b className='ml-1'>
                {i18next
                  .t('customer.service.order.scheduling.date.format')
                  .replace('{}', (date && date.format('dddd')) || '-')
                  .replace('{}', (date && date.format('L')) || '-')
                  .replace(
                    '{}',
                    (details.idSlotApplication &&
                      i18next.t(schdlgCds[details.idSlotApplication]).toLowerCase()) ||
                      '-',
                  )}
              </b>
            )}
          </>
        )}
      </span>

      {showRescheduleBtn && (
        <Button
          onClick={() => openReschedulePage()}
          className='margin button-fit-content'
          fitContent
          variant='white'
          label={i18next.t('customer.service.order.reschedule.button')}
        />
      )}
    </div>
  )
}

export default CalendarBox
