import { FETCH_TEMPLATES_BY_ID_SUCCESS, FETCH_TEMPLATES_BY_ID_ERROR } from '../actions/actionTypes'

const DEFAULT_STATE = {
  templateFooterServiceDetails: {
    value: '',
    error: null,
  },
}

const templates = (state = DEFAULT_STATE, action) => {
  const data = action.payload

  switch (action.type) {
    case FETCH_TEMPLATES_BY_ID_SUCCESS:
      return {
        ...state,
        templateFooterServiceDetails: {
          value: data.servcGenrlTmpltText,
          error: null,
        },
      }

    case FETCH_TEMPLATES_BY_ID_ERROR:
      return {
        ...state,
        templateFooterServiceDetails: {
          error: data,
          value: null,
        },
      }

    default:
      return state
  }
}

export default templates
