import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import i18next from 'i18next'
import {
  QuestionType,
  QuestionAnswerType,
  UnnacomplishedReason,
  CommentFile,
  QuestionsValueType,
  InstallerPerformanceRating,
} from '../types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchUnnacomplishedReasonsList } from '../../../redux/actions/reasons'
import { ServiceOrderDetailV2 } from '../../../models/service-order-detail-v2'
import { fluxes } from '../../../constants/constants'
import IconCheckNoColor from '../../../assets/icons/check-no-green.svg'
import IconCheckNoWhite from '../../../assets/icons/check-no-white.svg'
import IconCheckYesColor from '../../../assets/icons/check-yes-green.svg'
import IconCheckYesWhite from '../../../assets/icons/check-yes-white.svg'
import ApiRequest from '../../../utils/ApiRequest'
import API_URL from '../../../infra/api/endpoints'

interface SatisfactionSurveyContextType {
  questions: QuestionsValueType
  isStoreServiceFlux: boolean
  alreadyHasAnswer: boolean
  files: CommentFile[]
  setFiles: React.Dispatch<React.SetStateAction<CommentFile[]>>
  commentText: string
  setCommentText: React.Dispatch<React.SetStateAction<string>>
}

interface SatisfactionSurveyProviderProps {
  children: ReactNode
  osDetails: ServiceOrderDetailV2
  unnacomplishedReasonsList?: UnnacomplishedReason[]
  fetchUnnacomplishedReasonsList?: Function
  dispatch: Function
}

const SatisfactionSurveyContext = createContext<SatisfactionSurveyContextType | null>(null)

function SatisfactionSurveyProvider(props: SatisfactionSurveyProviderProps) {
  const { osDetails, unnacomplishedReasonsList, fetchUnnacomplishedReasonsList, dispatch } = props

  const [questionServiceWasPerformed, setQuestionServiceWasPerformed] = useState<
    QuestionType<boolean>
  >({
    title: i18next.t('satisfactionSurvey.questions.serviceWasPerformed.title'),
    type: QuestionAnswerType.buttons,
    options: [
      {
        value: true,
        label: i18next.t('satisfactionSurvey.questions.serviceWasPerformed.yes'),
        icon: {
          checked: IconCheckYesWhite,
          unchecked: IconCheckYesColor,
        },
      },
      {
        value: false,
        label: i18next.t('satisfactionSurvey.questions.serviceWasPerformed.no'),
        icon: {
          checked: IconCheckNoWhite,
          unchecked: IconCheckNoColor,
        },
      },
    ],
  })

  const [questionIndScoreDelivery, setQuestionIndScoreDelivery] = useState<QuestionType<number>>({
    type: QuestionAnswerType.buttons,
    title: i18next.t('satisfactionSurvey.questions.indScoreDelivery.title'),
    options: Array.from({ length: 5 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    })),
    bottomLabels: [
      i18next.t('satisfactionSurvey.questions.indScoreDelivery.labelBad'),
      i18next.t('satisfactionSurvey.questions.indScoreDelivery.labelGood'),
    ],
  })

  const [questionIndScoreProduct, setQuestionIndScoreProduct] = useState<QuestionType<number>>({
    type: QuestionAnswerType.buttons,
    title: i18next.t('satisfactionSurvey.questions.indScoreProduct.title'),
    description: i18next.t('satisfactionSurvey.questions.indScoreProduct.help'),
    options: Array.from({ length: 5 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    })),
    bottomLabels: [
      i18next.t('satisfactionSurvey.questions.indScoreProduct.labelBad'),
      i18next.t('satisfactionSurvey.questions.indScoreProduct.labelGood'),
    ],
  })

  const [questionIndScoreRating, setQuestionIndScoreRating] = useState<QuestionType<number>>({
    type: QuestionAnswerType.buttons,
    title: i18next.t('satisfactionSurvey.questions.indScoreRating.title'),
    description: i18next.t('satisfactionSurvey.questions.indScoreRating.help'),
    options: Array.from({ length: 5 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    })),
    bottomLabels: [
      i18next.t('satisfactionSurvey.questions.indScoreRating.labelBad'),
      i18next.t('satisfactionSurvey.questions.indScoreRating.labelGood'),
    ],
  })

  const [questionIndEvaluationRating, setQuestionIndEvaluationRating] = useState<
    QuestionType<number>
  >({
    type: QuestionAnswerType.buttons,
    title: i18next.t('satisfactionSurvey.questions.indEvaluationRating.title'),
    description: i18next.t('satisfactionSurvey.questions.indEvaluationRating.help'),
    options: Array.from({ length: 5 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    })),
    bottomLabels: [
      i18next.t('satisfactionSurvey.questions.indEvaluationRating.labelBad'),
      i18next.t('satisfactionSurvey.questions.indEvaluationRating.labelGood'),
    ],
  })

  const [questionIndScoreRecommend, setQuestionIndScoreRecommend] = useState<QuestionType<number>>({
    type: QuestionAnswerType.buttons,
    title: i18next.t('satisfactionSurvey.questions.indScoreRecommend.title'),
    options: Array.from({ length: 11 }, (_, i) => ({
      label: i.toString(),
      value: i,
    })),
    bottomLabels: [
      i18next.t('satisfactionSurvey.questions.indScoreRecommend.labelBad'),
      i18next.t('satisfactionSurvey.questions.indScoreRecommend.labelGood'),
    ],
  })

  const [questionLsReason, setQuestionLsReason] = useState<QuestionType<number[]>>({
    type: QuestionAnswerType.multiple,
    title: i18next.t('satisfactionSurvey.questions.lsReason.title'),
    options: Array.from({ length: 5 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    })),
  })

  const [questionTechnicalVisit, setQuestionTechnicalVisit] = useState<QuestionType<boolean>>({
    type: QuestionAnswerType.buttons,
    title: i18next.t('satisfactionSurvey.questions.technicalVisit.title'),
    options: [
      {
        value: true,
        label: i18next.t('satisfactionSurvey.questions.technicalVisit.yes'),
        icon: {
          checked: IconCheckYesWhite,
          unchecked: IconCheckYesColor,
        },
      },
      {
        value: false,
        label: i18next.t('satisfactionSurvey.questions.technicalVisit.no'),
        icon: {
          checked: IconCheckNoWhite,
          unchecked: IconCheckNoColor,
        },
      },
    ],
  })

  const [questionSelectedReason, setQuestionSelectedReason] = useState<QuestionType<number>>({
    title: i18next.t('satisfactionSurvey.questions.selectedReason.title'),
    type: QuestionAnswerType.radios,
    options: [],
  })

  const [commentText, setCommentText] = useState<string>('')

  const [files, setFiles] = useState<CommentFile[]>([])

  useEffect(() => {
    fetchUnnacomplishedReasonsList!()
  }, [])

  useEffect(() => {
    if (unnacomplishedReasonsList?.length && !questionSelectedReason.options.length) {
      setQuestionSelectedReason((q) => ({
        ...q,
        options: unnacomplishedReasonsList.map((r) => ({
          label: r.servcOrdEvntTypRsnDesc,
          value: r.servcOrdEvntTypRsnId,
          description: r.servcOrdEvntTypRsnDetail,
        })),
      }))
    }
  }, [unnacomplishedReasonsList])

  useEffect(() => {
    new ApiRequest('', dispatch)
      .get(API_URL.PERFORMANCE_RATINGS_FIND_BY_STARS, {
        satsftnSrvyRtgNr: questionIndScoreRating.answer,
        buCd: osDetails.buCd,
        cntryCd: osDetails.cntryCd,
      })
      .then((response: { data: InstallerPerformanceRating[] }) => {
        setQuestionLsReason((q) => ({
          ...q,
          options: response.data
            .filter((x) => Number(x.actvSatsftnSrvyRtgRsnInd) === 1)
            .map((x) => ({
              label: x.satsftnSrvyRtgRsnTxt,
              value: x.satsftnSrvyRtgRsnId,
            })),
          answer: [],
        }))
      })
      .catch(console.error)
  }, [questionIndScoreRating])

  useEffect(() => {
    setQuestionTechnicalVisit((q) => ({ ...q, answer: undefined }))
  }, [questionIndEvaluationRating])

  useEffect(() => {
    setQuestionLsReason((q) => ({ ...q, answer: [] }))
  }, [questionIndScoreRating])

  useEffect(() => {
    ;[
      setQuestionIndScoreDelivery,
      setQuestionIndScoreProduct,
      setQuestionIndScoreRating,
      setQuestionIndEvaluationRating,
      setQuestionIndScoreRecommend,
      setQuestionLsReason,
      setQuestionTechnicalVisit,
      setQuestionSelectedReason,
    ].forEach((setQuestion) => setQuestion((q: any) => ({ ...q, answer: undefined })))
  }, [questionServiceWasPerformed])

  const isStoreServiceFlux = Number(osDetails.servcCatgryTypCd) === fluxes.storeService

  const alreadyHasAnswer = !!osDetails.dsEvaluation || !!osDetails.selectedReason

  const questions: QuestionsValueType = {
    serviceWasPerformed: {
      get: questionServiceWasPerformed,
      set: setQuestionServiceWasPerformed,
    },
    indScoreDelivery: {
      get: questionIndScoreDelivery,
      set: setQuestionIndScoreDelivery,
    },
    indScoreProduct: {
      get: questionIndScoreProduct,
      set: setQuestionIndScoreProduct,
    },
    indScoreRating: {
      get: questionIndScoreRating,
      set: setQuestionIndScoreRating,
    },
    indEvaluationRating: {
      get: questionIndEvaluationRating,
      set: setQuestionIndEvaluationRating,
    },
    indScoreRecommend: {
      get: questionIndScoreRecommend,
      set: setQuestionIndScoreRecommend,
    },
    lsReason: {
      get: questionLsReason,
      set: setQuestionLsReason,
    },
    technicalVisit: {
      get: questionTechnicalVisit,
      set: setQuestionTechnicalVisit,
    },
    selectedReason: {
      get: questionSelectedReason,
      set: setQuestionSelectedReason,
    },
  }

  const contextValue = useMemo(
    () => ({
      questions,
      isStoreServiceFlux,
      alreadyHasAnswer,
      files,
      setFiles,
      commentText,
      setCommentText,
    }),
    [questions, isStoreServiceFlux, alreadyHasAnswer, files, setFiles, commentText, setCommentText],
  )

  return (
    <SatisfactionSurveyContext.Provider value={contextValue}>
      {props.children}
    </SatisfactionSurveyContext.Provider>
  )
}

const useSatisfactionSurveyContext = () =>
  useContext(SatisfactionSurveyContext) as SatisfactionSurveyContextType

export { SatisfactionSurveyContext, useSatisfactionSurveyContext }

const mapStateToProps = (state: any) => ({
  unnacomplishedReasonsList: state.reasonsReducer.unnacomplishedList.records,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ fetchUnnacomplishedReasonsList, dispatch }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SatisfactionSurveyProvider)
