import { combineReducers } from 'redux'

import i18nReducer from './I18n'
import activityIndicator from './activityIndicator'
import customerReducer from './customer'
import occurrencesReducer from './occurrencesReducer'
import paginateReducer from './paginate'
import setupParameters from './parameters'
import reasonsReducer from './reasons'
import templatesReducer from './templates'

const rootReducer = combineReducers({
  i18nReducer,
  customerReducer,
  reasonsReducer,
  activityIndicator,
  setupParameters,
  occurrencesReducer,
  templatesReducer,
  paginateReducer,
})

export default rootReducer
export type RootReducer = ReturnType<typeof rootReducer>
