const AVAILABLE_LANGUAGES = ['pt-br', 'pt-pt', 'en-us', 'fr-fr', 'pl-pl', 'it-it', 'es-es']
const ADITTIONAL_LANGUAGES = ['de-de']
const DEFAULT_LANGUAGE = 'en-us'

/**
 * Define configuracoes do idioma
 */
class LanguageConfig {
  constructor(prefix) {
    // Prefixo para key das traduções no localStorage
    this.i18nPrefix = prefix
  }

  /**
   * Idioma do usuário. Por padrão sera a linguagem do browser.
   * @return {String}
   */
  get userLanguage() {
    let language = navigator.language.toLowerCase()
    if (language.length < 5) {
      language = `${language}-${language}`
    }

    if (!AVAILABLE_LANGUAGES.includes(language)) {
      language = DEFAULT_LANGUAGE
    }

    if (language === 'pl') {
      return 'pl-pl'
    }

    return language
  }

  get allLanguages() {
    return [...AVAILABLE_LANGUAGES, ...ADITTIONAL_LANGUAGES]
  }

  /**
   * Key do idioma salva no localStorage
   * @return {String}
   */
  keyI18next() {
    const regex = new RegExp(this.i18nPrefix, 'g')

    for (var key in localStorage) {
      if (localStorage.hasOwnProperty(key) && key.match(regex)) {
        return key
      }
    }
  }

  /**
   * Limpa o cache do idioma do localStorage
   */
  clearI18nCache() {
    localStorage.removeItem(this.keyI18next())
  }
}

export default LanguageConfig
