import User from './User'
import LanguageConfig from '../configs/Language'
import { SESSION_EXPIRED, CHANGE_TOKEN } from '../redux/actions/actionTypes'

const ApiKey = 'zQHJnLxKqAHoBiPQpEQav7UGYQjXfeCX'
export { ApiKey }

const user = new User()
const prefix = 'i18next_res_'
const language = new LanguageConfig(prefix)

/*
 * Class para lidar com as requests para API
 */
class ApiRequest {
  constructor(token, dispatch, metadata) {
    this.token = token
    this.dispatch = dispatch
    this.metadata = metadata
  }

  /**
   * Requisição POST
   * @param {String} endpoint = endpoint da API
   * @params {Object} params = parametros a serem enviados para API
   * @return {Promise} response
   */
  post(endpoint, params) {
    return fetch(endpoint, {
      method: 'POST',
      headers: this.headers(endpoint),
      body: JSON.stringify(params),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Requisição GET
   * @param {String} endpoint = endpoint da API
   * @return {Promise} response
   */
  get(endpoint, query) {
    const queryParams = this.createQueryParams(query)

    if (queryParams) {
      endpoint = `${endpoint}?${queryParams}`
    }

    return fetch(endpoint, {
      method: 'GET',
      headers: this.headers(endpoint),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Requisição DELETE
   * @param {String} endpoint = endpoint da API
   * @return {Promise} response
   */
  delete(endpoint, query) {
    const queryParams = this.createQueryParams(query)

    if (queryParams) {
      endpoint = `${endpoint}?${queryParams}`
    }

    return fetch(endpoint, {
      method: 'DELETE',
      headers: this.headers(endpoint),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  getFile(endpoint) {
    var dsToken = this.token
    if (user && user.currentUser && user.currentUser.dsToken) {
      dsToken = user.currentUser.dsToken
    }

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: dsToken,
        ApiKey,
        'Content-Language': language.userLanguage,
      },
    }).then((response) => response)
  }

  /**
   * Requisição PUT
   * @param {String} endpoint = endpoint da API
   * @params {Object} params = parametros a serem enviados para API
   * @return {Promise} response
   */
  put(endpoint, params) {
    return fetch(endpoint, {
      method: 'PUT',
      headers: this.headers(endpoint),
      body: JSON.stringify(params),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Configura headers para requesição
   * @param {string} url
   * @return {Object} headers
   */
  headers(url) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ApiKey,
      'Content-Language': language.userLanguage,
    }

    if (url.includes('/public')) {
      headers.client = 1
    }

    if (this.token) {
      headers.Authorization = this.token
    }

    if (this.metadata) {
      headers._offset = this.metadata.page
      headers._limit = this.metadata.size
    }

    return headers
  }

  /**
   * Verifica o status da resposta.
   * @return {Boolean}
   */
  checkStatus(response) {
    const status = response.cdStatus

    return status >= 200 && status < 299
  }

  /**
   * Set http status code in the body if necessary
   * @return {Promise} response
   */
  handleData = async (response) => {
    const responseData = await response.json()

    if (!responseData.cdStatus) {
      responseData.cdStatus = response.status
    }

    return responseData
  }

  /**
   * Tratamento de erros da requisição.
   * @return {Promise} response
   */
  handleResponse(response) {
    if (response.data || this.checkStatus(response)) {
      if (this.responseMetadata) {
        response.metadata = this.responseMetadata
      }

      return response
    }

    return Promise.reject(response)
  }

  updateToken(response) {
    if (response.dsToken || (response.data && response.data.dsToken)) {
      const dsToken = response.dsToken || response.data.dsToken
      var tokenExpirationTime = response.tokenExpirationTime || response.data.tokenExpirationTime
      if (user.currentUser) {
        const newUser = user.currentUser
        newUser.dsToken = dsToken
        if (tokenExpirationTime === undefined) {
          tokenExpirationTime = user.currentUser.tokenExpirationTime
        }
        newUser.tokenExpirationTime = tokenExpirationTime
        user.currentUser = newUser
      }

      if (this.dispatch && response.dsToken) {
        this.dispatch({
          type: CHANGE_TOKEN,
          payload: {
            dsToken: response.dsToken,
            tokenExpirationTime: response.tokenExpirationTime,
          },
        })
      }
    }

    return response
  }

  /**
   * Tratamento de sessão expirada.
   * @return {Promise} response
   */
  checkExpiredSession(response) {
    if (response.status === 401 || response.status === 403) {
      this.dispatch({ type: SESSION_EXPIRED })
    }

    return response
  }

  /*
   * Cria parametros de query
   * @param query {Object}
   * @return {String} query
   * ex.:
   * query = { name: 'username', age: '18' }
   * queryParams 'name=username&age=18'
   */
  createQueryParams(query) {
    if (!query || Object.keys(query).length < 1) {
      return
    }

    const esc = encodeURIComponent

    const queryParams = Object.keys(query)
      .map((k) => `${esc(k)}=${esc(query[k])}`)
      .join('&')

    return queryParams
  }

  handleMetadata(response) {
    const offset = response.headers.get('_offset')
    const limit = response.headers.get('_limit')
    const pages = response.headers.get('pages')
    const total = response.headers.get('total')

    if (offset && limit && pages && total) {
      this.responseMetadata = {
        pageCount: parseInt(pages),
        page: parseInt(offset),
        totalCount: parseInt(total),
        size: parseInt(limit),
      }
    }

    return response
  }
}

export default ApiRequest
