import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCustomerServiceOrders } from '../redux/actions/customer'
import store from '../redux/store'
import ServiceListItem from './ServiceListItem'
import Pagination from './pagination/Pagination'
import Col from './utils/Col'
import Row from './utils/Row'

function ServiceList({ t, history, services, pages, current, count }) {
  return (
    <div className='service-list'>
      <Row className='title'>
        <Col xs={12} sm={4} md={4}>
          <h5>{t('customer.service.order.list.title.service')}</h5>
        </Col>
        <Col xs={12} sm={3} md={3}>
          <h5>{t('customer.service.order.list.title.scheduling.date')}</h5>
        </Col>
        <Col xs={12} sm={5} md={5}>
          <h5>{t('customer.service.order.list.title.situation')}</h5>
        </Col>
      </Row>
      <hr />
      {(services &&
        !services.error &&
        services.map((service, idx) => <ServiceListItem key={idx} t={t} service={service} />)) ||
        t('customer.service.order.list.no.services.label')}

      {pages > 1 && (
        <div className='align-center margin-bottom-small'>
          <Pagination
            getPage={(page) =>
              store.dispatch(
                fetchCustomerServiceOrders(
                  history,
                  t('http.exception'),
                  localStorage.customerCpf,
                  localStorage.customerSmsCode,
                  page,
                ),
              )
            }
            pages={pages}
            current={current}
            count={count}
          />
        </div>
      )}
    </div>
  )
}
const mapStateToProps = ({ paginateReducer }) => ({
  pages: paginateReducer.pages,
  current: paginateReducer.current,
  count: paginateReducer.count,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCustomerServiceOrders }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList)
