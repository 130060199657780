import React from 'react'
import { withRouter } from 'react-router-dom'
import Col from './utils/Col'
import Row from './utils/Row'
import Button from './Button'
import situationList from '../variations/ProgressBar'

function NumberOnGreenCircle({ number }) {
  return (
    <div className='number-on-green-circle'>
      <span>{number}</span>
    </div>
  )
}

const handleOpenServiceDetails = (service, history) => {
  history &&
    history.push({
      pathname: '/service-details',
      search: `?${new URLSearchParams({
        c: service?.vaCustCdEncoded,
        o: service?.encodedServcOrdSeq,
      })}`,
    })
}

function ServiceListItem(props) {
  const {
    t,
    service: { servcOrdCd, serviceOrderItem, date, dsSlot, statusOs, servcCatgryTypCd },
    service,
    history,
  } = props
  let servcNm = ''
  ;(serviceOrderItem || []).forEach((item, idx) => {
    servcNm = servcNm.concat(`${(idx > 0 && ', ') || ''}${item.nomeProd}`)
  })

  return (
    <div className='item'>
      <Row>
        <Col xs={12} sm={6} md={4} className='align-vertical-center'>
          <span>
            <b>{servcOrdCd}</b> - {servcNm}
          </span>
        </Col>
        <Col xs={12} sm={6} md={3} className='align-vertical-center'>
          <span>{`${date} - ${t(dsSlot)}`}</span>
        </Col>
        <Col xs={12} sm={6} md={3} className='align-vertical-center'>
          <NumberOnGreenCircle number={statusOs} />
          <span>{t(situationList(parseInt(servcCatgryTypCd))[statusOs - 1])}</span>
        </Col>
        <Col xs={12} sm={6} md={2} className='align-vertical-center'>
          <Button
            label={t('customer.service.order.list.see.details.button')}
            fitContent
            onClick={() => handleOpenServiceDetails(service, history)}
          />
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(ServiceListItem)
