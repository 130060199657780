import { datadogRum } from '@datadog/browser-rum'
import i18next from 'i18next'
import React from 'react'

interface Props {
  error: { message: string }
  resetErrorBoundary: () => void
}

function ErrorFallback({ error, resetErrorBoundary }: Props) {
  React.useEffect(() => {
    if (import.meta.env.PROD) handleError()
  }, [error])

  const handleError = async () => {
    try {
      datadogRum.addError(error)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div role='alert'>
      <div>
        {i18next.t('fallback.page.error.title') as string}{' '}
        <span>
          <b>{i18next.t('fallback.page.error.subtitle') as string}</b>
        </span>
      </div>
      <pre>{error?.message}</pre>
      <button className='generic-cancel-button word-nowrap' onClick={resetErrorBoundary}>
        {i18next.t('fallback.page.text.button') as string}
      </button>
    </div>
  )
}

export default ErrorFallback
