import React from 'react'
import classNames from 'classnames'
import TelephoneInput from './PhoneInput'
import { getPhoneNumberCountry } from '../../utils/InternationalPhoneNumberUtils'

const userLanguage = navigator.language || navigator.userLanguage
const defaultCountry =
  {
    'fr-FR': 'FR',
    'en-US': 'US',
    'pt-PT': 'PT',
    'pl-PL': 'PL',
    'it-IT': 'IT',
  }[userLanguage] || 'BR'

function TelephoneInputField(props) {
  const {
    id,
    name,
    onChange,
    label,
    className,
    labelClassName = '',
    value,
    topLabel,
    valid,
    country = defaultCountry,
    ...remainingProps
  } = props

  const inputClass = classNames({ input: true }, className)

  const classLabelDisabled =
    className === 'inputDisabled' && remainingProps.disabled ? 'disable-label' : ''

  return (
    <div
      className={`telephone-input-field ${
        (valid === true && 'green') || (valid === false && 'red') || ''
      }`}
    >
      {topLabel && (
        <label htmlFor={id} className={`top-label ${classLabelDisabled}`}>
          {topLabel}
        </label>
      )}

      <TelephoneInput
        id={id}
        name={name}
        value={value}
        country={(value && getPhoneNumberCountry(value)) || country}
        defaultCountry={(value && getPhoneNumberCountry(value)) || country}
        className={inputClass}
        onChange={onChange}
        {...remainingProps}
      />

      {label && (
        <label htmlFor={id} className={`label ${labelClassName}`}>
          {label}
        </label>
      )}
    </div>
  )
}

export default TelephoneInputField
