import { useState, useEffect } from 'react'

import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'

interface ServiceHourly {
  serviceSeq: number
  servcOrdSeq: number
  serviceId: number
  sectionId: string
  sectionNm: string
  serviceCd: string
  serviceNm: string
  serviceQty: number
  serviceTime: number
  creatTs: Date
  lastUpdtTs: Date
  lastUpdtUserCd: string
}

function useHusbandRent(servcOrdSeq: number) {
  const [showSpinner, setShowSpinner] = useState(false)
  const [servicesHourly, setServicesHourly] = useState<ServiceHourly[]>([])

  async function fetchServicesHourly() {
    setShowSpinner(true)

    const URL = `${API_URL.FETCH_SERVICES_HOURLY}/${servcOrdSeq}`
    return new Api(null)
      .get(URL, {})
      .then((response) => {
        setServicesHourly(response.data)
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  useEffect(() => {
    fetchServicesHourly()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const totalTime = servicesHourly.reduce(
    (accumulator: number, service: ServiceHourly) => (accumulator += service.serviceTime),
    0,
  )

  return {
    servicesHourly,
    showSpinner,
    totalTime,
  }
}

export default useHusbandRent
