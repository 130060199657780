import moment, { Moment, isMoment } from 'moment-timezone'
import React from 'react'
import { SingleDatePicker } from 'react-dates'

import Calendar from '../../assets/icons/calendar-icon.svg?react'

export type anchorDirection = 'left' | 'right'

type DateSelectType = {
  id: string
  value: any
  label: string
  onChange: Function
  placeholder: string
  isDayBlocked?: Function
  isDayHighlighted?: Function
  highlightToday?: boolean
  isOutsideRange?: Function
  anchorDirection?: anchorDirection
  renderCalendarInfo?: any
  disabled?: boolean
  readonly?: boolean
  haveToValidate?: boolean
}

function DateSelect(props: DateSelectType) {
  const {
    id,
    value,
    label,
    onChange,
    placeholder,
    isDayBlocked,
    isDayHighlighted,
    highlightToday = true,
    isOutsideRange,
    anchorDirection = 'left',
    disabled,
    renderCalendarInfo,
    readonly,
    haveToValidate = true,
  } = props

  const [focused, setFocused] = React.useState<boolean>(false)
  const [invalid, setInvalid] = React.useState<boolean>(false)
  const [text, setText] = React.useState<string>('')

  const htmlInput: HTMLInputElement = document.querySelector(`#${id}`) as HTMLInputElement

  React.useEffect(() => {
    if (htmlInput && !readonly) {
      const newText = dateMask(text)
      const newMoment = moment(newText, 'DD/MM/YYYY', true)

      htmlInput.value = newText
      htmlInput.defaultValue = ''

      if (newMoment.isValid()) {
        if (!isInvalidValue(newMoment)) onChange(newMoment)
      }
    }
  }, [text])

  React.useEffect(() => {
    isInvalidValue(value)
  }, [value])

  React.useEffect(() => {
    if (!readonly) {
      if (htmlInput && !htmlInput.onkeyup && focused) {
        htmlInput.onkeyup = (e: any) => e.preventDefault()

        htmlInput.onkeydown = (e: any) => {
          if (e.key === 'Backspace') {
            e.preventDefault()
            return handleClear()
          }

          onChange(null)
          setInvalid(true)
          setText(e.target.value)
        }
      }

      if (htmlInput) {
        const newMoment = moment(htmlInput.value, 'DD/MM/YYYY', true)

        if (!newMoment.isValid()) {
          htmlInput.defaultValue = ''
          htmlInput.value = ''

          setInvalid(false)
        }
      }
    }
  }, [focused])

  const dateMask = (value: string) => {
    let v = value.replace(/\D/g, '').slice(0, 8)

    if (v.length >= 5) {
      v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
    } else if (v.length >= 3) {
      v = `${v.slice(0, 2)}/${v.slice(2)}`
    }

    return v
  }

  const handleClear = () => {
    setText('')
    onChange(null)
    setInvalid(false)
  }

  const handleDateChange = (e: any) => {
    if (isMoment(e)) return onChange(e)
    isInvalidValue(value)
  }

  const isInvalidValue = (newValue: Moment) => {
    if (haveToValidate) {
      const momentValue = htmlInput?.value ? !isMoment(newValue) : false

      const dayIsBlocked = isDayBlocked && isMoment(newValue) ? isDayBlocked(newValue) : momentValue
      const dayIsOutsideRange =
        isOutsideRange && isMoment(newValue) ? isOutsideRange(newValue) : momentValue

      setInvalid(dayIsBlocked || dayIsOutsideRange)
      return dayIsBlocked || dayIsOutsideRange
    }

    setInvalid(false)
    return false
  }

  const todayClass = highlightToday ? 'highlight-today' : ''
  const anchorClass = anchorDirection === 'right' ? 'anchor-right' : 'anchor-left'
  const textWhite = label === '-' ? 'text-white' : ''
  const invalidClass = invalid ? 'invalid-date' : ''
  const selectedClass = value && haveToValidate ? 'has-selected-date' : ''

  return (
    <div className={`date-select-advanced-search ${selectedClass} ${invalidClass} ${todayClass}`}>
      <label className={`date-select-label ${textWhite}`}>{label}</label>

      <div className={`date-select-body ${anchorClass}`} id={`${id}_body`}>
        <SingleDatePicker
          date={value}
          id={id}
          focused={focused}
          anchorDirection={anchorDirection}
          placeholder={placeholder}
          displayFormat='DD/MM/YYYY'
          numberOfMonths={1}
          onDateChange={(e: any) => handleDateChange(e)}
          onFocusChange={(e: any) => setFocused(e.focused)}
          /* @ts-ignore */
          isDayBlocked={isDayBlocked}
          /* @ts-ignore */
          isDayHighlighted={isDayHighlighted}
          /* @ts-ignore */
          isOutsideRange={isOutsideRange}
          renderCalendarInfo={renderCalendarInfo}
          transitionDuration={0}
          disabled={disabled}
          readOnly={readonly}
        />
        <Calendar className='date-select-icon' onClick={() => setFocused(true)} />
      </div>
    </div>
  )
}

export default DateSelect
