import React from 'react'
import PhoneInput from '@develcode/react-phone-number-input'
import { withTranslation } from 'react-i18next'
import br from '@develcode/react-phone-number-input/locale/pt-BR.json'
import fr from '@develcode/react-phone-number-input/locale/fr.json'
import en from '@develcode/react-phone-number-input/locale/en.json'
import pt from '@develcode/react-phone-number-input/locale/pt.json'
import pl from '@develcode/react-phone-number-input/locale/pl.json'
import it from '@develcode/react-phone-number-input/locale/it.json'

const userLanguage = navigator.language || navigator.userLanguage

const labels =
  {
    'fr-FR': fr,
    'en-US': en,
    'pt-PT': pt,
    'pl-PL': pl,
    'it-IT': it,
  }[userLanguage] || br

function TelephoneInput({ label, value, onChange, country, t }) {
  return (
    <div className='PhoneInputContainer'>
      <PhoneInput
        value={value}
        defaultCountry={country || t('country.code')}
        country={country || ''}
        label={label}
        limitMaxLength
        labels={labels}
        onChange={onChange}
        international
      />
    </div>
  )
}

const TelephoneInputTranslated = withTranslation()(TelephoneInput)

export default TelephoneInputTranslated
