import React from 'react'
import PropTypes from 'prop-types'
import PaginationLink from './Link'

function Pagination({ getPage, pages, current, count }) {
  if (!count) {
    return false
  }

  let initialNumber = current < 5 ? 1 : current - 4
  const lastNumber = pages < 8 + initialNumber ? pages : 8 + initialNumber
  const diff = initialNumber - lastNumber + 8
  initialNumber -= initialNumber > diff ? diff : 0

  const paginationNumbers = []

  for (let i = initialNumber; i <= lastNumber; i++) {
    const linkProps = {
      getPage,
      key: i,
      pageNumber: i,
      active: i === current,
    }

    paginationNumbers.push(<PaginationLink {...linkProps} />)
  }

  const prevButtonProps = {
    getPage,
    className: 'prev-button',
    pageNumber: 1,
    glyphType: 'double-arrow-left',
    disabled: current === 1,
  }

  const lastButtonProps = {
    getPage,
    className: 'next-button',
    pageNumber: pages,
    glyphType: 'double-arrow-right',
    disabled: current === pages,
  }

  return (
    <div className='row'>
      <div className='col-xs-12'>
        <nav className='pagination margin-top margin-bottom'>
          <PaginationLink {...prevButtonProps} />
          {paginationNumbers}
          <PaginationLink {...lastButtonProps} />
        </nav>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  getPage: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
}

export default Pagination
