import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'
import { fetchOsInfo } from './customer'
import { renderToastError } from '../../utils/Toast'

import {
  SAVE_EVALUATION_REQUEST,
  SAVE_EVALUATION_REQUEST_SUCCESS,
  SAVE_EVALUATION_REQUEST_ERROR,
} from './actionTypes'
import { startLoading, stopLoading } from './activityIndicator'

/*
 * SAVE EVALUATION
 */
export const saveEvaluation = (values, o, c) => (dispatch) => {
  dispatch(saveEvaluationRequest())
  const loadingAction = 'saveEval'

  const fields = {
    ...values,
  }

  dispatch(startLoading(loadingAction))
  return new Api()
    .post(API_URL.SAVE_EVALUATION, fields)
    .then((response) => {
      dispatch(saveEvaluationSuccess(response.data))
      dispatch(fetchOsInfo(o, c))
    })
    .catch((error) => {
      dispatch(saveEvaluationError(error))
      dispatch(fetchOsInfo(o, c))
      if (error?.message) {
        renderToastError(error.message.dsMessage)
      }
    })
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const saveEvaluationRequest = () => ({
  type: SAVE_EVALUATION_REQUEST,
})

export const saveEvaluationSuccess = (data) => ({
  type: SAVE_EVALUATION_REQUEST_SUCCESS,
  payload: data,
})

export const saveEvaluationError = (error) => ({
  type: SAVE_EVALUATION_REQUEST_ERROR,
  payload: error,
})
