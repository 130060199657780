import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import _ from 'lodash'
import moment from 'moment-timezone'

import { ChevronLeft } from '@leroy-merlin-br/backyard-icons'
import { Spinner as BackyardSpinner } from '@leroy-merlin-br/backyard-react'
import { useQuery } from 'react-query'
import { fetchFile, fetchInstallerPageData } from '../../services/installer'

import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import Spinner from '../../components/Spinner'

const APPROVED_PROFILE_PICTURE_STATUS = 3

function Installer(props) {
  const {
    location: { search },
    t,
    location,
    history,
  } = props

  // States
  const [showSpinner, setShowSpinner] = React.useState(false)
  const [providerPicture, setProviderPicture] = React.useState(false)

  const query = new URLSearchParams(search)
  const param = { servcPrvdrAgntId: query.get('id') }

  // Queries
  const { isLoading, data: response } = useQuery('installerDetails', () =>
    fetchInstallerPageData(param),
  )

  const { agent, provider, totalOrders, satisfaction } = response || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isLoading === true) setShowSpinner(true)
    else setShowSpinner(false)
  }, [isLoading])

  useEffect(() => {
    if (!isLoading && response && response?.provider) {
      if (response?.provider?.filePath) {
        fetchFile(response?.provider?.filePath)
          .then((response) => setProviderPicture(response?.data))
          .catch(() => setProviderPicture(''))
      } else {
        setProviderPicture('')
      }
    }
  }, [response, isLoading])

  function renderStar() {
    return (
      <svg viewBox='0 0 24 24'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g fill='currentColor' fillRule='nonzero'>
            <path d='M23.8789461,8.64867361 L23.8789461,8.64867361 C23.6030522,7.86897349 22.9313105,7.31718572 22.1036289,7.18523647 L16.4538019,6.34555942 L14.006743,1.24752019 C13.6468814,0.491810843 12.8551859,0 12.0035135,0 C11.1518411,0 10.3601456,0.491810843 10.000284,1.24752019 L7.54122975,6.35755481 L1.89140274,7.19723186 C1.06372108,7.31718572 0.391979439,7.88096888 0.116085551,8.660669 C-0.14781295,9.44036912 0.0441132324,10.2920416 0.631887167,10.8798155 L4.78229087,15.0182238 L3.82265996,20.7640139 C3.69071071,21.5797002 4.0505723,22.4193772 4.74630471,22.8991927 C5.43004174,23.3670128 6.35368649,23.4149943 7.08540506,23.0191466 L11.9915181,20.3801615 L16.9096265,23.0191466 C17.233502,23.1990773 17.605359,23.2830451 17.9772159,23.2830451 C18.4330406,23.2830451 18.8768699,23.1510958 19.2487269,22.8871973 C19.9444593,22.4073818 20.3043209,21.5677048 20.1603763,20.7520185 L19.2007454,15.0062284 L23.3511491,10.8678201 C23.9509184,10.2800462 24.1548399,9.44036912 23.8789461,8.64867361 Z' />
          </g>
        </g>
      </svg>
    )
  }

  function renderRating(rating, qty, type = 'installer') {
    const stars = []
    const empty = [1, 2, 3, 4, 5]

    for (let i = 1; i <= Math.floor(rating); i++) {
      stars.push(100)
    }

    if (rating - Math.floor(rating) > 0) {
      stars.push((rating - Math.floor(rating)) * 100)
    }

    return (
      <div className='rating'>
        <div className='stars'>
          {empty.map((v, i) => (
            <div className='star-p' key={i}>
              {renderStar()}
              {stars[i] ? (
                <div className='fill' style={{ width: `${stars[i]}%` }}>
                  {renderStar()}
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
        <div className='rating-infos'>
          <p>
            <b>{rating?.toString().replace('.', ',')}</b> {t(`installerPage.${type}.points`)}
          </p>
          <p>
            <b>{qty}</b> {t(`installerPage.${type}.services`)}
          </p>
        </div>
      </div>
    )
  }

  function renderComments() {
    return !_.isEmpty(satisfaction?.ratingOptions) || !_.isEmpty(satisfaction?.ratingComments) ? (
      <div className='comments'>
        {!_.isEmpty(satisfaction?.ratingOptions) && (
          <>
            <h3>{t('installerPage.installer.options')}</h3>
            <div className='box-itens'>
              {satisfaction?.ratingOptions.map((option, i) => (
                <span key={i}>
                  <i>{option.total}</i>
                  <p>{option.option}</p>
                </span>
              ))}
            </div>
          </>
        )}
        {!_.isEmpty(satisfaction?.ratingComments) && (
          <>
            <h4>{t('installerPage.installer.comments')}</h4>
            {satisfaction?.ratingComments.map((comment, i) => {
              const service = comment.servcGrpNm.split(' - ')
              const date = moment(comment.dtComment)
              return (
                <div className='comment' key={i}>
                  <p>"{comment.dsComment}"</p>
                  <span>
                    {t('installerPage.installer.comments.service')} {service[service.length - 1]},{' '}
                    {date.format('L')}
                  </span>
                </div>
              )
            })}
          </>
        )}
      </div>
    ) : (
      ''
    )
  }

  function renderServices() {
    return !_.isEmpty(provider?.grpList) ? (
      <div className='comments no-margin'>
        <p>{t('installerPage.company.groups')}</p>
        <div className='box-itens margin-top'>
          {provider?.grpList.map((group, i) => (
            <span key={i}>{group.servcGrpNm}</span>
          ))}
        </div>
      </div>
    ) : (
      ''
    )
  }

  const osDetails =
    typeof location.state !== 'undefined' && typeof location.state.osDetails !== 'undefined'
      ? location.state.osDetails
      : null

  const installerDate = moment(agent?.creatTs || undefined)
  const companyDate = moment(provider?.servcPrvdrCreatDt || undefined)

  if (showSpinner)
    return (
      <>
        {' '}
        <Spinner visible={showSpinner} />{' '}
      </>
    )

  return (
    <>
      <HeaderV2 />
      {response && (
        <div className='client-page-body installer-page'>
          {osDetails && (
            <div className='installer-page-go-back' onClick={() => history.goBack()}>
              <ChevronLeft className='installer-page-back-icon' />
              <span>{t('installerPage.back')}</span>
            </div>
          )}
          <div className='installer-part border-b'>
            <div className='left'>
              <div className='data-container'>
                <div className='image'>
                  <img
                    alt=''
                    src={
                      agent?.profilePicture &&
                      agent?.pictureStatus === APPROVED_PROFILE_PICTURE_STATUS
                        ? `data:image/png;base64,${agent?.profilePicture}`
                        : '/assets/installer-placeholder.png'
                    }
                  />
                </div>
                <div className='data'>
                  <div className='job'>{t('installerPage.installer.title')}</div>
                  <h2 className='name'>{agent?.servcPrvdrAgntNm}</h2>
                  <p>
                    {t('installerPage.installer.partner.m')}{' '}
                    <b>{installerDate.format('MMMM/YYYY')}</b>
                  </p>
                  {renderRating(agent?.sysAcsUserAvgGrade, totalOrders?.agent)}
                  <hr />
                  {renderComments()}
                </div>
              </div>
            </div>
            {/* For share box in future */}
            <div className='right' />
          </div>

          <div className='installer-part company-part'>
            <div className='left full'>
              <div className='data-container'>
                <div className='image'>
                  {providerPicture === false ? (
                    <BackyardSpinner size='giga' />
                  ) : (
                    <img
                      alt=''
                      src={
                        providerPicture
                          ? `data:image/png;base64, ${providerPicture}`
                          : '/assets/company-placeholder.png'
                      }
                    />
                  )}
                </div>
                <div className='data'>
                  <div className='job'>{t('installerPage.company.title')}</div>
                  <h2 className='name'>
                    {provider?.servcPrvdrTrdNm ? provider?.servcPrvdrTrdNm : provider?.servcPrvdrNm}
                  </h2>
                  <p>
                    {t('installerPage.company.partner')} <b>{companyDate.format('MMMM/YYYY')}</b>
                  </p>
                  {renderRating(provider?.servcPrvdrAvgGrade, totalOrders?.provider, 'company')}
                  {renderServices()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <FooterV2 />
    </>
  )
}

const InstallerTranslated = withTranslation()(Installer)

export default InstallerTranslated
