import {
  API_INSTALA_SATSFTN,
  API_INSTALA,
  API_INSTALA_PROVIDER,
  API_INSTALA_I18N,
  API_INSTALA_PARAM,
  API_INSTALA_CORE_ELASTIC,
  API_INSTALA_SERVICES_GROUPS,
} from './envs'

const endpoints = {
  SETUP_PARAMETERS: `${API_INSTALA_PARAM}/public/find-setup-parameter`,
  FETCH_SERVC_ORDER_RECEIPT: `${API_INSTALA}/public/order/find-nfc`,

  FIND_CUSTOMER_PUBLIC_STORES: `${API_INSTALA}/public/branch/list-customer-servc-request`,

  SAVE_CONFIRMATION: `${API_INSTALA}/public/order/save`,
  SAVE_EVALUATION: `${API_INSTALA}/public/order/saveEvaluation`,

  CONFORMITY: `${API_INSTALA}/public/order/conformity`,

  PERFORMANCE_RATINGS_FIND_BY_STARS: `${API_INSTALA_SATSFTN}/public/ratings/findByStars`,

  PUBLIC_SERVICE_PROVIDER_POSTAL_CODE: `${API_INSTALA_PROVIDER}/public/servcPrvdr/postalCode`,

  LOCALES_LIST: `${API_INSTALA_I18N}/public/locales/list`,

  FIND_UNNACOMPLISHED_REASONS: `${API_INSTALA}/public/list-unnacomplished-reasons`,
  FETCH_CUSTOMER_OS_ENCODED: `${API_INSTALA}/public/order/find-client-order`,
  CUSTOMER_LOGIN_CPF: `${API_INSTALA}/public/order/send-client-password`,
  CUSTOMER_LOGIN_SMS_CODE: `${API_INSTALA}/public/order/validate-client-password`,
  CUSTOMER_FETCH_SERVICE_ORDERS: `${API_INSTALA}/public/order/get-client-orders`,
  CUSTOMER_EDIT_ADDRESS: `${API_INSTALA}/public/order/set-client-address-order`,
  CUSTOMER_EDIT_PHONES: `${API_INSTALA}/public/order/set-client-phone-order`,
  CUSTOMER_EDIT_ADDRESS_PHONE: `${API_INSTALA}/public/order/set-client-address-and-phone-order`,
  CUSTOMER_RESCHEDULE: `${API_INSTALA}/public/order/set-client-reschedule-order`,
  CUSTOMER_SEND_MESSAGE: `${API_INSTALA}/public/order/send-client-message`,

  CLIENT_SERVICE_RESCHEDULE: `${API_INSTALA}/public/order/reschedule`,

  CUSTOMER_FETCH_IMG: `${API_INSTALA}/public/order/file`,
  CUSTOMER_REQUEST_SERVICE: `${API_INSTALA}/public/service-request`,

  TEMPLATES_PUBLIC_FETCH: `${API_INSTALA_PARAM}/public/find-with-filter`,

  INSTALLER_FETCH: `${API_INSTALA_PROVIDER}/public/agnt-integration/installer-page`,
  INSTALLER_PAGE_INSTALLER_INFOS: `${API_INSTALA_PROVIDER}/public/agnt-integration/installer-pageV2`,
  INSTALLER_PAGE_PROVIDER_INFOS: `${API_INSTALA_PROVIDER}/public/servcPrvdr/infos`,
  INSTALLER_PAGE_TOTAL_ORDERS: `${API_INSTALA}/public/order/total-orders-by-agent`,
  INSTALLER_PAGE_SATISTACTION: `${API_INSTALA_CORE_ELASTIC}/public/servc-prvdr-agnt/satsftn`,

  FETCH_FILE: `${API_INSTALA}/public/order/file`,

  RESCHEDULING_CONFIRM: `${API_INSTALA}/public/order/customer-reschedule-confirm`,
  RESCHEDULING_REFUSE: `${API_INSTALA}/public/order/customer-reschedule-refuse`,
  GET_LAST_OCCURRENCE: `${API_INSTALA}/public/order/get-last-occurrence`,

  // Included And Excluded
  FETCH_INCLUDED_EXCLUDED_CLIENT: `${API_INSTALA_SERVICES_GROUPS}/public/v1/services-group/{servcGrpCd}`,

  // Package Services
  FETCH_PACKAGES_SERVICES_ORDER: `${API_INSTALA}/public/servcGrpCatgry/by-order`,
  SAVE_PACKAGES_SERVICES_ORDER: `${API_INSTALA}/public/serviceOrderGroupCategory`,
  FETCH_SERVICES_CONDITION_SERVICE_ORDER: `${API_INSTALA}/public/servcGrpCatgry/listAll`,

  // Services Hourly
  FETCH_SERVICES_HOURLY: `${API_INSTALA}/public/order/services-hourly`,

  FETCH_SERVICE_GROUP: `${API_INSTALA_SERVICES_GROUPS}/public/v1/services-group`,
}

export default endpoints
