export const parseQuery = (parsed) => {
  const params = new URLSearchParams(window.location.search)

  return parsed === true // needed, will be refactored in the next technical sprint :)
    ? {
        servcOrdSeqEncoded: params.get('o'),
        vaCustCDEncoded: params.get('c'),
        vaCustCdEncoded: params.get('c'),
      }
    : {
        o: params.get('o'),
        c: params.get('c'),
      }
}
