class ServiceOrderStatusType {
  static PRE_AGENDADA = new ServiceOrderStatusType('00', 'serviceOrders.preScheduled', [
    '00',
    '01',
    '09',
  ])

  static AGENDADA = new ServiceOrderStatusType('01', 'serviceOrders.scheduled', ['01', '02', '09'])

  static DISTRIBUIDA = new ServiceOrderStatusType('02', 'serviceOrders.distributed', [
    '02',
    '03',
    '00',
    '09',
  ])

  static ASSOCIADA = new ServiceOrderStatusType('03', 'serviceOrders.associated', [
    '03',
    '04',
    '00',
    '09',
  ])

  static CONFIRMADA = new ServiceOrderStatusType('04', 'serviceOrders.confirmed', [
    '04',
    '05',
    '00',
    '09',
  ])

  static EM_REALIZACAO = new ServiceOrderStatusType('05', 'serviceOrders.doing', [
    '05',
    '06',
    '07',
    '09',
  ])

  static REALIZADO_COM_SUCESSO = new ServiceOrderStatusType('06', 'serviceOrders.done', [
    '01',
    '06',
    '08',
    '09',
    '10',
  ])

  static REALIZADO_PARCIALMENTE = new ServiceOrderStatusType('07', 'serviceOrders.partiallyDone', [
    '07',
    '08',
    '09',
    '01',
  ])

  static ENCERRADO = new ServiceOrderStatusType('08', 'serviceOrders.closed', ['08', '09'])

  static CANCELADA = new ServiceOrderStatusType('09', 'serviceOrders.canceled', ['09'])

  static NAO_CONFORME = new ServiceOrderStatusType('10', 'serviceOrders.notAccordingly', ['10'])

  static LOJA_AGUARDANDO = new ServiceOrderStatusType('11', 'serviceOrders.branch.waiting', [
    '11',
    '12',
    '09',
  ])

  static LOJA_EM_REALIZACAO = new ServiceOrderStatusType('12', 'serviceOrders.branch.doing', [
    '12',
    '13',
    '09',
  ])

  static LOJA_REALIZADO = new ServiceOrderStatusType('13', 'serviceOrders.branch.done', [
    '13',
    '14',
    '09',
  ])

  static LOJA_ENCERRADO = new ServiceOrderStatusType('14', 'serviceOrders.branch.closed', [
    '14',
    '09',
  ])

  static PROVIDER_CLOSED = new ServiceOrderStatusType('24', 'serviceOrders.provider.closed', [
    '24',
    '08',
  ])

  static OUT_OF_FLOW = new ServiceOrderStatusType('15', 'serviceOrders.outofflow', ['15'])

  static TODOS = new ServiceOrderStatusType('99', 'serviceOrders.all', [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
  ])

  static SATISFACTION_SURVEY_EDITION = new ServiceOrderStatusType(
    ['06', '13', '22'],
    'serviceOrders.satisfaction.survey.edition',
    ['06', '13', '22'],
  )

  id = ''

  value = ''

  futureStatuses = []

  constructor(id, value, futureStatuses) {
    this.id = id || ''
    this.value = value || ''
    this.futureStatuses = futureStatuses || []
  }

  static getStatusWithId(id) {
    switch (id) {
      case ServiceOrderStatusType.PRE_AGENDADA.id:
        return ServiceOrderStatusType.PRE_AGENDADA

      case ServiceOrderStatusType.AGENDADA.id:
        return ServiceOrderStatusType.AGENDADA

      case ServiceOrderStatusType.DISTRIBUIDA.id:
        return ServiceOrderStatusType.DISTRIBUIDA

      case ServiceOrderStatusType.ASSOCIADA.id:
        return ServiceOrderStatusType.ASSOCIADA

      case ServiceOrderStatusType.CONFIRMADA.id:
        return ServiceOrderStatusType.CONFIRMADA

      case ServiceOrderStatusType.EM_REALIZACAO.id:
        return ServiceOrderStatusType.EM_REALIZACAO

      case ServiceOrderStatusType.REALIZADO_COM_SUCESSO.id:
        return ServiceOrderStatusType.REALIZADO_COM_SUCESSO

      case ServiceOrderStatusType.REALIZADO_PARCIALMENTE.id:
        return ServiceOrderStatusType.REALIZADO_PARCIALMENTE

      case ServiceOrderStatusType.ENCERRADO.id:
        return ServiceOrderStatusType.ENCERRADO

      case ServiceOrderStatusType.CANCELADA.id:
        return ServiceOrderStatusType.CANCELADA

      case ServiceOrderStatusType.NAO_CONFORME.id:
        return ServiceOrderStatusType.NAO_CONFORME

      case ServiceOrderStatusType.LOJA_AGUARDANDO.id:
        return ServiceOrderStatusType.LOJA_AGUARDANDO

      case ServiceOrderStatusType.LOJA_EM_REALIZACAO.id:
        return ServiceOrderStatusType.LOJA_EM_REALIZACAO

      case ServiceOrderStatusType.LOJA_REALIZADO.id:
        return ServiceOrderStatusType.LOJA_REALIZADO

      case ServiceOrderStatusType.LOJA_ENCERRADO.id:
        return ServiceOrderStatusType.LOJA_ENCERRADO

      case ServiceOrderStatusType.PROVIDER_CLOSED.id:
        return ServiceOrderStatusType.PROVIDER_CLOSED

      case ServiceOrderStatusType.OUT_OF_FLOW.id:
        return ServiceOrderStatusType.OUT_OF_FLOW

      case ServiceOrderStatusType.SATISFACTION_SURVEY_EDITION.id:
        return ServiceOrderStatusType.SATISFACTION_SURVEY_EDITION

      case ServiceOrderStatusType.TODOS.id:
        return ServiceOrderStatusType.TODOS

      default:
        return new ServiceOrderStatusType()
    }
  }

  static getStatusWithValue(value) {
    switch (value) {
      case ServiceOrderStatusType.PRE_AGENDADA.value:
        return ServiceOrderStatusType.PRE_AGENDADA

      case ServiceOrderStatusType.AGENDADA.value:
        return ServiceOrderStatusType.AGENDADA

      case ServiceOrderStatusType.DISTRIBUIDA.value:
        return ServiceOrderStatusType.DISTRIBUIDA

      case ServiceOrderStatusType.ASSOCIADA.value:
        return ServiceOrderStatusType.ASSOCIADA

      case ServiceOrderStatusType.CONFIRMADA.value:
        return ServiceOrderStatusType.CONFIRMADA

      case ServiceOrderStatusType.EM_REALIZACAO.value:
        return ServiceOrderStatusType.EM_REALIZACAO

      case ServiceOrderStatusType.REALIZADO_COM_SUCESSO.value:
        return ServiceOrderStatusType.REALIZADO_COM_SUCESSO

      case ServiceOrderStatusType.REALIZADO_PARCIALMENTE.value:
        return ServiceOrderStatusType.REALIZADO_PARCIALMENTE

      case ServiceOrderStatusType.ENCERRADO.value:
        return ServiceOrderStatusType.ENCERRADO

      case ServiceOrderStatusType.CANCELADA.value:
        return ServiceOrderStatusType.CANCELADA

      case ServiceOrderStatusType.NAO_CONFORME.value:
        return ServiceOrderStatusType.NAO_CONFORME

      case ServiceOrderStatusType.LOJA_AGUARDANDO.value:
        return ServiceOrderStatusType.LOJA_AGUARDANDO

      case ServiceOrderStatusType.LOJA_EM_REALIZACAO.value:
        return ServiceOrderStatusType.LOJA_EM_REALIZACAO

      case ServiceOrderStatusType.LOJA_REALIZADO.value:
        return ServiceOrderStatusType.LOJA_REALIZADO

      case ServiceOrderStatusType.LOJA_ENCERRADO.value:
        return ServiceOrderStatusType.LOJA_ENCERRADO

      case ServiceOrderStatusType.PROVIDER_CLOSED.value:
        return ServiceOrderStatusType.PROVIDER_CLOSED

      case ServiceOrderStatusType.OUT_OF_FLOW.value:
        return ServiceOrderStatusType.OUT_OF_FLOW

      case ServiceOrderStatusType.SATISFACTION_SURVEY_EDITION.value:
        return ServiceOrderStatusType.SATISFACTION_SURVEY_EDITION

      case ServiceOrderStatusType.TODOS.value:
        return ServiceOrderStatusType.TODOS

      default:
        return new ServiceOrderStatusType()
    }
  }

  static getFutureStatus(id) {
    const status = this.getStatusWithId(id)
    return status.futureStatuses
  }

  hasFutureStatus(serviceOrderStatusType) {
    return this.futureStatuses.indexOf(serviceOrderStatusType.id) > -1
  }
}

export default ServiceOrderStatusType
