import { Text } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import Button from '../../../../../../components/Button'
import QuantityInput from '../../../../../../components/utils/QuantityInput'

import successIcon from '../../../../../../images/icons/success.svg'

function Packages(props) {
  const {
    handleSavePackagesOnServiceOrder,
    handleChangeQuantity,
    subservice: { amount, subserviceTxt, subservicesDTO },
    index,
  } = props
  const [showAlertText, setAlertText] = React.useState(false)

  const quantityOfSubservicesFinished = (subservicesDTO || []).reduce(
    (accumulator, value) => accumulator + (value.sbgrpQty || 0),
    0,
  )

  const disableSelectQuantities = quantityOfSubservicesFinished > amount

  const disableMore =
    quantityOfSubservicesFinished > amount || quantityOfSubservicesFinished === amount

  const showSuccessIcon = quantityOfSubservicesFinished === amount

  const showAlertHelper = showAlertText && quantityOfSubservicesFinished === 0

  function handleSubmitPackages() {
    if (quantityOfSubservicesFinished === 0) {
      setAlertText(true)
      return
    }

    setAlertText(false)
    handleSavePackagesOnServiceOrder()
  }

  if (subservicesDTO.length === 0) return <></>

  return (
    <section id='package-services-list'>
      <section>
        <table>
          <tbody>
            {(subservicesDTO || []).map((subservice, subServiceIndex) => (
              <tr key={subServiceIndex}>
                <td className='td-subservice-name'>
                  <Text className='subservice-name'>{subservice.sbgrpNm || ''}</Text>
                </td>
                <td>
                  <QuantityInput
                    min={0}
                    max={amount || 0}
                    name={subServiceIndex || 0}
                    disableMore={disableMore}
                    disabled={disableSelectQuantities}
                    value={subservicesDTO[subServiceIndex].sbgrpQty || 0}
                    onChange={(event) => handleChangeQuantity(event, index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section className='content-counter'>
        <Text className='counter'>
          {i18next
            .t('serviceOrders.package.subservice.counter.text')
            .replace('{0}', quantityOfSubservicesFinished)
            .replace('{1}', amount || 0)}
        </Text>

        {showSuccessIcon ? (
          <img src={successIcon} alt='success icon' className='success-icon' />
        ) : null}
      </section>

      <section className='content-save'>
        <Button
          fitContent
          onClick={() => handleSubmitPackages()}
          className='button-fit-content'
          variant='white'
          label={i18next.t('serviceOrders.confirm')}
        />

        {showAlertHelper ? (
          <span className='alert-message'>
            {i18next.t('client.page.package.services.alert.message')}
          </span>
        ) : null}
      </section>

      <p className='important-label-helper'>{i18next.t('important.label.text')}:</p>
      <Text className='subservice-text'>{ReactHtmlParser(subserviceTxt || '')}</Text>
    </section>
  )
}

export default Packages
