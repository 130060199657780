import moment from 'moment-timezone'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import Body from '../../components/Body'
import Button from '../../components/Button'
import DatePicker from '../../components/DateSelect'
import Col from '../../components/utils/Col'
import Row from '../../components/utils/Row'
import {
  fetchOsInfo,
  fetchServiceGroupInfo,
  reschedule,
  rescheduleNeedHelp,
} from '../../redux/actions/customer'
import { getFluxLabel } from '../../utils/ServiceOrderFlux'
import { colourStyles } from '../../utils/selectStyle'
import { parseQuery } from '../../utils/serviceOrderUtils'

import GrayDot from '../../assets/icons/gray-dot.svg?react'
import GreenDot from '../../assets/icons/green-dot.svg?react'
import WhiteDot from '../../assets/icons/white-dot.svg?react'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'

const selectOptions = [
  { value: 'M', label: 'Manhã' },
  { value: 'A', label: 'Tarde' },
]

class Reschedule extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newDate: moment(),
      newShift: null,
      formData: {
        servcOrdSchdlgTs: '',
        servcOrdSchdlgShiftCd: '',
      },
      orderDate: null,
    }
  }

  async componentDidMount() {
    await this.loadOsDetail()

    window.scrollTo(0, 0)
  }

  async componentDidUpdate() {
    const { orderDetails } = this.props
    const { newShift } = this.state

    if (orderDetails && !newShift) {
      const idSlotApplication = orderDetails?.idSlotApplication
      const date = orderDetails?.date

      idSlotApplication &&
        date &&
        this.setState({
          newShift: selectOptions.filter(({ value }) => value === idSlotApplication),
          newDate: moment(date, 'DD/MM/YYYY'),
          formData: {
            servcOrdSchdlgShiftCd: idSlotApplication,
            servcOrdSchdlgTs: moment(date, 'DD/MM/YYYY').toISOString(),
          },
          orderDate: moment(date, 'DD/MM/YYYY').toISOString(),
        })
    }
  }

  loadOsDetail = () => {
    const { o, c } = parseQuery()
    return this.props.fetchOsInfo(o, c)
  }

  handleSubmit = () => {
    const { formData } = this.state
    const {
      reschedule,
      t,
      location: { search },
      history,
      orderDetails,
    } = this.props

    reschedule(formData, orderDetails, search, history, t('customer.service.order.edit.save.fail'))
  }

  isOutsideRangeDate(day) {
    const {
      params: {
        apiDaysServiceOrdersSchedule: plusDays,
        reschduleByDeliveryDate: rescheduleByDeliveryDate,
      },
      orderDetails,
    } = this.props

    const deliveryDt = orderDetails?.servcOrdPrmsdDlvryDt
    const scheduleDt = orderDetails?.date

    const today = moment().startOf('day')
    const deliveryDate = deliveryDt ? moment(deliveryDt) : today
    const scheduleDate = moment(scheduleDt, 'DD/MM/YYYY')

    const refDate = rescheduleByDeliveryDate ? deliveryDate : scheduleDate
    const finalDate = today.isAfter(refDate) ? today : refDate

    const rescheduleOffset = plusDays && parseInt(plusDays)

    return finalDate.add(rescheduleOffset, 'days').isAfter(day)
  }

  isDayBlocked = (day) => {
    const {
      params: {
        reschedulingAvailableShift: availableShift,
        reschduleByDeliveryDate: rescheduleByDeliveryDate,
      },
      orderDetails,
    } = this.props

    const deliveryDt = orderDetails?.servcOrdPrmsdDlvryDt
    const scheduleDt = orderDetails?.date

    const today = moment().startOf('day')
    const deliveryDate = deliveryDt ? moment(deliveryDt) : today
    const scheduleDate = moment(scheduleDt, 'DD/MM/YYYY')

    const refDate = rescheduleByDeliveryDate ? deliveryDate : scheduleDate

    const weekDay = String(parseInt(day.format('d')) + 1)
    const sixMonthsLater = refDate.add(6, 'months').startOf('day')

    const isDateAvailable = day.diff(sixMonthsLater, 'days') > 0
    const inAvailableShift = availableShift && availableShift.indexOf(weekDay) === -1

    return inAvailableShift || isDateAvailable
  }

  renderCalendarInfo = () => {
    const { t } = this.props

    return (
      <div className='calendar-footer'>
        <div>
          <GreenDot /> {t('serviceOrders.schedulingDate')}
        </div>
        <div>
          <GrayDot /> {t('schedule.blocked.type')}
        </div>
        <div>
          <WhiteDot /> {t('menu.free')}
        </div>
      </div>
    )
  }

  render() {
    const { newDate, newShift, formData } = this.state
    const {
      t,
      history,
      orderDetails,
      location: { search },
    } = this.props

    const idSlotApplication = orderDetails?.idSlotApplication
    const date = orderDetails?.date

    const disablePeriodSelect = this.isOutsideRangeDate(newDate) || this.isDayBlocked(newDate)

    const flux = parseInt(orderDetails?.servcCatgryTypCd)

    const handleOnClickHeaderNeedHelp = () => {
      history.push({
        pathname: '/service-details/need-help',
        search,
        state: {
          osDetails: orderDetails,
        },
      })
    }

    return (
      <>
        <HeaderV2 onClickNeedHelp={handleOnClickHeaderNeedHelp} />
        <Body>
          <div className='data-edit-form'>
            <h4>{getFluxLabel('customer.service.order.reschedule.page.title', flux)}</h4>
            <h5>{getFluxLabel('customer.service.order.choose.new.date', flux)}</h5>
            <Row className='margin-bottom-row relative'>
              <Col xs={12} sm={12} md={3} className='notranslate'>
                <label className='top-label'>{t('customer.service.order.reschedule.date')}</label>
                <DatePicker
                  id='reschedule-date-picker'
                  value={newDate}
                  date={newDate}
                  onChange={(newDate) => {
                    if (!newDate) return false
                    this.setState({
                      newDate,
                      formData: {
                        ...formData,
                        servcOrdSchdlgTs: newDate.toISOString(),
                      },
                    })
                  }}
                  isDayBlocked={(dt) => this.isDayBlocked(dt) || this.isOutsideRangeDate(dt)}
                  highlightToday={false}
                  haveToValidate={false}
                  renderCalendarInfo={this.renderCalendarInfo}
                  readonly
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={3}>
                <label className='top-label'>{t('customer.service.order.reschedule.shift')}</label>
                <Select
                  options={selectOptions}
                  value={newShift}
                  classNamePrefix='react-select'
                  styles={colourStyles}
                  isDisabled={disablePeriodSelect}
                  onChange={(opt) =>
                    this.setState({
                      newShift: opt,
                      formData: {
                        ...formData,
                        servcOrdSchdlgShiftCd: opt.value,
                      },
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={3}>
                <Button
                  onClick={() => history.goBack()}
                  label={t('customer.service.order.reschedule.cancel')}
                  variant='white'
                  fill
                />
              </Col>
              <Col xs={6} sm={6} md={3}>
                <Button
                  onClick={() => this.handleSubmit()}
                  label={t('customer.service.order.reschedule.save')}
                  fill
                  disabled={
                    !(
                      idSlotApplication !== formData.servcOrdSchdlgShiftCd ||
                      date !== newDate.format('L')
                    )
                  }
                />
              </Col>
            </Row>
          </div>
        </Body>
        <FooterV2 />
      </>
    )
  }
}

const RescheduleTranslated = withTranslation()(Reschedule)

const mapStateToProps = (state) => ({
  params: state.setupParameters,
  orderDetails: state.customerReducer.osDetails,
  serviceGroup: state.customerReducer.serviceGroup,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { reschedule, fetchOsInfo, fetchServiceGroupInfo, rescheduleNeedHelp },
    dispatch,
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RescheduleTranslated))
