import moment from 'moment-timezone'
import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Body from '../../components/Body'
import DynamicContentBody from '../../components/DynamicContentBody'
import Footer from '../../components/ClientFooter'
import Header from '../../components/GenericHeader'
import { fluxes, schdlgCds } from '../../constants/constants'
import { clientRescheduleRequest } from '../../redux/actions/confirmation'
import { fetchOsInfo, fetchReceipt } from '../../redux/actions/customer'
import { saveEvaluation } from '../../redux/actions/evaluation'
import ReplaceTextKeys from '../../utils/ReplaceTextKeys'
import ServcOrdCategoryType from '../../utils/ServcOrdCategoryType'
import { getFluxLabel } from '../../utils/ServiceOrderFlux'
import ServiceOrderStatusType from '../../utils/ServiceOrderStatusType'
import { renderToastSuccess } from '../../utils/Toast'
import { base64toBlob } from '../../utils/Toolbox'
import { parseQuery } from '../../utils/serviceOrderUtils'
import bodyModifiers from '../../variations/Body'
import situationList from '../../variations/ProgressBar'

class ServiceDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      titleModifier: '',
    }
  }

  get specialSubtitle() {
    const { osDetails } = this.props
    const flux = parseInt(osDetails?.servcCatgryTypCd)

    if (osDetails?.showRescheduleOption || osDetails?.rescheduledByClient) {
      switch (flux) {
        case fluxes.installation:
        case fluxes.packageService:
        case fluxes.storeService:
          return 'continuation.service.flag'
        case fluxes.technicalVisit:
        case fluxes.plannedFurnitureTechnicalVisit:
        case fluxes.highPotentialVisit:
          return 'continuation.visit.flag'
        default:
          return 'continuation.service.flag'
      }
    }
    return null
  }

  componentDidMount() {
    const {
      t,
      location: { state },
    } = this.props

    const { success } = state || {}

    if (success === 'address-phone') {
      renderToastSuccess(t('customer.service.order.edit.success.address.phone'))
      window.history.replaceState({ ...state, success: '' }, '')
    }

    if (success === 'reschedule') {
      renderToastSuccess(t('customer.service.order.reschedule.requested'))
      window.history.replaceState({ ...state, success: '' }, '')
    }

    this.handleFetchOSInfo()

    window.scrollTo(0, 0)
  }

  handleFetchOSInfo = () => {
    const {
      fetchOsInfo,
      fetchReceipt,
      location: { state },
    } = this.props
    const { success } = state || {}

    const parsedQuery = parseQuery()

    if (success === 'reschedule') {
      this.setState({
        titleModifier: 'reschedule.request',
      })
    }

    fetchReceipt(parsedQuery.o || state?.osDetails?.encodedServcOrdSeq)
    fetchOsInfo(parsedQuery.o, parsedQuery.c).then(this.handleFetchOcurrences)
    this.saveLocalStorage()
  }

  saveLocalStorage = () => {
    const {
      params: { nifMandatory },
    } = this.props

    const osDetails = this.props
    if (
      osDetails.osDetails &&
      (!localStorage.customerSmsCode || !localStorage.customerCpf !== osDetails.osDetails.vaCustCd)
    ) {
      localStorage.setItem('customerSmsCode', osDetails.osDetails.custPassword)
      if (nifMandatory) {
        localStorage.setItem('customerCpf', osDetails.osDetails.vaCustCd)
      } else {
        localStorage.setItem('customerCpf', osDetails.osDetails.custMobilePhonNr)
      }
    }
  }

  handleFetchOcurrences = () => {
    const { osDetails } = this.props

    if (osDetails?.showRescheduleOption) {
      this.setState({
        titleModifier: 'continuation',
      })
    }

    if (osDetails?.rescheduledByClient)
      this.setState({
        titleModifier: 'continuation.cancelled',
      })
  }

  handleCheckOccurences = () => {
    const { occurrences, osDetails } = this.props
    const lastOccurrence = occurrences?.[osDetails.servcOrdSeq]?.servcOrdEvntStusInd

    const modifier = {
      72: 'reschedule.request',
      73: 'reschedule.accept',
      74: 'reschedule.accept',
      75: 'reschedule.deny',
    }[lastOccurrence]

    if (modifier) this.setTitleModifier(modifier)
  }

  handleEvalSubmit = (values) => {
    const {
      saveEvaluation,
      location: { search },
      osDetails,
    } = this.props

    const query = parseQuery(search)

    const params = {
      ...values,
      servcOrdSeq: osDetails.servcOrdSeq,
    }

    return saveEvaluation(params, query.o, query.c)
  }

  downloadNf = ({ servcOrdItemAtchmtDesc, preview }) => {
    const link = document.createElement('a')
    const data = base64toBlob(preview, 'application/pdf')
    link.href = URL.createObjectURL(data)
    link.download = servcOrdItemAtchmtDesc
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  setTitleModifier = (mod) => this.setState({ titleModifier: mod })

  chooseMessageModifierByStatus = (osDetails) => {
    const isCancelled = osDetails?.servcOrdStusCd === ServiceOrderStatusType.CANCELADA.id
    const isOutOfFlow = osDetails?.servcOrdStusCd === ServiceOrderStatusType.OUT_OF_FLOW.id
    const isNonConforming = osDetails?.servcOrdStusCd === ServiceOrderStatusType.NAO_CONFORME.id
    const isAssociated =
      osDetails?.servcOrdStusCd === ServiceOrderStatusType.ASSOCIADA.id &&
      osDetails?.statusOs === '1'

    if (isCancelled) return 'cancelled'
    if (isOutOfFlow) return 'out.of.flow'
    if (isNonConforming) return 'non.conforming'
    if (isAssociated) return 'associated'
  }

  getHeaderSubtitleByOSStatus(flux, osDetails) {
    return getFluxLabel(
      `customer.page.header.subtitle.status.${osDetails?.servcOrdStusCd}`,
      flux,
      this.chooseMessageModifierByStatus(osDetails),
    )
  }

  componentDidUpdate(prevProps) {
    const {
      osDetails,
      history,
      location: { state },
      params: { homePageVersionCustomerPortal },
    } = this.props

    // Works better in update because the params are not available in the first render
    if (homePageVersionCustomerPortal === 2) {
      history.replace(`/v2/service-details${window.location.search}`)
    }

    const flux = parseInt(osDetails?.servcCatgryTypCd)
    const existsFlux = this.verifyFlux(flux)
    const isAssociated = osDetails?.servcOrdStusCd === ServiceOrderStatusType.ASSOCIADA.id
    const verifyStatusOS =
      isAssociated && prevProps.osDetails !== osDetails && osDetails?.statusOs === '1'

    let { success } = state || {}
    const isReschedule = success === 'reschedule'

    if (verifyStatusOS && existsFlux) {
      success = isReschedule && ''
      this.setState({ titleModifier: 'os.associated' })
    }
    this.saveLocalStorage()
  }

  verifyFlux(flux) {
    switch (flux) {
      case fluxes.installation:
        return true

      case fluxes.technicalVisit:
      case fluxes.plannedFurnitureTechnicalVisit:
      case fluxes.highPotentialVisit:
        return true

      case fluxes.storeService:
        return true

      case fluxes.packageService:
        return true

      default:
        return false
    }
  }

  render() {
    const { titleModifier } = this.state
    const {
      t,
      location: { search, state },
      params: { svrcOrderSendConfirmationSms },
      params,
      clientRescheduleRequest,
      osDetails,
    } = this.props

    const flux = parseInt(osDetails?.servcCatgryTypCd || 0)

    const isPackageServiceFlux = flux === ServcOrdCategoryType.PACKAGES_SUBSERVICES.id

    const currentStatusOrderText = ServiceOrderStatusType.getStatusWithId(osDetails?.servcOrdStusCd)

    const statusOs = parseInt(osDetails?.statusOs || 0)
    const isCancelled = osDetails?.servcOrdStusCd === ServiceOrderStatusType.CANCELADA.id
    const isOutOfFlow = osDetails?.servcOrdStusCd === ServiceOrderStatusType.OUT_OF_FLOW.id
    const isNonConforming = osDetails?.servcOrdStusCd === ServiceOrderStatusType.NAO_CONFORME.id
    const isAssociated =
      osDetails?.servcOrdStusCd === ServiceOrderStatusType.ASSOCIADA.id &&
      osDetails?.statusOs === '1' &&
      titleModifier !== 'reschedule.request'

    const isDefaultClientStep =
      !isCancelled && !isOutOfFlow && !isNonConforming && (statusOs === 0 || statusOs <= 5)

    const modifiers = bodyModifiers(
      flux,
      parseInt(statusOs),
      isCancelled,
      isOutOfFlow,
      isNonConforming,
      isAssociated,
      osDetails?.showRescheduleOption,
      osDetails?.rescheduledByClient,
    )

    const { components = [] } = modifiers || {}

    const checkNonConformityPackages = isPackageServiceFlux && isNonConforming

    const headerSubtitleTranslationKey = checkNonConformityPackages
      ? this.getHeaderSubtitleByOSStatus(flux, osDetails)
      : this.specialSubtitle ||
        situationList(flux)[statusOs - 1] ||
        this.getHeaderSubtitleByOSStatus(flux, osDetails)

    const dtLiberation = moment(osDetails?.date, 'L').subtract(svrcOrderSendConfirmationSms, 'days')

    const isActualDt = moment().format('L') === moment(osDetails?.date, 'L').format('L')

    const isYesterdeyDt = moment().subtract(1, 'days').format('L') === dtLiberation.format('L')

    const confirmationDate = isActualDt || isYesterdeyDt ? moment() : dtLiberation

    const customBodyTitleStep =
      isPackageServiceFlux && statusOs === 2 ? currentStatusOrderText.value : statusOs

    const customerPageHeaderTitleInstallationText = getFluxLabel(
      `customer.page.body.title.step.${customBodyTitleStep}`,
      flux,
      titleModifier,
    )

    const schedulingDate = osDetails?.date
    const schedulingSlot = t(schdlgCds[osDetails?.idSlotApplication] || '').toLowerCase()
    const schedulingConfirmationDate = confirmationDate.format('L')
    const schedulingWeekDay = moment(osDetails?.date, 'L').format('dddd')
    const installer = osDetails?.servcPrvdrAgntNm
    const provider = osDetails?.servcPrvdrTrdNm
    const serviceGroup =
      osDetails?.servcGrpNm && osDetails?.servcGrpNm?.split('-').length > 1
        ? osDetails?.servcGrpNm?.split('-')[1].trim()
        : osDetails?.servcGrpNm

    return (
      <>
        <Header
          title={`${t(`customer.page.header.title.installation`)} ${osDetails?.servcOrdSeq}`}
          subtitle={t(headerSubtitleTranslationKey)}
          osDetails={osDetails}
        />
        <Body
          title={osDetails?.custFullNm && osDetails?.custFullNm.split(' ')[0]}
          subtitle={
            <span>
              {isDefaultClientStep
                ? ReactHtmlParser(
                    ReplaceTextKeys(customerPageHeaderTitleInstallationText, {
                      schedulingDate,
                      schedulingSlot,
                      schedulingConfirmationDate,
                      schedulingWeekDay,
                      installer,
                      provider,
                      serviceGroup,
                    }),
                  )
                : ReactHtmlParser(
                    getFluxLabel(
                      `customer.page.body.title.status.${osDetails?.servcOrdStusCd}`,
                      flux,
                      this.chooseMessageModifierByStatus(osDetails),
                    ).replace('{serviceOrderReworkCd}', osDetails?.reworkServcOrdCd),
                  )}
            </span>
          }
        >
          <div className='service-details'>
            {components.map((comp, i) => (
              <DynamicContentBody
                flux={flux}
                status={statusOs}
                success={state?.success}
                query={search}
                submitEvalForm={this.handleEvalSubmit}
                downloadNf={this.downloadNf}
                component={comp}
                t={t}
                details={osDetails}
                setTitleModifier={this.setTitleModifier}
                fetchOsInfo={this.handleFetchOSInfo}
                clientRescheduleRequest={clientRescheduleRequest}
                key={i}
                isRescheduledByClient={osDetails?.rescheduledByClient}
              />
            ))}
          </div>
        </Body>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  osDetails: state.customerReducer.osDetails,
  occurrences: state.occurrencesReducer,
  params: state.setupParameters,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOsInfo,
      saveEvaluation,
      fetchReceipt,
      clientRescheduleRequest,
    },
    dispatch,
  )

const ServiceDetailsTranslated = withTranslation()(ServiceDetails)

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailsTranslated)
