import React from 'react'

import { isEmpty } from 'lodash'
import { Tabs } from '@leroy-merlin-br/backyard-react'
import ReactHtmlParser from 'react-html-parser'

import Skeleton from 'react-loading-skeleton'

import ErrorFallback from '../../../../errors/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'

import { htmlHasOnlySpaces } from '../../../../utils/string-helper'
import { fetchServiceConditions } from '../../../../services/conditions'

const mapServicesConditions = {
  A: {
    field: 'inclddServcTxt',
    label: 'subcategoriesByLm.form.includedForClients',
  },
  B: {
    field: 'inclddNotServcTxt',
    label: 'subcategoriesByLm.form.excludedForClients',
  },
  C: {
    field: 'checklistServcTxt',
    label: 'subcategoriesByLm.form.checklistForClients',
  },
  D: {
    field: 'inclddMaterial',
    label: 'subcategoriesByLm.form.inclddMaterial',
  },
  E: {
    field: 'inclddNotMaterial',
    label: 'subcategoriesByLm.form.inclddNotMaterial',
  },
  F: {
    field: 'serviceConditions',
    label: 'subcategoriesByLm.form.serviceConditions',
  },
}

function IncludedAndExcludedNoneSG(props) {
  const { t, details } = props
  const [servicesConditions, setServicesConditions] = React.useState([])

  React.useEffect(() => {
    handleFetchData()
  }, [details?.serviceOrderItem])

  const setDefaultLoadingServicesInfo = () => {
    const serviceConditions = []
    if (details.error !== undefined || details.serviceOrderItem === undefined) {
      return
    }
    details.serviceOrderItem.map((s) =>
      serviceConditions.push({ loading: true, servcCd: s.servcCd }),
    )
    setServicesConditions(serviceConditions)
    return serviceConditions
  }

  const updateServicesConditions = (servicesConditions, service) => {
    const newList = []
    servicesConditions.forEach((s) => {
      if (s.servcCd === service.servcCd) {
        newList.push(service)
      } else {
        newList.push(s)
      }
    })
    setServicesConditions(newList)
    return newList
  }

  async function handleFetchData() {
    if (!details) {
      return
    }
    if (!details.serviceOrderItem) {
      return
    }

    let servicesConditionsCurr = servicesConditions
    if (servicesConditions.length === 0) {
      servicesConditionsCurr = setDefaultLoadingServicesInfo()
    }

    // Fetch service condition for each service
    for (const i in details.serviceOrderItem) {
      const service = details.serviceOrderItem[i]
      const response = await fetchServiceConditions(service.servcCd)
      const findCondition = servicesConditionsCurr.find((s) => s.servcCd === service.servcCd)
      servicesConditionsCurr = updateServicesConditions(servicesConditionsCurr, {
        ...findCondition,
        data: response.data,
        loading: false,
      })
    }
  }

  function isValidConditions(service) {
    const showConditionsOfServices =
      props.params.customerSexTrackPageShowConditionsOfServices.split(',')
    const findCondition = servicesConditions.find((s) => s.servcCd === service.servcCd)

    if (!findCondition) return false

    for (const condIdx in showConditionsOfServices) {
      const cond = showConditionsOfServices[condIdx]
      const serviceConditions = mapServicesConditions[cond]
      const value = findCondition.data[0][serviceConditions.field]
      if (isEmpty(value) || htmlHasOnlySpaces(value)) {
        return false
      }
    }
    return true
  }

  function resolveTabsItens(service) {
    const showConditionsOfServices =
      props.params.customerSexTrackPageShowConditionsOfServices.split(',')
    const findCondition = servicesConditions.find((s) => s.servcCd === service.servcCd)

    if (!findCondition) return

    const listItens = []
    showConditionsOfServices.forEach((cond) => {
      const serviceConditions = mapServicesConditions[cond]
      if (serviceConditions) {
        listItens.push({
          id: serviceConditions.field,
          label: t(serviceConditions.label),
          panel: ReactHtmlParser(findCondition.data[0][serviceConditions.field]),
        })
      }
    })
    return listItens
  }

  const renderTabs = (val) => {
    const tabsList = resolveTabsItens(val)
    return <Tabs items={tabsList} />
  }

  if (servicesConditions.length === 0) return <IncludedAndExcludedNoneSGGhost />

  const renderServices = (services) => {
    if (!services) return

    return services.map((val, i) => {
      const condition = servicesConditions.find((s) => s.servcCd === val.servcCd)

      if (!condition || condition.loading) {
        return <IncludedAndExcludedNoneSGGhost key={`service-${i}`} />
      }

      const isValid = isValidConditions(val)

      if (isValid) {
        return (
          <div id='services-include-exclude' key={`service-${i}`}>
            <h3 className='conditions-title'>
              {`${t('client.portal.servcGrp.service.conditions.label')} ${val.nomeProd}`}
            </h3>
            {renderTabs(val)}
          </div>
        )
      }

      return <div />
    })
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => handleFetchData()}>
      <div className='check-list-client'>
        <header>{renderServices(details.serviceOrderItem)}</header>
        <br />
      </div>
    </ErrorBoundary>
  )
}

export default IncludedAndExcludedNoneSG

function IncludedAndExcludedNoneSGGhost() {
  return (
    <div>
      <Skeleton height={60} />
    </div>
  )
}
