import i18next from 'i18next'
import tScheduleInfo from '../../../types/components/scheduleInfo'
import { getFluxLabel } from '../../../utils/ServiceOrderFlux'
import { formatPostalCode } from '../../../utils/masks'
import Button from '../../Button'

import Location from '../../../assets/icons/location.svg?react'

function AddressBox(props: tScheduleInfo) {
  const { flux, details, openChangeAddressPage } = props

  const showAddressBtn = false

  return (
    <div className='address'>
      <Location />
      <span>{getFluxLabel('customer.service.order.details.address', flux)}</span>
      <p>{`${details.servcAddrStrNm || ''} ${
        (details.servcAddrNr && ` - ${details.servcAddrNr} `) || ''
      }`}</p>
      <p>{`${details.servcAddrCmplmtryTxt || ''} ${
        (details.servcAddrRefPointDesc && ` - ${details.servcAddrRefPointDesc} `) || ''
      }`}</p>
      <p>{`${details.servcAddrDstrctNm || ''} ${
        (details.servcAddrPstlCd &&
          ` - ${i18next.t('changeAddressForm.servcAddrPstlCd')} ${formatPostalCode(
            details.servcAddrPstlCd,
          )} `) ||
        ''
      }`}</p>
      <p>{`${details.servcAddrCityNm || ''} ${
        (details.servcAddrStCd && ` - ${details.servcAddrStCd} `) || ''
      }`}</p>
      {showAddressBtn && (
        <>
          <Button
            onClick={() => openChangeAddressPage()}
            className='margin button-fit-content'
            fitContent
            variant='white'
            label={i18next.t('company.info.change.address')}
          />
        </>
      )}
    </div>
  )
}

export default AddressBox
