import i18next from 'i18next'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchTemplateById } from '../redux/actions/templates'
import store from '../redux/store'

function ClientFooter() {
  const parameters = store.getState().setupParameters

  return (
    <div className='client-footer'>
      <div className='talk-to-us'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={parameters.serviceOrderMailSocialWhatsapp}
        >
          <img alt='' src='/assets/phone.svg' />
        </a>
        <span>{ReactHtmlParser(i18next.t('serviceOrder.mail.footer.contact'))}</span>
      </div>
      <div className='wpp-attention'>
        <h4>{i18next.t('serviceOrder.mail.footer.attention')}</h4>
        <span>{ReactHtmlParser(i18next.t('serviceOrder.mail.footer.attentionText'))}</span>
      </div>
      <div className='social-media'>
        <img alt='' src='/assets/social-media.svg' className='social-media-logo' />
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={parameters.serviceOrderMailSocialTwitter}
        >
          <img alt='' src='/assets/twitter.svg' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={parameters.serviceOrderMailSocialFacebook}
        >
          <img alt='' src='/assets/facebook.svg' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={parameters.serviceOrderMailSocialInstagram}
        >
          <img alt='' src='/assets/instagram.svg' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={parameters.serviceOrderMailSocialPinterest}
        >
          <img alt='' src='/assets/pinterest.svg' />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={parameters.serviceOrderMailSocialYoutube}
        >
          <img alt='' src='/assets/youtube.svg' />
        </a>
      </div>
      <div className='address'>
        <span>{i18next.t('serviceOrder.mail.company.address')}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  template: state.templatesReducer.templateFooterServiceDetails,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchTemplateById }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ClientFooter)
