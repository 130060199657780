import i18next from 'i18next'
import React, { Component } from 'react'
import Button from './Button'
import Lightbox from './Lightbox'

class ServicePicsUpload extends Component {
  constructor(props) {
    super(props)

    this.fileInput = React.createRef()
    this.state = {
      pics: {},
      lightboxPic: '',
      lightboxOpen: false,
    }
  }

  onFileChange = (input) => {
    const acceptedFormats = ['jpg', 'jpeg', 'png']
    const { pics } = this.state
    const { handleFileChange } = this.props
    const [pic] = input.target.files

    if (
      Object.keys(pics).includes(pic.name) ||
      !acceptedFormats.includes(pic.name.split('.').pop().toLowerCase())
    )
      return

    const reader = new FileReader()
    reader.readAsDataURL(pic)
    reader.onloadend = (e) => {
      this.setState({ pics: { ...pics, [pic.name]: reader.result } }, () => {
        handleFileChange(this.state.pics, input)
        // clear the fileInput value so it lets you load twice in a row the same file
        this.fileInput.value = null
      })
    }
  }

  clear = () => {
    this.setState({
      ...this.state,
      pics: {},
    })
  }

  render() {
    const { pics, lightboxOpen, lightboxPic } = this.state
    const { handleFileChange, isSingleFile, buttonAddLabel } = this.props

    return (
      <>
        <Lightbox
          handleClose={() => this.setState({ lightboxOpen: false })}
          open={lightboxOpen}
          img={lightboxPic}
        />

        <input
          ref={(input) => (this.fileInput = input)}
          type='file'
          className='hide'
          onChange={this.onFileChange}
          accept='image/*'
        />
        <div className='pic-previews-container'>
          {Object.entries(pics || []).map(([key, value], i) => (
            <div className='pic-table' key={i}>
              <div className='miniature-container'>
                <img
                  alt=''
                  className='miniature cursor-pointer'
                  src={value}
                  onClick={() =>
                    this.setState({
                      lightboxOpen: true,
                      lightboxPic: value,
                    })
                  }
                />
              </div>
              <div className='pic-description'>
                <h5>{key}</h5>
                <div
                  onClick={() => {
                    this.setState({ pics: { ...pics, [key]: undefined } }, () => {
                      this.setState(
                        {
                          pics: JSON.parse(JSON.stringify(this.state.pics)),
                        },
                        () => {
                          handleFileChange(this.state.pics)
                        },
                      )
                    })
                  }}
                >
                  <img alt='' src='/assets/trash.svg' />
                  <span>{i18next.t('customer.service.order.evaluation.remove.pics')}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!isSingleFile || Object.entries(pics || []).length === 0 ? (
          <Button
            variant='white'
            className='button-fit-content'
            fitContent
            onClick={() => this.fileInput.click()}
            label={
              <>
                <img alt='' src='/assets/camera.svg' />
                {buttonAddLabel || i18next.t('customer.service.order.evaluation.add.pics')}
              </>
            }
          />
        ) : (
          ''
        )}
      </>
    )
  }
}

export default ServicePicsUpload
