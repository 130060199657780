import Skeleton from 'react-loading-skeleton'

function PackageServicesGhost() {
  return (
    <section>
      <Skeleton height={30} width={190} />
      <Skeleton height={30} width={670} />
      <Skeleton height={200} width={440} />
      <Skeleton height={50} width={150} />
    </section>
  )
}

export default PackageServicesGhost
