import {
  CUSTOMER_FETCH_OS_INFO_FAILURE,
  CUSTOMER_FETCH_OS_INFO_SUCCESS,
  CUSTOMER_SMS_CODE_SUCCESS,
  CUSTOMER_SMS_CODE_FAILURE,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAILURE,
  CUSTOMER_FETCH_SERVICE_ORDER_SUCCESS,
  CUSTOMER_FETCH_SERVICE_ORDER_FAILURE,
  SERVC_ORD_RECEIPT_REQUEST_SUCCESS,
  SERVC_ORD_RECEIPT_REQUEST_FAILURE,
  CUSTOMER_FETCH_SERVICE_GROUP_INFO_SUCCESS,
  CUSTOMER_FETCH_SERVICE_GROUP_INFO_FAILURE,
} from '../actions/actionTypes'

const osDetails = (state = {}, action) => {
  switch (action.type) {
    case SERVC_ORD_RECEIPT_REQUEST_SUCCESS:
      return {
        ...state,
        receipt: {
          data: action.payload,
          error: null,
        },
      }

    case SERVC_ORD_RECEIPT_REQUEST_FAILURE:
      return {
        ...state,
        receipt: {
          data: null,
          error: action.payload,
        },
      }

    case CUSTOMER_FETCH_OS_INFO_SUCCESS:
      return {
        ...state,
        osDetails: action.payload,
      }

    case CUSTOMER_FETCH_OS_INFO_FAILURE:
      return {
        ...state,
        osDetails: {
          error: true,
        },
      }

    case CUSTOMER_SMS_CODE_SUCCESS:
      return {
        ...state,
        userValid: true,
      }

    case CUSTOMER_SMS_CODE_FAILURE:
      return {
        ...state,
        userValid: false,
      }

    case CUSTOMER_LOGIN_SUCCESS:
      return {
        ...state,
        loginInfo: action.payload,
      }

    case CUSTOMER_LOGIN_FAILURE:
      return {
        ...state,
        loginInfo: {
          error: true,
        },
      }

    case CUSTOMER_FETCH_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        serviceOrders: action.payload,
      }

    case CUSTOMER_FETCH_SERVICE_ORDER_FAILURE:
      return {
        ...state,
        serviceOrders: {
          error: true,
        },
      }

    case CUSTOMER_FETCH_SERVICE_GROUP_INFO_SUCCESS:
      return {
        ...state,
        serviceGroup: action.payload,
      }

    case CUSTOMER_FETCH_SERVICE_GROUP_INFO_FAILURE:
      return {
        ...state,
        serviceGroup: {
          error: true,
        },
      }

    default:
      return state
  }
}

export default osDetails
