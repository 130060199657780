import Mask from 'vanilla-masker'
import moment from 'moment-timezone'
import { FORMAT_DATE, FORMAT_DATE_TRUNC } from '../constants/config'
import i18next from 'i18next'

export const isDefined = (value) => value !== null && value !== undefined

export const isFilled = (value) => value.trim() !== ''

export const getBankIdMask = (t = (value) => value) => t('bankid.mask')

export const removeMask = (value) => (value ? value.replace(/[^\d]+/g, '') : null)

export const format = (value, regex, disableMultipleMask = false) => {
  value = `${value}`

  if (!isDefined(value) || !isFilled(value)) {
    return ''
  }

  value = removeMask(value)

  return disableMultipleMask ? Mask.toPattern(value, regex) : toPatternMultiple(value, regex)
}

export const getCPFMask = (t = (value) => value) => t('cpf.mask')

export const formatCPF = (value, t = (value) => value) => {
  if (value && value.match(/[a-z]/i)) return value

  return format(value, getCPFMask(t))
}

export const getCNPJMask = (t = (value) => value) => t('cnpj.mask')

export const formatCNPJ = (value, t = (value) => value) => format(value, getCNPJMask(t))

export const getPhoneMask = (t = (value) => value) => t('phone.mask')

export const formatPhone = (value, t = (value) => value) => format(value, getPhoneMask(t))

export const getPostalCodeMask = (t = null) => {
  if (t === null) {
    return i18next.t('postal.code.mask')
  }
  return t('postal.code.mask')
}

export const formatPostalCode = (value, t = null) => format(value, getPostalCodeMask(t))

export const formatDecimal = (value, t = (value) => value) => {
  if (!isDefined(value)) {
    value = 0
  }

  return `${t('option.currency')} ${Mask.toMoney(value.toFixed(t('size.currency')), {
    separator: t('separator.currency'),
    delimiter: t('delimiter.currency'),
  })}`
}

export const isSamePattern = (value1, value2) => {
  if (!value1 || !value2) return false
  return value1.replace(/\d/g, '9') === value2.replace(/\d/g, '9')
}

export const formatDecimalNoCurrency = (value, t = (value) => value) => {
  if (!isDefined(value)) {
    value = 0
  }

  return `${Mask.toMoney(value.toFixed(t('size.currency')), {
    separator: t('separator.currency'),
    delimiter: t('delimiter.currency'),
  })}`
}

export const formatDate = (value) => moment(value).format(FORMAT_DATE)

export const formatDateTrunc = (value) => moment(value).format(FORMAT_DATE_TRUNC)

export const toPatternMultiple = (input, mask) => {
  if (input) {
    const regex = /^\[.*\]$/
    const isArrayOfMasks = regex.test(mask)
    if (!isArrayOfMasks) {
      return Mask.toPattern(input, mask)
    }
    // Masks array is only working for numeric masks (9 ... 9)
    const maskArray = mask
      .replace(/^\[|\]$/g, '') // remove square brackets
      .split(';') // create an array based on semicolon
      .map((mask) => mask.replace(/^\s+|\s+$/g, '')) // remove espaços no inicio ou final do item
      .map((mask) => ({
        mask,
        length: mask.replace(/[^9]/g, '').length,
      })) // transform into an object with a length property (length of valid input numbers)
      .sort((a, b) => a.length - b.length) // sort based on length
    const value = input.replace(/\D/g, '') // keep only numeric values
    const currentMask = maskArray.find(
      (mask, i) => mask.length >= value.length || i === maskArray.length - 1,
    ) // take the first mask of length greater than or equal to the length of the value
    return Mask.toPattern(value, currentMask.mask)
  }
}

/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable curly */

export const validateCpf = function (cpf) {
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false
  var soma = 0
  var resto
  for (var i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if (resto == 10 || resto == 11) resto = 0
  if (resto != parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (var i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if (resto == 10 || resto == 11) resto = 0
  if (resto != parseInt(cpf.substring(10, 11))) return false
  return true
}

/* eslint-enable no-redeclare */
/* eslint-enable eqeqeq */
/* eslint-enable curly */
