import { fluxes } from '../constants/constants'
import ServcOrdCategoryType from '../utils/ServcOrdCategoryType'

const BodyModifiers = (
  flux: number,
  step: number,
  isCancelled: boolean,
  isOutOfFlow: boolean,
  isNonConforming: boolean,
  isAssociated: boolean,
  isContinuation: boolean,
  isRescheduleByClient: boolean,
) => {
  const isPackageServiceFlux: boolean =
    Number(flux) === Number(ServcOrdCategoryType.PACKAGES_SUBSERVICES.id)

  const purchaseConfirmed = {
    components: [
      'newScheduleInfo',
      'br',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const purchaseConfirmedWithOSAssociated = {
    components: [
      'newScheduleInfo',
      'br',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const confirmScheduling = {
    components: [
      'confirmationContainer',
      'br',
      'newScheduleInfo',
      'reschedulingConfirmation',
      'br',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const serviceAuthorized = {
    components: [
      'newScheduleInfo',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const evaluation = {
    components: [
      'evalContainer',
      'br',
      'newScheduleInfo',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const warranty = {
    components: [
      'newScheduleInfo',
      'br',
      'nfDownloadButton',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const serviceCancelled = {
    components: [
      'newScheduleInfoCancelled',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const serviceOutOfFlow = {
    components: [
      'newScheduleInfo',
      'horizontalLine',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const serviceNonConforming = {
    components: [
      'followUpReworkServiceBtn',
      'br',
      'newScheduleInfo',
      'br',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const packagesServiceNonConforming = {
    components: [
      'followUpReworkServiceBtn',
      'packagesServices',
      'br',
      'newScheduleInfo',
      'br',
      'servicesTable',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const packageService = {
    components: [
      'packagesServices',
      'newScheduleInfo',
      'servicesTable',
      'br',
      'br',
      'includedAndExcludedToClient',
      'horizontalLine',
    ],
  }

  const installationFlux: any = {
    1: purchaseConfirmed,
    2: confirmScheduling,
    3: serviceAuthorized,
    4: evaluation,
    5: warranty,
  }

  const technicalVisitFlux: any = {
    1: purchaseConfirmed,
    2: confirmScheduling,
    3: serviceAuthorized,
    4: evaluation,
    5: {
      components: [
        'newScheduleInfo',
        'br',
        'nfDownloadButton',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
  }

  const plannedFurnitureTechnicalVisitFlux: any = {
    1: purchaseConfirmed,
    2: confirmScheduling,
    3: serviceAuthorized,
    4: {
      components: ['newScheduleInfo', 'br', 'nfDownloadButton', 'servicesTable', 'horizontalLine'],
    },
  }

  const storeServiceFlux: any = {
    1: purchaseConfirmed,
    2: confirmScheduling,
    3: serviceAuthorized,
    4: {
      components: [
        'newScheduleInfo',
        'br',
        'evalContainer',
        'br',
        'servicesTable',
        'horizontalLine',
      ],
    },
    5: {
      components: ['servicesTable', 'horizontalLine'],
    },
  }

  const packageServiceFlux: any = {
    1: {
      components: [
        'newScheduleInfo',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    2: packageService,
    3: {
      components: [
        'packagesServices',
        'confirmationContainer',
        'newScheduleInfo',
        'br',
        'reschedulingConfirmation',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    4: {
      components: [
        'packagesServices',
        'newScheduleInfo',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    5: {
      components: [
        'packagesServices',
        'evalContainer',
        'br',
        'newScheduleInfo',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    6: {
      components: [
        'packagesServices',
        'newScheduleInfo',
        'br',
        'nfDownloadButton',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
  }

  const hourlyServiceFlux: any = {
    1: {
      components: [
        'husbandRent',
        'br',
        'newScheduleInfo',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    2: {
      components: [
        'husbandRent',
        'br',
        'newScheduleInfo',
        'br',
        'confirmationContainer',
        'reschedulingConfirmation',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    3: {
      components: [
        'husbandRent',
        'br',
        'newScheduleInfo',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    4: {
      components: [
        'husbandRent',
        'evalContainer',
        'newScheduleInfo',
        'br',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
    5: {
      components: [
        'husbandRent',
        'newScheduleInfo',
        'br',
        'nfDownloadButton',
        'servicesTable',
        'br',
        'includedAndExcludedToClient',
        'horizontalLine',
      ],
    },
  }

  if (isCancelled) return serviceCancelled
  if (isOutOfFlow) return serviceOutOfFlow
  if (isNonConforming && !isPackageServiceFlux) return serviceNonConforming
  if (isNonConforming && isPackageServiceFlux) return packagesServiceNonConforming
  if (isAssociated) return purchaseConfirmedWithOSAssociated
  if (isRescheduleByClient) return serviceAuthorized
  if (isContinuation) {
    switch (flux) {
      case fluxes.installation:
        return {
          components: ['requestRescheduleBlock', ...installationFlux[step].components],
        }

      case fluxes.technicalVisit:
      case fluxes.highPotentialVisit:
        return {
          components: ['requestRescheduleBlock', ...technicalVisitFlux[step].components],
        }

      case fluxes.plannedFurnitureTechnicalVisit:
        return {
          components: [
            'requestRescheduleBlock',
            ...plannedFurnitureTechnicalVisitFlux[step].components,
          ],
        }

      case fluxes.storeService:
        return {
          components: ['requestRescheduleBlock', ...storeServiceFlux[step].components],
        }

      case fluxes.packageService:
        return {
          components: ['requestRescheduleBlock', ...packageServiceFlux[step].components],
        }

      case fluxes.hourlyService:
        return {
          components: ['requestRescheduleBlock', 'br', ...hourlyServiceFlux[step].components],
        }

      default:
        return {
          components: ['requestRescheduleBlock', ...installationFlux[step || 1].components],
        }
    }
  }

  switch (flux) {
    case fluxes.installation:
      return installationFlux[step]

    case fluxes.technicalVisit:
    case fluxes.highPotentialVisit:
      return technicalVisitFlux[step]

    case fluxes.plannedFurnitureTechnicalVisit:
      return plannedFurnitureTechnicalVisitFlux[step]

    case fluxes.storeService:
      return storeServiceFlux[step]

    case fluxes.packageService:
      return packageServiceFlux[step]

    case fluxes.hourlyService:
      return hourlyServiceFlux[step]

    default:
      return installationFlux[step || 1]
  }
}

export default BodyModifiers
