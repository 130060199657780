import { fluxes } from '../constants/constants'
import i18next from 'i18next'

export const getTranslationAppend = (flux) => {
  switch (flux) {
    case fluxes.installation:
      return 'installation'

    case fluxes.technicalVisit:
    case fluxes.highPotentialVisit:
      return 'technical.visit'

    case fluxes.plannedFurnitureTechnicalVisit:
      return 'planned.furniture.technical.visit'

    case fluxes.storeService:
      return 'store.service'

    case fluxes.packageService:
      return 'package.service'

    case fluxes.hourlyService:
      return 'hourly.service'

    default:
      return 'installation'
  }
}

export const CURRENT_STEP = {
  0: '',
  1: '.step1',
  2: '.step2',
  3: '',
  4: '',
  5: '',
}

export const getFluxLabel = (key, category, extraModf = '', status = 0) =>
  i18next?.t(
    `${key}.${getTranslationAppend(category)}${CURRENT_STEP[status]}${
      extraModf ? `.${extraModf}` : ''
    }`,
  )
