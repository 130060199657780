import { FETCH_OCCURRENCES_SUCCESS, FETCH_OCCURRENCES_ERROR } from '../actions/actionTypes'

const DEFAULT_STATE = {}

const occurrences = (state = DEFAULT_STATE, action) => {
  const { servcOrdSeq, data } = action.payload || {}

  switch (action.type) {
    case FETCH_OCCURRENCES_SUCCESS:
      return {
        ...state,
        [servcOrdSeq]: data,
      }
    case FETCH_OCCURRENCES_ERROR:
      return {
        ...state,
        [servcOrdSeq]: { error: true },
      }
    default:
      return state
  }
}

export default occurrences
