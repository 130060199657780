import React, { useState } from 'react'

import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import moment from 'moment-timezone'

import { schdlgCds } from '../constants/constants'

import { formatPostalCode } from '../utils/masks'
import { getFluxLabel } from '../utils/ServiceOrderFlux'
import { formatNationalOrInternationalPhoneNumber } from '../utils/InternationalPhoneNumberUtils'

import Modal from 'react-modal'

import ServiceOrderStatusType from '../utils/ServiceOrderStatusType'

import Button from './Button'
import ProviderBox from '../pages/ServiceDetails/components/ProviderBox'
import SpecialistBox from '../pages/ServiceDetails/components/InstallerBox'
import SatisfactionSurvey from './SatisfactionSurvey'
import ServiceConfirmation from './ServiceConfirmation'
import ServiceDescription from './ServiceDescription'
import RescheduleConfirmation from './RescheduleConfirmation'
import IncludedAndExcluded from '../pages/ServiceDetails/components/IncludedAndExcluded'
import PackageServices from '../pages/ServiceDetails/components/PackageServices'
import HusbandRent from '../pages/ServiceDetails/components/HusbandRent'
import ScheduleInfo from './scheduleInfo/index.tsx'
import SatisfactionSurveyProvider from './SatisfactionSurvey/context'
import IncludedAndExcludedNoneSG from '../pages/ServiceDetails/components/IncludedAndExcludedNoneSG'

const openChangeAddressPage = (orderDetails, query, history, scrollTo = null) =>
  history.push({
    pathname: '/service-details/address-edit',
    search: query,
    state: { orderDetails, scrollTo },
  })

const openReschedulePage = (orderDetails, query, history) =>
  history.push({
    pathname: '/service-details/reschedule',
    search: query,
    state: { orderDetails },
  })

function DynamicContentBody(props) {
  const {
    details = {},
    t,
    query,
    success,
    history,
    submitEvalForm,
    flux,
    component,
    status,
    receipt,
    downloadNf,
    setTitleModifier,
    fetchOsInfo,
    clientRescheduleRequest,
    isRescheduledByClient,
    params,
  } = props

  const date = (details.date && moment(details.date, 'DD/MM/YYYY')) || 0
  const [showRescheduleRequest, setShowRescheduleRequest] = useState(false)

  switch (component) {
    /* Line break */
    case 'br':
      return <br />

    /* Component for body title */
    case 'bodyTitle':
      return (
        <h4>
          <b>{getFluxLabel('customer.service.order.details.info', flux)}</b>
        </h4>
      )

    case 'requestRescheduleBlock':
      return (
        <>
          <Modal
            onClose={() => setShowRescheduleRequest(false)}
            isCentered
            isOpen={showRescheduleRequest}
            size='giga'
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              content: {
                position: 'relative',
                width: 900,
                minHeight: 180,
                borderRadius: 8,
                inset: 0,
                paddingLeft: 25,
                paddingRight: 25,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },
            }}
            ariaHideApp={false}
          >
            <span className='margin-bottom' style={{ fontWeight: 'bold', fontSize: 19 }}>
              {t('service.reschedule.title').replace('{schedulingDate}', details.date)}
            </span>
            <span className='margin-bottom' style={{ fontSize: 16 }}>
              <strong>{t('service.confirmation.bank.obs.text1').concat(' ')}</strong>
              {t('service.reschedule.warning')}
            </span>
          </Modal>
          <span className='margin-top'>
            {t('customer.service.order.reschedule.button.message')}
          </span>
        </>
      )

    case 'bodyTitleNonConforming':
      return (
        <h4>
          <b>{getFluxLabel('customer.service.order.details.info', flux, 'non.conforming')}</b>
        </h4>
      )

    /* Component for service eval */
    case 'evalContainer':
      return (
        details.enableEvaluation && (
          <SatisfactionSurveyProvider osDetails={details}>
            <SatisfactionSurvey submit={submitEvalForm} osDetails={details} />
          </SatisfactionSurveyProvider>
        )
      )

    /* Block that shows a line with only the os number */
    case 'osNumber':
      return (
        <span>
          {t('customer.service.order.details.os.number')} <b>{details.servcOrdSeq}</b>
        </span>
      )

    case 'installerName':
      return (
        <span>
          {t('customer.service.order.details.installer.name')}{' '}
          <b>
            {details.servcPrvdrAgntNm || t('customer.service.order.details.installer.name.error')}
          </b>
        </span>
      )

    /* Block that contains the warranty info */
    case 'warrantyInfo':
      return (
        <span>
          {t('customer.service.order.details.warranty.valid')}
          <b> {moment(details.warrantyMaxDt).format('L')}</b>
        </span>
      )

    case 'conclusionDate':
      return (
        <span>
          {t('customer.service.order.details.finish.date')}
          <b>{(date && date.format('L')) || '-'}</b>
        </span>
      )

    case 'conclusionDateWithFluxLabel':
      return (
        <span>
          {getFluxLabel('customer.service.order.details.finish.date', flux)}{' '}
          <b>{(date && date.format('L')) || '-'}</b>
        </span>
      )

    case 'newScheduleInfo':
      return (
        <ScheduleInfo
          flux={flux}
          details={details}
          step={status}
          isRescheduledByClient={isRescheduledByClient}
          success={success}
          openChangeAddressPage={(scrollTo = null) =>
            openChangeAddressPage(details, query, history, scrollTo)
          }
          openReschedulePage={() => openReschedulePage(details, query, history)}
        />
      )

    case 'newScheduleInfoCancelled':
      return (
        <ScheduleInfo
          flux={flux}
          details={details}
          step={status}
          isRescheduledByClient={isRescheduledByClient}
          success={success}
          isCanceled
        />
      )

    /* Block that containst the scheduling info */
    case 'schedulingInfo':
      return (
        <>
          <span>
            {getFluxLabel(
              'customer.service.order.details.scheduling.date',
              flux,
              details.servcOrdStusCd === ServiceOrderStatusType.OUT_OF_FLOW.id ? 'previst' : '',
              status,
            )}
            {isRescheduledByClient && (
              <strong>
                {' '.concat(t('customer.service.order.details.scheduling.date.wait'))}
              </strong>
            )}
            {success === 'reschedule' && status === 1 && (
              <p className='success-edit-msg'>{t('customer.service.order.reschedule.requested')}</p>
            )}
          </span>
          {!isRescheduledByClient && (
            <p>
              {t('customer.service.order.scheduling.date.format')
                .replace('{}', (date && date.format('dddd')) || '-')
                .replace('{}', (date && date.format('L')) || '-')
                .replace(
                  '{}',
                  (details.idSlotApplication &&
                    t(schdlgCds[details.idSlotApplication]).toLowerCase()) ||
                    '-',
                )}
            </p>
          )}
        </>
      )

    case 'schedulingInfoCancelled':
      return (
        <>
          <span>
            {getFluxLabel('customer.service.order.details.scheduling.date', flux)}
            {success === 'reschedule' && (
              <p className='success-edit-msg'>{t('customer.service.order.reschedule.requested')}</p>
            )}
          </span>
          <p>
            <s>
              {t('customer.service.order.scheduling.date.format')
                .replace('{}', (date && date.format('dddd')) || '-')
                .replace('{}', (date && date.format('L')) || '-')
                .replace(
                  '{}',
                  (details.idSlotApplication &&
                    t(schdlgCds[details.idSlotApplication]).toLowerCase()) ||
                    '-',
                )}
            </s>
          </p>
        </>
      )

    /* Block that contains the address info */
    case 'addressInfo':
      return (
        <>
          <span>{getFluxLabel('customer.service.order.details.address', flux)}</span>
          {success === 'address' && (
            <p className='success-edit-msg'>{t('customer.service.order.save.address.success')}</p>
          )}
          <p>{`${details.servcAddrStrNm || ''} ${
            (details.servcAddrNr && ` - ${details.servcAddrNr} `) || ''
          }`}</p>
          <p>{`${details.servcAddrCmplmtryTxt || ''} ${
            (details.servcAddrRefPointDesc && ` - ${details.servcAddrRefPointDesc} `) || ''
          }`}</p>
          <p>{`${details.servcAddrDstrctNm || ''} ${
            (details.servcAddrPstlCd &&
              ` - ${t('changeAddressForm.servcAddrPstlCd')} ${formatPostalCode(
                details.servcAddrPstlCd,
                t,
              )} `) ||
            ''
          }`}</p>
          <p>{`${details.servcAddrCityNm || ''} ${
            (details.servcAddrStCd && ` - ${details.servcAddrStCd} `) || ''
          }`}</p>
        </>
      )

    /* Block that contains the phone numbers */
    case 'phones':
      return (
        <>
          <span>{getFluxLabel('customer.service.order.details.phones', flux)}</span>
          {success === 'phone' && (
            <p className='success-edit-msg'>
              {t('customer.service.order.details.save.phone.success')}
            </p>
          )}
          {details.custMobilePhonNr && (
            <span className='margin'>
              {t('customer.service.order.details.mobile.phone')}:{' '}
              <b>{`${
                formatNationalOrInternationalPhoneNumber(t, details.custMobilePhonNr) || '-'
              }`}</b>
            </span>
          )}
          {details.custHmPhonNr && (
            <span className='margin'>
              {t('customer.service.order.details.home.phone')}:{' '}
              <b>{`${formatNationalOrInternationalPhoneNumber(t, details.custHmPhonNr) || '-'}`}</b>
            </span>
          )}
          {details.custBusnsPhonNr && (
            <span className='margin'>
              {t('customer.service.order.details.business.phone')}:{' '}
              <b>{`${
                formatNationalOrInternationalPhoneNumber(t, details.custBusnsPhonNr) || '-'
              }`}</b>
            </span>
          )}
        </>
      )

    /* Block that contains the provider and specialist info */
    case 'providerInfo':
      return (
        <>
          <ProviderBox details={details} t={t} step={2} />
          <br /> <br />
          <SpecialistBox details={details} t={t} step={2} />
        </>
      )

    /* Block that contains the service confirmation and reschedule container */
    case 'confirmationContainer':
      return (
        <ServiceConfirmation
          onRescheduleClick={() => openReschedulePage(details, query, history)}
          query={query || {}}
          details={details}
          t={t}
          setTitleModifier={setTitleModifier}
          title={getFluxLabel('customer.service.order.reschedule.title', flux)}
        />
      )

    /* Block that contains the rescheduling acceptance */
    case 'reschedulingConfirmation':
      return (
        <RescheduleConfirmation
          setTitleModifier={setTitleModifier}
          onRescheduleClick={() => openReschedulePage(details, query, history)}
          query={query || {}}
          details={details}
          t={t}
          title={getFluxLabel('customer.service.order.reschedule.title', flux)}
        />
      )

    /* Block that renders the nf download button and title */
    case 'nfDownloadButton': {
      const hasServiceOrderTotalValue = details.servcOrdNfeSendMax !== null

      if (hasServiceOrderTotalValue) {
        return (
          <>
            <p className='ml-0'>
              <strong>{t('service.details.receipt')}</strong>
            </p>
            {receipt?.data?.preview ? (
              <Button
                className='button-fit-content margin-top margin-bottom-double'
                fitContent
                variant='white'
                label={
                  <>
                    <img alt='' src='/assets/download.svg' className='button-icon' />
                    {t('service.details.download.receipt')}
                  </>
                }
                onClick={() => downloadNf(receipt.data)}
              />
            ) : (
              <h5 className='margin-top margin-bottom-double normal-text'>
                {t('service.details.with.not.receipt.2')}
              </h5>
            )}
          </>
        )
      }
      return ''
    }

    /* Services Table */
    case 'servicesTable':
      return <ServiceDescription services={details.serviceOrderItem} t={t} flux={flux} />

    case 'followUpReworkServiceBtn':
      return (
        <Link to={{ pathname: details.reworkServcOrdUrl }} target='_blank'>
          <Button
            className='button-fit-content'
            fitContent
            label={`${getFluxLabel(
              'customer.service.order.follow.up.rework.service.button',
              flux,
            )} ${details.reworkServcOrdCd || ''}`}
          />
        </Link>
      )

    case 'includedAndExcludedToClient':
      if (params.useServiceGroupCodeServcOrdCreation) {
        return <IncludedAndExcluded t={t} details={details} />
      }
      return <IncludedAndExcludedNoneSG t={t} details={details} params={params} />
    case 'horizontalLine':
      return <span className='horizontal-line-dynamic-body' />

    case 'packagesServices':
      return <PackageServices servcOrdSeq={details.servcOrdSeq} />

    case 'husbandRent':
      return <HusbandRent servcOrdSeq={details.servcOrdSeq} />

    default:
      return null
  }
}

const mapStateToProps = (state) => ({
  params: state.setupParameters,
  receipt: state.customerReducer.receipt,
})

export default withRouter(connect(mapStateToProps)(DynamicContentBody))
