class User {
  /*
   * Seta user no localStorage
   * @param {Object} user
   */
  set currentUser(user) {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    }
  }

  /*
   * Recupera user do localStorage
   * @return {Object} user
   */
  get currentUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  /*
   * Seta menu do user no localStorage
   * @param {Object} menu
   */
  set menu(menu) {
    localStorage.setItem('userMenu', JSON.stringify(menu))
  }

  /*
   * Recupera menu do user do localStorage
   * @return {Object} menu
   */
  get menu() {
    return JSON.parse(localStorage.getItem('userMenu'))
  }

  /*
   * Limpa user e o menu do localStorage
   * @param {Object} menu
   */
  clearCache() {
    localStorage.removeItem('user')
    localStorage.removeItem('userMenu')
  }
}

export default User
