import i18next from 'i18next'

import tScheduleInfo from '../../../types/components/scheduleInfo'

function InstallerBox(props: tScheduleInfo) {
  const { details, warrantyStep } = props

  return (
    <div className='info-box mt-size'>
      <div className='image rounded'>
        {details.servcPrvdrAgntProfilePicture ? (
          <img
            alt=''
            className='img'
            src={`data:image/png;base64,${details.servcPrvdrAgntProfilePicture}`}
          />
        ) : (
          <img alt='' className='img contain' src='/assets/installer-placeholder.png' />
        )}
      </div>
      <div className='right-infos'>
        <p>
          {i18next.t('certificate.upload.screen.specialist.name') as string}{' '}
          <strong>{details.servcPrvdrAgntNm}</strong>
        </p>
        {!warrantyStep && (
          <p>
            {i18next.t('customer.service.order.details.infos.document') as string}:{' '}
            <b>{details.natrlPrsnAddlTaxIdNr}</b>
          </p>
        )}
      </div>
    </div>
  )
}

export default InstallerBox
