import React from 'react'
import i18next from 'i18next'

import { Text } from '@leroy-merlin-br/backyard-react'
import ReactHtmlParser from 'react-html-parser'

function ListPackages(props) {
  const {
    subservice: { subserviceTxt, subservicesDTO },
  } = props

  function handleResolveSubservicesList(subservice, index) {
    if (subservice.sbgrpQty === 0) return
    return (
      <span className='subservice-items' key={index}>
        {subservice.sbgrpNm}: {subservice.sbgrpQty}{' '}
        {subservice.sbgrpQty === 1 ? i18next.t('unity.text') : i18next.t('units.text')}
      </span>
    )
  }

  return (
    <section id='package-services-list-selected'>
      <header>
        <h3>{i18next.t('selected.services.text')}</h3>
      </header>

      <h4>{i18next.t('selected.services.helper.text')}:</h4>

      {(subservicesDTO || []).map((subservice, index) =>
        handleResolveSubservicesList(subservicesDTO[index], index),
      )}

      <p className='important-label-helper'>{i18next.t('important.label.text')}:</p>
      <Text className='subservice-text'>{ReactHtmlParser(subserviceTxt || '')}</Text>
    </section>
  )
}
export default ListPackages
