import React, { Component } from 'react'
import Select from 'react-select'
import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'
import { colourStyles } from '../../utils/selectStyle'

class CustomerSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      branchs: [],
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    this.setState({ loading: true })

    return new Api(null, dispatch)
      .get(API_URL.FIND_CUSTOMER_PUBLIC_STORES)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(branchs) {
    const options = branchs.map((branch) => ({ value: branch.id, label: branch.name }))

    this.setState({ branchs: options, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, branchs: [] })
  }

  render() {
    const { t, onChange } = this.props
    const { branchs, loading } = this.state

    const placeholder = loading ? t('option.loading') : ''

    return (
      <div>
        <label className='top-label'>{t('serviceOrders.store')}</label>
        <Select
          name='branchs'
          placeholder={placeholder}
          onChange={onChange}
          options={branchs}
          styles={colourStyles}
        />
      </div>
    )
  }
}

export default CustomerSelect
