/* CONSTANTE PARA STATUS DA ORDEM DE SERVIÇO */
export const ORDER_STATUS_TYPES = {
  '00': 'serviceOrders.preScheduled',
  '01': 'serviceOrders.scheduled',
  '02': 'serviceOrders.distributed',
  '03': 'serviceOrders.associated',
  '04': 'serviceOrders.confirmed',
  '05': 'serviceOrders.doing',
  '06': 'serviceOrders.done',
  '07': 'serviceOrders.partiallyDone',
  '08': 'serviceOrders.closed',
  '09': 'serviceOrders.canceled',
  10: 'serviceOrders.notAccordingly',
  11: 'serviceOrders.branch.waiting',
  12: 'serviceOrders.branch.doing',
  13: 'serviceOrders.branch.done',
  14: 'serviceOrders.branch.closed',
  15: 'serviceOrders.outofflow',
  16: 'serviceOrders.provider.preScheduled',
  17: 'serviceOrders.provider.scheduled',
  18: 'serviceOrders.provider.distributed',
  19: 'serviceOrders.provider.associated',
  20: 'serviceOrders.provider.confirmed',
  21: 'serviceOrders.provider.inRealization',
  22: 'serviceOrders.provider.succssesfullyDone',
  24: 'serviceOrders.provider.closed',
  99: 'serviceOrders.all',
  100: 'serviceOrders.allHouse',
  101: 'serviceOrders.allStore',
  102: 'serviceOrders.allProvider',
}

export const ORDER_STATUS_TYPE = {
  PRE_SCHEDULE: '00',
  SCHEDULED: '01',
  DISTRIBUTED: '02',
  ASSOCIATED: '03',
  CONFIRMED: '04',
  DOING: '05',
  DONE: '06',
  PARTIALLY_DONE: '07',
  CLOSED: '08',
  CANCELED: '09',
  NOT_ACCORDINGLY: '10',
  LOJA_AGUARDANDO: '11',
  LOJA_EM_REALIZACAO: '12',
  LOJA_REALIZADO: '13',
  LOJA_ENCERRADO: '14',
}

/* CONSTANTE PARA TIPO DE CANAL */
export const CHANNEL_TYPES = {
  '01': 'Loja',
  '02': 'Televendas',
  '03': 'e-commerce',
}

/* CONSTANTE PARA TIPO DE FILTRO */
export const FILTER_TYPES = {
  document: 'serviceOrders.document',
  phoneNumber: 'serviceOrders.customer.phoneNumber',
  name: 'serviceOrders.name',
  serviceOrderNumber: 'serviceOrders.serviceOrderNumber',
  orderNumer: 'serviceOrders.orderNumer',
}

/* CONSTANTE PARA TIPO DO INPUT */
export const INPUT_FILTER_TYPES = {
  document: '',
  phoneNumber: '',
  name: '',
  serviceOrderNumber: 'number',
  orderNumer: 'number',
}

/* CONSTANTE PARA TIPO DE ORDENAÇÃO */
export const SORT_BY = {
  'so.custIdNr': 'so.custIdNr.value',
  'so.custFullNm': 'so.custFullNm.value',
  'so.custTrdNm': 'so.custTrdNm.value',
  'so.servcOrdCd': 'so.servcOrdCd.value',
  'so.vaSlsOrdNr': 'so.vaSlsOrdNr.value',
  'so.creatTs': 'so.creatTs.value',
  'so.servcOrdSchdlgTs': 'so.servcOrdSchdlgTs.value',
  'so.servcOrdStusCd': 'so.servcOrdStusCd.value',
  'so.slsChnlNm': 'so.slsChnlNm.value',
  'so.plntNm': 'so.plntNm.value',
}

/* Tipo de Ordem de Serviços */
export const SERVC_ORD_TYPE = {
  'so.typeClient': 'so.typeClient.value',
  'so.typeStore': 'so.typeStore.value',
  'so.typePrvdr': 'so.typePrvdr.value',
  'so.typeFull': 'so.typeFull.value',
}

/* Tipo de Ordens */
export const SERVC_ORD_PRIORTY_CD = {
  1: 'so.typeService.value',
  2: 'so.typeTechnicalVisit.value',
  0: 'so.typeFull.value',
}

/* CONSTANTE PARA TIPO DE ORDENAÇÃO */
export const SORTING_TYPES = {
  ASC: 'option.ascending',
  DESC: 'option.descending',
}

/* CONSTANTE PARA TIPO DE DOCUMENTO */
export const DOCUMENT_TYPE = {
  1: 'serviceOrders.cpf',
  2: 'serviceOrders.cnpj',
  3: 'serviceOrders.passport',
}

/* CONSTANTE PARA STATUS */
export const STATUS_TYPE = {
  0: 'option.inactive',
  1: 'option.active',
  '': 'option.all',
}

/* CONSTANTE PARA STATUS SIM ou NÂO */
export const YES_NO = {
  0: 'option.no',
  1: 'option.yes',
}

/* CONSTANTE PARA STATUS INVERTIDO */
export const STATUS_TYPE_INVERTED = {
  0: 'option.active',
  1: 'option.inactive',
  '': 'option.all',
}

/* Padrão de datas do sistema */
export const FORMAT_DATE = 'DD/MM/YYYY HH:mm:ss'
export const FORMAT_DATE_T = 'YYYY-MM-DDT03:00:00'
export const FORMAT_DATE_TRUNC_MIN = 'DD/MM/YYYY HH:mm'
export const FORMAT_DATE_TRUNC = 'DD/MM/YYYY'
export const FORMAT_DATE_TRUNC_DOT = 'DD.MM.YYYY'
export const FORMAT_DATE_PICKER = 'dd/MM/yyyy'
export const FORMAT_SINGLE_DATE_PICKER = 'DD/MM/YYYY'
export const DATE_PATTERN = '99/99/9999'
export const FORMAT_HOUR_MIN = 'HH:mm'

export const SITE_LMB = 'https://www.leroymerlin.com.br/'

/* STATUS TYPES */
export const UNNACOMPLISHED_SERVICE = '49'

/* CONSTANTE para GRUPO e PERFIL */
export const PROFILE_TYPE = {
  0: 'option.group',
  1: 'option.profile',
}

export const SEX_TYPES = {
  M: 'option.male',
  F: 'option.female',
}

export const MARITIAL_STATUS = {
  0: 'maritalStatus.notMarried',
  1: 'maritalStatus.married',
  2: 'maritalStatus.divorced',
  3: 'maritalStatus.widower',
}

export const EXPERT_TYPE = {
  0: '',
  1: 'CAU',
  2: 'CREA',
}

/* CONSTANTE PARA TIPO DE VALOR DE PARÂMETRO */
export const PARAMETER_TYPE = {
  1: 'option.string',
  2: 'option.integer',
  3: 'option.date',
  4: 'option.dateTime',
  5: 'option.decimal',
  6: 'option.blob',
  7: 'option.boolean',
}

/* CONSTANTE PARA ONLINE */
export const ONLINE_TYPE = {
  0: 'option.offline',
  1: 'option.online',
}

/* constantes de mapeamento para chamadas no cockpit */
export const COCKIPT_ONLINE_INSTALLERS = 1
export const COCKIPT_NEW_SERVICES = 2
export const COCKIPT_RUNNING_SERVICES = 3
export const COCKIPT_EVE_CONFIRM_SERVICES = 4
export const COCKIPT_TO_DISTRIBUTE_SERVICES = 5
export const COCKIPT_PARTIALLY_DONE_SERVICES = 6
export const COCKIPT_CLOSED_OR_DONE_SERVICES = 7
export const COCKIPT_WITH_WARRANTY_SERVICES = 8
export const COCKIPT_PROGRESS_SERVICES = 9
export const COCKIPT_OPEN_REWORKS = 11
export const COCKIPT_TODAY_LATE = 12
export const COCKIPT_PREVIOUSLY_LATE = 13
export const COCKIPT_REFUSED = 14
export const COCKIPT_ALERTS = 15
export const COCKIPT_SERVICES_PARTIALLY_DONE = 17
export const COCKPIT_ALERTS_FOR_SERVICE_COORDINATOR = 19
export const COCKPIT_TODAY_CONFIRMED_AND_LATE = 20
export const COCKPIT_WAITING_FOR_QUOTATION = 22
export const COCKIPT_ACCEPTANCE_SERVICE_CONDITIONS = 23
export const COCKIPT_NOT_ALLOCATED = 999

/* Dispositivos possíveis em uma mensagem do chat */
export const MESSAGE_DEVICE = {
  WEB: 'web',
  WEB_BRANCH: 'webBranch',
  MOBILE: 'mobile',
}

/* Conteúdos possíveis em uma mensagem do chat */
export const MESSAGE_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
}

/* Marcadores dos mapas */
export const MAP_MARKER = {
  EXECUTION: 1,
  DISTRIBUTE: 2,
  NEW: 3,
  REWORK: 4,
  EXECUTING: 5,
  CONNECTED: 6,
  FREE: 7,
}

/* Tipo de mapa */
export const MAP_TYPE = {
  SERVICES: 1,
  ONLINE_AGENTS: 2,
}

/* Tags usadas no chat */
const CHAT_TAG_OS_NAME = 'oscd'
export const CHAT_TAG = {
  OS_OPEN: `#${CHAT_TAG_OS_NAME}#`,
  OS_CLOSE: `#/${CHAT_TAG_OS_NAME}#`,
}

/* Theme para ser usado em autocomplete */
export const AUTOCOMPLETE_THEME = {
  container: 'react-autosuggest__container',
  containerOpen: 'react-autosuggest__container--open',
  input: 'react-autosuggest__input input',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsContainer: 'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'react-autosuggest__suggestions-list',
  suggestion: 'react-autosuggest__suggestion',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title',
}

/* Filtros da tela de consulta das OS */
export const SERVICE_ORDERS_DEFAULT_FILTERS = {
  orderBy: 'so.servcOrdSchdlgTs',
  descAsc: 'ASC',
  currentSearchField: 'serviceOrderNumber',
  currentSearchFieldValue: '',
  creatTs: null,
  creatTsEnd: null,
  servcOrdSchdlgTs: null,
  servcOrdSchdlgTsEnd: null,
  servcXecutnStrtDt: null,
  servcXecutnStrtDtEnd: null,
  servcOrdItemEvntDt: null,
  servcOrdItemEvntDtFim: null,
  lsGroup: [],
  lsCodProd: [],
  lsStatus: [],
  lsServcOrdStusCd: [],
  codProdAssociado: '',
  servcPrvdrAgntNm: '',
  servcPrvdrNm: '',
  slsChnlTyp: '',
  lsBranch: [],
  lsPlntCd: [],
  indRunningRework: false,
  indNewRework: false,
  indRefused: false,
  indRefusedLastDay: false,
  indExpired: false,
  serviceOrderHasAttachments: false,
  servcOrdPriortyCd: 'so.typeFull',
  withoutChildren: false,
  waitingForEvaluationInNumDays: false,
  hasFilters: false,
  servcOrdType: 'so.typeFull',
  servcOrdStatusTypeStore: false,
  servcOrdStatusTypeHouse: false,
  servcOrdStatusTypeProvider: false,
  lsServcOrdStusCockpit: [],
  codProd: '',
  sosWithoutEvaluation: false,
  sosPendingBudget: false,
  sosOutOfFlow: false,
  sosWaitingQuotation: false,
  sosClosedWithoutSatisfactionSurvey: false,
  conformity: false,
  lateTodayInd: false,
  manualDistributed: false,
  waitingForEvaluationForNDays: false,
}

export const SHIFT_CODES = {
  M: 'serviceOrders.turno.manha',
  A: 'serviceOrders.turno.tarde',
}

export const CALL_AGENT = {
  ANEW: 1,
  FIVE9: 2,
  TWILIO: 3,
}

export const I18N_PREFIX = 'i18next_res_'

export const USER_TYPE = {
  BACKOFFICE: 0,
  BRANCH: 1,
  CUSTOMER: 1,
}

export const MATCHMAKING_OPTIONS = [
  {
    label: 'enblMtchngMkngPlnt.fields.enblMtchngMkngPlntDesc.value.enabled',
    value: true,
  },
  {
    label: 'enblMtchngMkngPlnt.fields.enblMtchngMkngPlntDesc.value.disabled',
    value: false,
  },
]

export const MATCHMAKING_LOG_DAY_OPTIONS = [
  { label: 'enblMtchngMkngPlntLog.fields.nmDays.value.30', value: 30 },
  { label: 'enblMtchngMkngPlntLog.fields.nmDays.value.60', value: 60 },
]

export const ATTACHMENT_CLASSIFICATION = {
  FILE: 3,
  EVALUATION_PHOTO: 6,
  NEED_HELP_PHOTO: 7,
}

export const PAYMNT_IND = {
  0: 'option.no',
  1: 'option.yes',
  2: 'payments.servcOrdNfseAnalysis.rework',
}

export const ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

export const ACTV_SERVC_PRVDR_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

export const ACTV_SERVC_SPCLST_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

/* ACCOUNT TYPES */
export const ACCOUNT_TYPES = {
  1: 'account.type.checking',
  2: 'account.type.saving',
}

/* TERMS OF USE TYPES */
export const TOU_TYPES = {
  1: 'termOfUse.instalaApp',
  2: 'termOfUse.serviceProviderPortal',
}
