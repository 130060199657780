import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Body from '../../components/Body'
import Button from '../../components/Button'
import { FooterV2 } from '../../components/FooterV2'
import { HeaderV2 } from '../../components/HeaderV2'
import Col from '../../components/utils/Col'
import TelephoneInputField from '../../components/utils/InternationalPhoneNumberField'
import Row from '../../components/utils/Row'
import { editPhone } from '../../redux/actions/customer'
import {
  validateMobilePhoneNumber,
  validatePhoneNumber,
} from '../../utils/InternationalPhoneNumberUtils'
import { getFluxLabel } from '../../utils/ServiceOrderFlux'
import { renderToastError } from '../../utils/Toast'

const findDuplicates = (arr) =>
  arr.filter((item, index) => item && arr.indexOf(item) !== index).length

class PhoneEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        custMobilePhonNr: '',
        custHmPhonNr: '',
        custBusnsPhonNr: '',
      },
      isDisabled: true,
    }
  }

  componentDidMount() {
    const {
      location: {
        state: { orderDetails },
      },
    } = this.props
    const initialPhones = {}

    // load all the initials into one single thing, easier to manipulate
    initialPhones.custMobilePhonNr = orderDetails.custMobilePhonNr || ''
    initialPhones.custHmPhonNr = orderDetails.custHmPhonNr || ''
    initialPhones.custBusnsPhonNr = orderDetails.custBusnsPhonNr || ''

    this.setState({ formData: initialPhones })
    window.scrollTo(0, 0)
  }

  isPhoneNumberValid = () => {
    const { formData } = this.state
    return (
      formData.custMobilePhonNr &&
      Object.values(formData).some((phone) => phone && validatePhoneNumber(phone) === false)
    )
  }

  handleChange = (phone, value) => {
    const { formData } = this.state
    const newDict = formData

    newDict[phone] = value

    const { custBusnsPhonNr, custHmPhonNr, custMobilePhonNr } = newDict

    const phoneTypes = {
      custMobilePhonNr: (value) =>
        !validateMobilePhoneNumber(value) || [custHmPhonNr, custBusnsPhonNr].includes(value),

      custHmPhonNr: (value) =>
        (value ? !validatePhoneNumber(value) : false) ||
        [custMobilePhonNr, custBusnsPhonNr].includes(value),

      custBusnsPhonNr: (value) =>
        (value ? !validatePhoneNumber(value) : false) ||
        [custMobilePhonNr, custHmPhonNr].includes(value),
    }

    this.setState(
      {
        formData: newDict,
        isDisabled: Object.entries(phoneTypes).some(([fld, func]) =>
          fld === 'custMobilePhonNr' ? func(newDict[fld]) : newDict[fld] && func(newDict[fld]),
        ),
      },
      () => {
        // needed to make the cursor go to the end of input (maybe think of something better in the future)
        setTimeout(
          () =>
            document
              .getElementsByClassName('PhoneInputInput')
              [
                ['custMobilePhonNr', 'custHmPhonNr', 'custBusnsPhonNr'].indexOf(phone)
              ].setSelectionRange(-1, -1),
          10,
        )
      },
    )
  }

  handleSubmit = () => {
    const {
      t,
      editPhone,
      history,
      location: {
        search,
        state: { orderDetails },
      },
    } = this.props
    const { formData } = this.state

    if (!formData.custMobilePhonNr) {
      return renderToastError(t('customer.service.order.edit.phone.msg'))
    }

    // check for dup values
    if (findDuplicates(Object.values(formData))) {
      renderToastError(t('customer.service.order.edit.phone.duplicate'))
      return
    }

    // validating as requested (testing it strictly equal to false, because empty values return null)
    if (Object.values(formData).some((phone) => phone && validatePhoneNumber(phone) === false)) {
      renderToastError(t('customer.service.order.edit.phone.invalid'))
      return
    }

    editPhone(formData, orderDetails, search, history, t('customer.service.order.edit.save.fail'))
  }

  render() {
    const { formData, isDisabled } = this.state
    const {
      t,
      history,
      location: {
        search,
        state: { orderDetails },
      },
    } = this.props
    const flux = parseInt(orderDetails?.servcCatgryTypCd)
    const { custMobilePhonNr, custHmPhonNr, custBusnsPhonNr } = formData

    const handleOnClickHeaderNeedHelp = () => {
      history.push({
        pathname: '/service-details/need-help',
        search,
        state: { osDetails: orderDetails },
      })
    }

    return (
      <>
        <HeaderV2 onClickNeedHelp={handleOnClickHeaderNeedHelp} />
        <Body>
          <div className='data-edit-form'>
            <h4>{getFluxLabel('customer.service.order.edit.phone.title', flux)}</h4>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={5}>
                <TelephoneInputField
                  topLabel={t('customer.service.order.edit.phone.mobile')}
                  label={t('customer.service.order.edit.phone.msg')}
                  value={custMobilePhonNr}
                  valid={
                    !!custMobilePhonNr &&
                    validateMobilePhoneNumber(custMobilePhonNr) &&
                    ![custHmPhonNr, custBusnsPhonNr].includes(custMobilePhonNr)
                  }
                  onChange={(value) => this.handleChange('custMobilePhonNr', value || '')}
                  labelClassName='labelPhoneBreak'
                  className='labelPhoneBreak'
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={5}>
                <TelephoneInputField
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.phone.home'))}
                  value={custHmPhonNr}
                  valid={
                    custHmPhonNr !== ''
                      ? validatePhoneNumber(custHmPhonNr) &&
                        ![custMobilePhonNr, custBusnsPhonNr].includes(custHmPhonNr)
                      : ''
                  }
                  onChange={(value) => this.handleChange('custHmPhonNr', value || '')}
                />
              </Col>
            </Row>
            <Row className='margin-bottom-row'>
              <Col xs={12} sm={12} md={5}>
                <TelephoneInputField
                  topLabel={ReactHtmlParser(t('customer.service.order.edit.phone.bsns'))}
                  value={custBusnsPhonNr}
                  valid={
                    custBusnsPhonNr !== ''
                      ? validatePhoneNumber(custBusnsPhonNr) &&
                        ![custMobilePhonNr, custHmPhonNr].includes(custBusnsPhonNr)
                      : ''
                  }
                  onChange={(value) => this.handleChange('custBusnsPhonNr', value || '')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={6} md={3}>
                <Button
                  onClick={() => history.goBack()}
                  label={t('customer.service.order.reschedule.cancel')}
                  variant='white'
                  fill
                />
              </Col>
              <Col xs={6} sm={6} md={3}>
                <Button
                  label={t('customer.service.order.reschedule.save')}
                  onClick={() => this.handleSubmit()}
                  disabled={isDisabled}
                  fill
                />
              </Col>
            </Row>
          </div>
        </Body>
        <FooterV2 />
      </>
    )
  }
}

const PhoneEditTranslated = withTranslation()(PhoneEdit)

const mapDispatchToProps = (dispatch) => bindActionCreators({ editPhone }, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(PhoneEditTranslated))
