import './index.scss'

interface ContainerProps {
  readonly children: React.ReactNode
  readonly className?: string
}

/**
 * @description Container that wraps the entire application to create a consistent global layout
 */

export function Container({ children, className = '' }: ContainerProps) {
  return (
    <main id='container' className={className}>
      {children}
    </main>
  )
}
