import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'
import { fetchOsInfo, getLastOsOcurrence } from './customer'
import { startLoading, stopLoading } from './activityIndicator'

import {
  SAVE_CONFIRMATION_REQUEST,
  SAVE_CONFIRMATION_REQUEST_SUCCESS,
  SAVE_CONFIRMATION_REQUEST_ERROR,
} from './actionTypes'
import { parseQuery } from '../../utils/serviceOrderUtils'

/*
 * SAVE CONFIRMATION
 */
export const saveConfirmation = (values, query) => (dispatch) => {
  const queryParams = parseQuery(query)
  const loadingAction = 'saveConfirmation'

  dispatch(saveConfirmationRequest())

  const fields = {
    ...values,
  }

  dispatch(startLoading(loadingAction))
  return new Api()
    .post(API_URL.SAVE_CONFIRMATION, fields)
    .then((response) => {
      dispatch(saveConfirmationSuccess(response.data))
      dispatch(fetchOsInfo(queryParams.o, queryParams.c))
    })
    .catch((error) => {
      dispatch(saveConfirmationError(error))
    })
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const clientRescheduleRequest = () => (dispatch) => {
  const loadingAction = 'reschedule'
  const auth = parseQuery(true)

  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .post(API_URL.CLIENT_SERVICE_RESCHEDULE.concat(`/${auth.servcOrdSeqEncoded}`))
    .finally(() => dispatch(stopLoading(loadingAction)))
}

export const saveConfirmationRequest = () => ({
  type: SAVE_CONFIRMATION_REQUEST,
})

export const saveConfirmationSuccess = (data) => ({
  type: SAVE_CONFIRMATION_REQUEST_SUCCESS,
  payload: data,
})

export const saveConfirmationError = (error) => ({
  type: SAVE_CONFIRMATION_REQUEST_ERROR,
  payload: error,
})

export const handleSpecialistReschedulingAcceptance = (accepted, os) => (dispatch) => {
  const loadingAction = 'handleSpecialistReschedulingAcceptance'
  const auth = parseQuery(true)
  dispatch(startLoading(loadingAction))
  return new Api(null, dispatch)
    .post(accepted ? API_URL.RESCHEDULING_CONFIRM : API_URL.RESCHEDULING_REFUSE, auth)
    .then((res) => {
      dispatch(fetchOsInfo(auth.servcOrdSeqEncoded, auth.vaCustCdEncoded))
      dispatch(getLastOsOcurrence(os))
    })
    .catch((error) => Promise.reject(error))
    .finally(() => dispatch(stopLoading(loadingAction)))
}
