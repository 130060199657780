import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'
import { FETCH_TEMPLATES_BY_ID_SUCCESS, FETCH_TEMPLATES_BY_ID_ERROR } from './actionTypes'

export const fetchTemplateById = (params) => (dispatch) =>
  new Api(null, dispatch)
    .post(API_URL.TEMPLATES_PUBLIC_FETCH, params)
    .then((response) => dispatch(fetchTemplateSuccess(response.resultList[0] || '')))
    .catch((error) => dispatch(fetchTemplateError(error.error || error || '')))

export const fetchTemplateSuccess = (data) => ({
  type: FETCH_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
})

export const fetchTemplateError = (error) => ({
  type: FETCH_TEMPLATES_BY_ID_ERROR,
  payload: error,
})
