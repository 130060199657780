import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { validateCustomerSmsCode } from '../redux/actions/customer'
import Input from './utils/Input'
import GenericContainer from './GenericClientContainer'
import Button from './Button'
import i18next from 'i18next'

class LoginContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      code: '',
    }
  }

  get isOTPSupported() {
    return 'OTPCredential' in window
  }

  addOTPListener = () => {
    const ac = new AbortController()

    navigator.credentials
      .get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      })
      .then((otp) => {
        this.setState({ code: otp.code }, () => setTimeout(this.handleSubmit, 250))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  componentDidMount() {
    if (this.isOTPSupported) this.addOTPListener()
  }

  handleSubmit = () => {
    const { code } = this.state
    const { validateCustomerSmsCode, history } = this.props

    validateCustomerSmsCode(code, () => history.push('/services'))
  }

  render() {
    const { code } = this.state

    return (
      <GenericContainer>
        <h5>Código de acesso</h5>
        <Input
          autoComplete='one-time-code'
          type='number'
          className='input'
          mask='999999'
          value={code}
          onChange={(e) => this.setState({ code: e.target.value })}
          onKeyDown={(e) => e.key === 'Enter' && this.handleSubmit()}
        />
        <Button label={i18next.t('pwa.login.button')} onClick={this.handleSubmit} />
      </GenericContainer>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ validateCustomerSmsCode }, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(LoginContainer))
