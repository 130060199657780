import React from 'react'
import { getFluxLabel } from '../utils/ServiceOrderFlux'
import Col from './utils/Col'
import Row from './utils/Row'

function ServiceDescription(props) {
  const { t, services, flux } = props
  return (
    <>
      <h4>
        <span className='description-table-title'>
          {getFluxLabel('customer.service.order.service.description.table.title', flux)}
        </span>
      </h4>
      <hr />
      <Row>
        <Col xs={10} sm={10} md={11}>
          <span className='head-item item-span'>
            {t('customer.service.order.service.description.table.items')}
          </span>
        </Col>
        <Col xs={2} sm={2} md={1} className='text-center'>
          <span className='head-item qttd-span'>
            {t('customer.service.order.service.description.table.qtt')}
          </span>
        </Col>
      </Row>
      <hr />
      {(services || []).map((item, idx) => (
        <div key={idx}>
          <Row>
            <Col xs={10} sm={10} md={11} className='item-name'>
              <a className='pointer'>{item.nomeProd}</a>
            </Col>
            <Col xs={2} sm={2} md={1} className='text-center'>
              <span className='qttd-span'>{item.qtdProdOrdemServico}</span>
            </Col>
          </Row>
          <hr />
        </div>
      ))}
    </>
  )
}

export default ServiceDescription
