const ReplaceTextKeys = (text: string, keys: { [key: string]: string }) => {
  let newText = text

  Object.entries(keys).forEach(([key, value]) => {
    newText = newText.replace(`{${key}}`, value)
  })

  return newText
}

export default ReplaceTextKeys
