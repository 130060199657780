import React from 'react'
import Button from './Button'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { handleSpecialistReschedulingAcceptance } from '../redux/actions/confirmation'
import GenericClientContainer from './GenericClientContainer'
import { getTranslationAppend } from '../utils/ServiceOrderFlux'
import Events from '../utils/ServcOrdEventStusType'
import moment from 'moment-timezone'
import ReplaceTextKeys from '../utils/ReplaceTextKeys'

function RescheduleConfirmation(props) {
  const { t, details, occurrences } = props

  if (
    ![Events.AGENT_RESCHEDULE_REQUESTED.id].includes(
      occurrences?.[details.servcOrdSeq]?.servcOrdEvntStusInd,
    )
  )
    return null

  const customerPageRescheduleRequestText = t(
    `customer.page.reschedule.request.${getTranslationAppend(parseInt(details.servcCatgryTypCd))}`,
  )

  const schedulingDate = details.servcOrdRschdlgTs
  const schedulingSlot = t(details.servcOrdRschdlgCd).toLowerCase()
  const schedulingWeekDay = moment(details.date, 'L').format('dddd')
  const installer = details.servcPrvdrAgntNm
  const provider = details.servcPrvdrTrdNm
  const serviceGroup =
    details?.servcGrpNm && details?.servcGrpNm?.split('-').length > 1
      ? details?.servcGrpNm?.split('-')[1].trim()
      : details?.servcGrpNm

  return (
    <GenericClientContainer className='full-width service-confirmation'>
      <h4>
        {ReplaceTextKeys(customerPageRescheduleRequestText, {
          schedulingDate,
          schedulingSlot,
          schedulingWeekDay,
          installer,
          provider,
          serviceGroup,
        })}
      </h4>
    </GenericClientContainer>
  )
}

const mapStateToProps = (state) => ({
  occurrences: state.occurrencesReducer,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ handleSpecialistReschedulingAcceptance }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleConfirmation)
