import Api from '../../utils/ApiRequest'
import API_URL from '../../infra/api/endpoints'

export const fetchInstallerPageData = (params) => {
  const data = {
    agent: {},
    provider: {},
    totalOrders: {},
    satisfaction: {},
  }

  const { servcPrvdrAgntId } = params

  return Promise.all([
    new Api(null)
      .get(API_URL.INSTALLER_PAGE_INSTALLER_INFOS, params)
      .then((response) => (data.agent = response.data))
      .catch((error) => console.error(error)),

    new Api(null)
      .get(API_URL.INSTALLER_PAGE_PROVIDER_INFOS, params)
      .then((response) => (data.provider = response.data))
      .catch((error) => console.error(error)),

    new Api(null)
      .get(API_URL.INSTALLER_PAGE_TOTAL_ORDERS, params)
      .then((response) => (data.totalOrders = response.data))
      .catch((error) => console.error(error)),

    new Api(null)
      .get(`${API_URL.INSTALLER_PAGE_SATISTACTION}/${servcPrvdrAgntId}`)
      .then((response) => (data.satisfaction = response.data))
      .catch((error) => console.error(error)),
  ]).then(() => data)
}

export const fetchFile = (filePath) => new Api(null).get(API_URL.FETCH_FILE, { filePath })
